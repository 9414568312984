import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import transactionService from "./transactionService";
import { toast } from "react-toastify";

const initialState = {
  receiverName: "",
  transaction: null,
  transactions: [],
  loading: false,
  isSuccess: false,
  isError: false,
  message: "",
};

//Get User Transactions
export const getUserTransactions = createAsyncThunk(
  "transactions/getUserTransactions",
  async (_, thunkAPI) => {
    try {
      return await transactionService.getUserTransactions();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

//Verify Receiver Name
export const verifyAccount = createAsyncThunk(
  "transactions/verifyAccount",
  async (formData, thunkAPI) => {
    try {
      return await transactionService.verifyAccount(formData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

//Transfer Fund
export const transferFund = createAsyncThunk(
  "transactions/transferFund",
  async (formData, thunkAPI) => {
    try {
      return await transactionService.transferFund(formData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

//Get a Transaction
export const getUserSingleTransaction = createAsyncThunk(
  "transactions/getUserTransaction",
  async (id, thunkAPI) => {
    try {
      return await transactionService.getUserSingleTransaction(id);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);
const transactionSlice = createSlice({
  name: "transaction",
  initialState,
  reducers: {
    RESET_TRANSACTION_MESSAGE(state) {
      state.message = "";
    },
    RESET_RECEIVER(state) {
      state.receiverName = "";
    },
  },
  extraReducers: (builder) => {
    builder
      // Get User Transaction
      .addCase(getUserTransactions.pending, (state) => {
        state.loading = true;
      })
      .addCase(getUserTransactions.fulfilled, (state, action) => {
        state.loading = false;
        state.isSuccess = true;
        state.isError = false;
        state.transactions = action.payload;
      })
      .addCase(getUserTransactions.rejected, (state, action) => {
        state.loading = false;
        state.isError = true;
        state.message = action.payload;
      })
      // Verify Receiver Name
      .addCase(verifyAccount.pending, (state) => {
        state.loading = true;
      })
      .addCase(verifyAccount.fulfilled, (state, action) => {
        state.loading = false;
        state.isSuccess = true;
        state.isError = false;
        state.receiverName = action.payload.receiverName;
        state.message = action.payload.message;
      })
      .addCase(verifyAccount.rejected, (state, action) => {
        state.loading = false;
        state.isError = true;
        state.message = action.payload;
        toast.error(action.payload);
      })

      // Trasnfer Fund
      .addCase(transferFund.pending, (state) => {
        state.loading = true;
      })
      .addCase(transferFund.fulfilled, (state, action) => {
        state.loading = false;
        state.isSuccess = true;
        state.isError = false;
        state.message = action.payload.message;
        toast.success(action.payload.message);
      })
      .addCase(transferFund.rejected, (state, action) => {
        state.loading = false;
        state.isError = true;
        state.message = action.payload;
        toast.error(action.payload);
      })

      //Get a Transaction
      .addCase(getUserSingleTransaction.pending, (state) => {
        state.loading = true;
      })
      .addCase(getUserSingleTransaction.fulfilled, (state, action) => {
        state.loading = false;
        state.isSuccess = true;
        state.isError = false;
        state.transaction = action.payload;
      })
      .addCase(getUserSingleTransaction.rejected, (state, action) => {
        state.loading = false;
        state.isError = true;
        state.message = action.payload;
        toast.error(action.payload);
      });
  },
});

export const { RESET_TRANSACTION_MESSAGE, RESET_RECEIVER } =
  transactionSlice.actions;
export const selectTransactions = (state) => state.transaction.transactions;
export const selectReceiverName = (state) => state.transaction.receiverName;
export const selectTransactionMessage = (state) => state.transaction.message;
export default transactionSlice.reducer;
