import React, { useEffect, useState } from "react";
import css from "./Header.module.scss";
import Logo from "../../assets/main-logo.png";
import { FaUserSlash } from "react-icons/fa";
import { GiSelfLove } from "react-icons/gi";
import { SlWallet } from "react-icons/sl";
import { NavLink, Link, useNavigate } from "react-router-dom";
// import { Toggle } from "../index.js";
import { FaShoppingBasket } from "react-icons/fa";
import ShowOnLogin from "../protect/hiddenLink.js";
import { useDispatch, useSelector } from "react-redux";
import { UserName } from "../profile/settings/Settings.jsx";
import { AdminOnlyLink } from "../protect/AdminOnlyRoute.js";
import {
  CALCULATE_SUBTOTAL,
  CALCULATE_TOTAL_QUANTITY,
  selectCartItems,
  selectCartTotalQuantity,
  selectCartTotalAmount,
} from "../../redux/features/cart/cartSlice.js";
import { selectUser } from "../../redux/features/auth/authSlice.js";

export const activeLink = ({ isActive }) => (isActive ? `${css.active}` : "");

const Header = () => {
  const user = useSelector(selectUser);
  const navigate = useNavigate();
  const { products } = useSelector((state) => state.product);
  const { isLoggedIn } = useSelector((state) => state.auth);

  const [scrollPage, setScrollPage] = useState(false);
  const cartTotalQuantity = useSelector(selectCartTotalQuantity);
  const cartTotalAmount = useSelector(selectCartTotalAmount);

  const cartItems = useSelector(selectCartItems);
  const dispatch = useDispatch();

  const fixbar = () => {
    if (window.scrollY > 1) {
      setScrollPage(true);
    } else {
      setScrollPage(false);
    }
  };
  window.addEventListener("scroll", fixbar);

  const logoClick = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    navigate("/");
  };

  const goToProfile = () => {
    if (isLoggedIn) {
      navigate("/profile/dashboard");
    }
    if (!isLoggedIn) {
      navigate("/login");
    }
  };

  useEffect(() => {
    dispatch(CALCULATE_TOTAL_QUANTITY());
    dispatch(CALCULATE_SUBTOTAL());
  }, [dispatch, cartItems, products]);

  //Cart Component
  const cart = (
    <Link to="/cart" title="Cart">
      <div className={css.cart}>
        <small
          className={css["cart-total"]}
        >{`#${cartTotalAmount.toLocaleString()}`}</small>
        <span className={css.basket}>
          <FaShoppingBasket size={18} />
          <small className={css["cart-no"]}>
            {/* {cartTotalQuantity}+ */}
            {cartTotalQuantity < 100 ? cartTotalQuantity : `99+`}
          </small>
        </span>
      </div>
    </Link>
  );

  return (
    <>
      <header className={scrollPage ? `${css.fixed} --bg-primary` : null}>
        <div
          className={
            window.location.pathname !== "/"
              ? `${css["relative-header"]}`
              : `${css.header}`
          }
        >
          <div className={css["logo-cont"]}>
            <img src={Logo} alt="logo" width={100} onClick={logoClick} />
            {/* <Toggle/> */}
          </div>
          <nav>
            <div className={css["right-ul"]}>
              <ul>
                <AdminOnlyLink>
                  <li>
                    <NavLink to="/admin/dashboard" className={activeLink}>
                      Admin&ensp;|
                    </NavLink>
                  </li>
                </AdminOnlyLink>
                <li>
                  <NavLink to="/" className={activeLink}>
                    Home
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/shop" className={activeLink}>
                    Shop
                  </NavLink>
                </li>
                <li>
                  <NavLink to={"/men-products"} className={activeLink}>
                    Men
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/women-products" className={activeLink}>
                    Women
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/special-products" className={activeLink}>
                    Special
                  </NavLink>
                </li>
              </ul>
            </div>
            <div className={css["left-ul"]}>
              <ul>
                <ShowOnLogin>
                  <li
                    onClick={() => navigate("/order-history")}
                    className={css.l1}
                  >
                    Orders
                  </li>
                  <li
                    title="Wallet"
                    onClick={() => navigate("/wallet")}
                    className={css.l2}
                  >
                    <SlWallet size={20} />
                  </li>
                </ShowOnLogin>
                <ShowOnLogin>
                  <li
                    onClick={() => navigate("/wishlist")}
                    title="Wishlist"
                    className={css.l3}
                  >
                    <GiSelfLove size={20} />
                  </li>
                </ShowOnLogin>
                <li className={css.l4}>{cart}</li>
                <li title="Profile" onClick={goToProfile} className={css.l5}>
                  {isLoggedIn ? (
                    <>
                      <img
                        src={user?.photo}
                        width={25}
                        height={25}
                        style={{ borderRadius: "3em" }}
                      />
                    </>
                  ) : (
                    <>
                      <FaUserSlash size={18} />
                      &nbsp;login
                    </>
                  )}
                  <ShowOnLogin>
                    <UserName />
                  </ShowOnLogin>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </header>
    </>
  );
};

export default Header;
