import React, { useEffect } from "react";
import "./Wallet.scss";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getUser, selectUser } from "../../redux/features/auth/authSlice";
import cc from "../../assets/mc_symbol.png";
import Confetti from "react-confetti";
import { AiOutlineDollarCircle } from "react-icons/ai";
import { FaRegPaperPlane } from "react-icons/fa";
import WalletTransaction from "./WalletTransaction";
import {
  getUserTransactions,
  selectTransactions,
} from "../../redux/features/transaction/transactionSlice";
import { toast } from "react-toastify";
import { FaNairaSign } from "react-icons/fa6";
import Meta from "../../components/meta/Meta";

const Wallet = () => {
  const user = useSelector(selectUser);
  const transaction = useSelector(selectTransactions);

  const [urlParams] = useSearchParams();
  const payment = urlParams.get("payment");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getUser());
    dispatch(getUserTransactions());
  }, [dispatch]);

  useEffect(() => {
    if (payment === "successful") {
      toast.success("Payment Successful");
      setTimeout(() => {
        navigate("/wallet");
      }, 10000);
    }
    if (payment === "failed") {
      toast.success("Payment Failed Please try later!");
    }
  }, [payment, navigate]);

  return (
    <>
      <Meta
        descName={
          "Use your shopwithmeenah wallet at checkout and get up to 80% cashback"
        }
        title={"Wallet - Shop With Meenah"}
        keywords={"shopwithmeenah wallet, shop with meenah wallet"}
        canonical={"https://shopwithmeenah.com/wallet"}
        robots={"nofollow, noindex"}
        googlebot={"nofollow, noindex"}
      />
      {payment === "successful" && <Confetti />}
      <section className="--bg-lightgray">
        <div className="container ">
          <div className="wallet">
            <div className="wallet-data ">
              <div className="wallet-info --card mr">
                <span>Hello, </span>
                <h4>{user?.name}</h4>
                <div className="--box-underline"></div>
                <span className="--flex-between">
                  <p>Available Balance</p>
                  <img src={cc} alt="" width={50} />
                </span>
                <h4 style={{ display: "flex", alignItems: "center" }}>
                  <FaNairaSign />
                  {user !== null ? user?.balance?.toLocaleString(2) : "0.00"}
                  &nbsp;NGN
                </h4>
                <div className="buttons --flex-start">
                  <button
                    className="--btn --btn-primary"
                    // onClick={() => navigate("/fund-wallet")}
                    onClick={() =>
                      toast.info("Under maintenance, please retry later")
                    }
                  >
                    <AiOutlineDollarCircle size={22} />
                    Deposit
                  </button>
                  <button
                    className="--btn --btn-danger"
                    onClick={() => navigate("/send-money")}
                  >
                    <FaRegPaperPlane />
                    Transfer
                  </button>
                </div>
                <p className="--flex-center notice">
                  Use your shopwithmeenah wallet at checkout and get up to 80%
                  cashback
                </p>
              </div>
              <div className="wallet-info --card">
                <div className="account-info">
                  <div>
                    <span>Account Id:</span>
                    <span>{user?.email}</span>
                  </div>
                  <div>
                    <span>Account Type:</span>
                    {user !== null && <span>Buying Account</span>}
                  </div>
                  <div>
                    <span>Account Status: </span>
                    {user !== null && (
                      <span>
                        {user?.role !== "suspended" ? (
                          <span className="account-active">Active</span>
                        ) : (
                          <span className="account-inactive">Inactive</span>
                        )}
                      </span>
                    )}
                  </div>
                  <div>
                    <span>Cashback: </span>
                    <span>#0.00</span>
                  </div>
                </div>
              </div>
            </div>
            {/* Wallet Transaction */}
            {user !== null && (
              <WalletTransaction transactions={transaction} user={user} />
            )}
          </div>
          {/* Page Menu Creation Later */}
        </div>
      </section>
    </>
  );
};

export default Wallet;
