import css from "./Profile.module.scss";
import { Navbar } from "../../components";
import { Routes, Route } from "react-router-dom";
import { Dashboard, Settings } from "../../components";
import { TbMenu2 } from "react-icons/tb";
import { useState } from "react";
import Meta from "../../components/meta/Meta";

const Profile = () => {
  const [mview, setMview] = useState(false);
  const toggleMenu = () => {
    setMview(!mview);
  };
  return (
    <div className="">
      <Meta
        robots={"nofollow, noindex"}
        googlebot={"nofollow, noindex"}
        title={"My profile"}
      />
      <div className={css["profile-wrapper"]}>
        <div
          className={mview ? css["nav-tab"] : css["--display-none"]}
          onClick={toggleMenu}
        >
          {mview && (
            <>
              <Navbar />
              <div className={css["close-bg"]}></div>
            </>
          )}
        </div>
        <div className={css["profile-content"]}>
          <span>
            <TbMenu2 size={22} onClick={toggleMenu} />
          </span>
          <Routes>
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="settings" element={<Settings />} />
          </Routes>
        </div>
      </div>
    </div>
  );
};

export default Profile;
