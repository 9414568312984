import React from "react";
import { FaBoxOpen } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import css from "./Navbar.module.scss";
import { RxDashboard } from "react-icons/rx";
import { SlWallet } from "react-icons/sl";
import { GiSelfLove } from "react-icons/gi";
import { RiLogoutCircleRLine } from "react-icons/ri";
import { BiCog } from "react-icons/bi";
import { IconContext } from "react-icons/lib";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  RESET_AUTH,
  logout,
  selectUser,
} from "../../../redux/features/auth/authSlice";
import { GrUserAdmin } from "react-icons/gr";
import { AdminOnlyLink } from "../../protect/AdminOnlyRoute";

const activeLink = ({ isActive }) => (isActive ? `${css.active}` : "");

const Navbar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const logoutUser = async () => {
    dispatch(RESET_AUTH());
    await dispatch(logout());
    navigate("/login");
  };

  const user = useSelector(selectUser);
  return (
    <div>
      <div className={css.navs}>
        <ul>
          <IconContext.Provider
            value={{ size: 20, className: `${css["n-icons"]}` }}
          >
            <AdminOnlyLink>
              <li>
                <NavLink to="/admin/dashboard" className={activeLink}>
                  <GrUserAdmin />
                  Administrator
                </NavLink>
              </li>
            </AdminOnlyLink>
            <li>
              <NavLink to="dashboard" className={activeLink}>
                <RxDashboard />
                Dashboard
              </NavLink>
            </li>
            <li>
              <NavLink to="/wallet" className={activeLink}>
                <SlWallet />
                Wallet
              </NavLink>
            </li>
            <li>
              <NavLink to="/order-history" className={activeLink}>
                <FaBoxOpen />
                Orders
              </NavLink>
            </li>
            <li>
              <NavLink to="/wishlist" className={activeLink}>
                <GiSelfLove />
                Wishlist
              </NavLink>
            </li>
            <li>
              <NavLink to="settings" className={activeLink}>
                <BiCog />
                Settings
              </NavLink>
            </li>
            <li>
              <NavLink to="/" className={activeLink} onClick={logoutUser}>
                <RiLogoutCircleRLine /> {user !== null ? "Logout" : "Login"}
              </NavLink>
            </li>
          </IconContext.Provider>
        </ul>
      </div>
    </div>
  );
};

export default Navbar;
