import React from "react";
import { FaPhoneVolume, FaGift } from "react-icons/fa";
import { BsFillCreditCardFill } from "react-icons/bs";
import { GrStatusGood } from "react-icons/gr";
import "./Home.scss";

const data = [
  {
    icon: <GrStatusGood size={30} color="var(--m)" />,
    heading: "Quality Products",
    text: "Products that satisfies customer needs.",
  },
  {
    icon: <BsFillCreditCardFill size={30} color="var(--m)" />,
    heading: "Secure Payment",
    text: "Highly secured payment system.",
  },
  {
    icon: <FaGift size={30} color="var(--m)" />,
    heading: "Member Discount",
    text: "Coupons and discounts",
  },
  {
    icon: <FaPhoneVolume size={30} color="var(--m)" />,
    heading: "24/7 Support",
    text: "Get support all day",
  },
];
const HomeInfoBox = () => {
  return (
    <div className="infoboxes">
      {data.map((item, index) => {
        const { icon, heading, text } = item;
        return (
          <div className="infobox" key={index}>
            <div className="left-info">
              <div className="icon">{icon}</div>
            </div>
            <div className="right-info">
              <h4>{heading}</h4>
              <p className="text">{text}</p>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default HomeInfoBox;
