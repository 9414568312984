import React from "react";
import "./ProductCategory.scss";
import { IoIosArrowRoundForward } from "react-icons/io";
import { Link, useNavigate } from "react-router-dom";

const categories = [
  {
    id: 1,
    title: "All",
    image: "https://i.ibb.co/pjN24X3/Untitled-design-1-1-min.png",
    link: "/shop",
  },
  {
    id: 2,
    title: "Men",
    image: "https://i.ibb.co/zSF0XR7/IMG-2258-min.jpg",
    link: "/men-products",
  },
  {
    id: 3,
    title: "Women",
    image:
      "https://i.ibb.co/LdB41HG/e6c4998f-8694-48cb-b5db-66a70bb1c368-min.jpg",
    link: "/women-products",
  },
  {
    id: 4,
    title: "Special",
    image: "https://i.ibb.co/vhqcFSz/IMG-2590-min.jpg",
    link: "/special-products",
  },
];

const Category = ({ title, image, path }) => {
  const navigate = useNavigate();
  return (
    <div className="category" onClick={() => navigate(path)}>
      <h3 className="--fw-thick">{title}</h3>
      <img src={image} alt="category" />
      <Link to={path} className="--btn anchor">
        {"Shop Now"}
        <IoIosArrowRoundForward size={20} />
      </Link>
    </div>
  );
};
const ProductCategory = () => {
  return (
    <div className="categories">
      {categories.map((cat) => {
        return (
          <div key={cat.id}>
            <Category title={cat.title} image={cat.image} path={cat.link} />
          </div>
        );
      })}
    </div>
  );
};

export default ProductCategory;
