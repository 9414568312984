import "./Userstat.scss";
import InfoBox from "../../../infobox/InfoBox";
import { FaCartArrowDown, FaRegMoneyBillAlt, FaUsers } from "react-icons/fa";
import { BiUserCheck, BiUserMinus, BiUserX } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import {
  CALC_SUSPENDED_USER,
  CALC_VERIFIED_USER,
  getUsers,
} from "../../../../redux/features/auth/authSlice";
import { useEffect } from "react";
import { BsCart4 } from "react-icons/bs";
import { FaNairaSign } from "react-icons/fa6";
import {
  CALC_TOTAL_ORDER_AMOUNT,
  getOrders,
  selectTotalOrderAmount,
} from "../../../../redux/features/order/orderSlice";
import { getProducts } from "../../../../redux/features/product/productSlice";
import { useNavigate } from "react-router-dom";

//Icons
const icon1 = <FaUsers size={40} color="var(--mag)" />;
const icon2 = <BiUserCheck size={40} color="green" />;

const icon3 = <BiUserMinus size={40} color="var(--m)" />;
const icon4 = <BiUserX size={40} color="#c41849" />;
const icon5 = <FaNairaSign size={40} color="#c41849" />;
const icon6 = <BsCart4 size={40} color="#1f93ff" />;
const icon7 = <FaCartArrowDown size={40} color="orangered" />;
const icon8 = <FaRegMoneyBillAlt size={40} color="green" />;

const Userstat = () => {
  const { users, verifiedUsers, suspendedUsers } = useSelector(
    (state) => state.auth
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const totalOrderAmount = useSelector(selectTotalOrderAmount);
  const products = useSelector((state) => state.product.products);
  const orders = useSelector((state) => state.order.orders);
  const total = products.reduce((acc, product) => {
    return acc + product.price * product.quantity;
  }, 0);

  useEffect(() => {
    dispatch(getUsers());
    dispatch(getOrders());
    dispatch(getProducts());
  }, [dispatch]);

  useEffect(() => {
    dispatch(CALC_TOTAL_ORDER_AMOUNT());
  }, [orders, dispatch]);

  useEffect(() => {
    dispatch(CALC_SUSPENDED_USER());
    dispatch(CALC_VERIFIED_USER());
  }, [users, dispatch]);

  const uv = users.length - verifiedUsers;

  return (
    <div className="user-summary">
      <div className="info-summary">
        <InfoBox
          icon={icon8}
          title={"Store Value"}
          count={`#${total.toLocaleString()}`}
          bgColor="card5"
        />
        <InfoBox
          icon={icon5}
          title={"Earnings"}
          count={`#${totalOrderAmount.toLocaleString()}`}
          bgColor="card5"
        />
        <InfoBox
          icon={icon6}
          title={"Products"}
          count={products?.length}
          bgColor="card4"
        />
        <InfoBox
          icon={icon7}
          title={"Orders"}
          count={orders?.length}
          bgColor="card4"
        />
        <InfoBox
          icon={icon1}
          title={"Total Users"}
          count={users.length}
          bgColor="card1"
          navigateBox={() => navigate("/admin/customers")}
        />
        <InfoBox
          icon={icon2}
          title={"Verified Users"}
          count={verifiedUsers}
          bgColor="card2"
        />
        <InfoBox
          icon={icon3}
          title={"Unverified Users"}
          count={uv}
          bgColor="card3"
        />
        <InfoBox
          icon={icon4}
          title={"Suspended Users"}
          count={suspendedUsers}
          bgColor="card4"
        />
      </div>
    </div>
  );
};

export default Userstat;
