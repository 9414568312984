import React, { useEffect } from "react";
import {
  Slider,
  PageHeading,
  ProductCarousel,
  CarouselItem,
} from "../../components";
import HomeInfoBox from "./HomeInfoBox";
import ProductCategory from "./ProductCategory";
import { Link } from "react-router-dom";
import { IoIosArrowRoundForward } from "react-icons/io";
import { getProducts } from "../../redux/features/product/productSlice";
import { useDispatch, useSelector } from "react-redux";
import Meta from "../../components/meta/Meta";

const Button = ({ path, value }) => (
  <div className="home-btn">
    <Link to={path}>
      {value}
      <span
        style={{
          minWidth: "20px",
          alignItems: "center",
          display: "flex",
        }}
      >
        <IoIosArrowRoundForward size={20} />
      </span>
    </Link>
  </div>
);

const Home = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getProducts());
  }, [dispatch]);

  const { products } = useSelector((state) => state.product);
  const latest = products
    ?.filter((product) => {
      return product.quantity > 0;
    })
    ?.filter((product, index) => index < 10);
  //Bags
  const wbags = products
    ?.filter((product) => {
      return product.quantity > 0;
    })
    ?.filter((product) => {
      return product.category === "Bags" || product.category === "bags";
    })

    ?.filter((product, index) => index < 10);
  //Pyjamas
  const pyjamas = products
    ?.filter((product) => {
      return product.quantity > 0;
    })
    ?.filter((product) => {
      return (
        product.category === "pyjamas" ||
        product.category === "pajamas" ||
        product.category === "Pyjamas" ||
        product.category === "Pajamas"
      );
    })
    ?.filter((product, index) => index < 10);

  //Bikini
  const bikini = products
    ?.filter((product) => {
      return product.quantity > 0;
    })
    ?.filter((product) => {
      return product.category === "bikini" || product.category === "Bikini";
    })
    ?.filter((product, index) => index < 10);

  //Under wear
  const underwear = products
    ?.filter((product) => {
      return product.quantity > 0;
    })
    ?.filter((product) => {
      return (
        product.category === "underwears" || product.category === "Underwears"
      );
    })
    ?.filter((product, index) => index < 10);

  //Men Short
  const menShort = products
    ?.filter((product) => {
      return product.quantity > -1;
    })
    ?.filter((product) => {
      return product.mainCategory === "Men";
    })
    ?.filter((product, index) => index < 10);

  //Women Heel
  const heel = products
    ?.filter((product) => {
      return product.quantity > 0;
    })
    ?.filter((product) => {
      return product.category === "heels" || product.category === "Heels";
    })
    ?.filter((product, index) => index < 10);

  const latestProducts = latest.map((item) => (
    <div key={item._id}>
      <CarouselItem
        name={item.name}
        imageurl={item.image[0]}
        price={item.price}
        regularPrice={item.regularPrice}
        product={item}
        quantity={item.quantity}
      />
    </div>
  ));

  const bagProducts = wbags.map((item) => (
    <div key={item._id}>
      <CarouselItem
        name={item.name}
        imageurl={item.image[0]}
        price={item.price}
        regularPrice={item.regularPrice}
        product={item}
        quantity={item.quantity}
      />
    </div>
  ));
  //Pyjamas
  const pyjamasProduct = pyjamas.map((item) => (
    <div key={item._id}>
      <CarouselItem
        name={item.name}
        imageurl={item.image[0]}
        price={item.price}
        regularPrice={item.regularPrice}
        product={item}
        quantity={item.quantity}
      />
    </div>
  ));
  //Bikini
  const bikiniProduct = bikini.map((item) => (
    <div key={item._id}>
      <CarouselItem
        name={item.name}
        imageurl={item.image[0]}
        price={item.price}
        regularPrice={item.regularPrice}
        product={item}
        quantity={item.quantity}
      />
    </div>
  ));
  //Under wears
  const underwearProduct = underwear.map((item) => (
    <div key={item._id}>
      <CarouselItem
        name={item.name}
        imageurl={item.image[0]}
        price={item.price}
        regularPrice={item.regularPrice}
        product={item}
        quantity={item.quantity}
      />
    </div>
  ));
  //Men Short
  const menShortProduct = menShort.map((item) => (
    <div key={item._id}>
      <CarouselItem
        name={item.name}
        imageurl={item.image[0]}
        price={item.price}
        regularPrice={item.regularPrice}
        product={item}
        quantity={item.quantity}
      />
    </div>
  ));

  //Heels
  const heelProduct = heel.map((item) => (
    <div key={item._id}>
      <CarouselItem
        name={item.name}
        imageurl={item.image[0]}
        price={item.price}
        regularPrice={item.regularPrice}
        product={item}
        quantity={item.quantity}
      />
    </div>
  ));
  return (
    <>
      <Meta
        robots={"follow, index"}
        googlebot={"follow,index"}
        title={"Home - Shop With Meenah"}
        descName={
          "Shop luxury and quality bags, pyjamas, bikini, heels, and more special products ... Place your order and dazzle yourself with stunning and luxury ..."
        }
        canonical={"https://shopwithmeenah.com"}
        keywords={
          "shopwithmeenah, shop with meenah, Shop with meenah, Shopwithmeenah"
        }
      />

      <div className="--relative">
        <Slider />
      </div>

      <div>
        <HomeInfoBox />
      </div>
      <div>
        <PageHeading
          heading={"New Arrivals"}
          boldText={"Boost your productivity"}
        />
        <ProductCarousel products={latestProducts} />
        <Button value={"View all Products"} path={"/shop"} />
      </div>

      <div className="--bg-lightgray">
        <PageHeading heading={"Categories"} boldText={"All Products"} />
        <ProductCategory />
      </div>

      <div>
        <PageHeading heading={"Bags"} boldText={"Women Fashion Bags"} />
        <ProductCarousel products={bagProducts} />
        <Button value={"Shop now"} path={"/women-products"} />
      </div>

      <div>
        <PageHeading heading={"Pajamas"} boldText={"Hot Night Wears"} />
        <ProductCarousel products={pyjamasProduct} />
        <Button value={"Shop now"} path={"/shop"} />
      </div>

      <div>
        <PageHeading heading={"Bikini"} boldText={"Hot Beach Wears"} />
        <ProductCarousel products={bikiniProduct} />
        <Button value={"Shop now"} path={"/women-products"} />
      </div>

      <div>
        <PageHeading heading={"Underwears"} boldText={"Mens & Women"} />
        <ProductCarousel products={underwearProduct} />
        <Button value={"Shop now"} path={"/shop"} />
      </div>

      <div>
        <PageHeading heading={"Short"} boldText={"Men's Short & more..."} />
        <ProductCarousel products={menShortProduct} />
        <Button value={"Shop now"} path={"/men-products"} />
      </div>

      <div>
        <PageHeading heading={"Heels"} boldText={"Stunning Heels"} />
        <ProductCarousel products={heelProduct} />
        <Button value={"Shop now"} path={"/women-products"} />
      </div>
      {/* <div>
        <PageHeading
          heading={"Special Products"}
          boldText={"For Men and Women"}
        />
        <Specialproducts />
        <Button value={"Shop Special"} path={"/special-products"} />
      </div> */}
    </>
  );
};

export default Home;
