import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../loader/Loader";
import Card from "../../card/Card";
import {
  createBrand,
  getBrands,
  getCategories,
} from "../../../redux/features/categoryAndBrand/CategoryAndBrandSlice";
import { toast } from "react-toastify";

const CreateBrand = () => {
  const [name, setName] = useState("");
  const [category, setCategory] = useState("");

  const { loading, categories } = useSelector((state) => state.category);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCategories());
  }, [dispatch]);

  const saveBrand = async (e) => {
    e.preventDefault();
    if (name.length < 3) {
      return toast.error("Brand Name must be up to 3 characters");
    }
    if (!category) {
      return toast.error("Please add a parent category");
    }
    const formData = {
      name,
      category,
    };
    await dispatch(createBrand(formData));
    await dispatch(getBrands());
    setName("");
  };

  return (
    <>
      {loading && <Loader />}
      <div className="--mb2 create-category">
        <h3>CreateCategory</h3>
        <p>
          Use the form to <b className="--fw-thick">Create a Brand</b>
        </p>
        <Card cardClass={"card"}>
          <br />
          <form onSubmit={saveBrand}>
            <label htmlFor="">Brand Name:</label>
            <input
              type="text"
              name="name"
              value={name}
              placeholder="Brand Name"
              onChange={(e) => setName(e.target.value)}
              required
            />
            <input
              type="submit"
              value="Save Brand"
              className="--btn --btn-primary"
            />

            <label htmlFor="">Parent Category:</label>
            <select
              name="category"
              className="form-control"
              onChange={(e) => setCategory(e.target.value)}
              style={{ minHeight: "40px" }}
            >
              <option>select category</option>
              {categories.length > 0 &&
                categories.map((cat) => (
                  <option key={cat._id} value={cat.name}>
                    {cat.name}
                  </option>
                ))}
            </select>
          </form>
        </Card>
      </div>
    </>
  );
};

export default CreateBrand;
