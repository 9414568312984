import { FaArrowTrendDown, FaArrowTrendUp } from "react-icons/fa6";

export const shortenText = (text, n) => {
  if (text.length > n) {
    const shortenedText = text.substring(0, n).concat("...");
    return shortenedText;
  }
  return text;
};

export const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
export const FRONTEND_URL = process.env.REACT_APP_FRONTEND_URL;

// Extract id and cart quantity from cartItems -- Checkout
export function extractIdAndCartQuantity(products) {
  return products.map(function (product) {
    return {
      _id: product._id,
      cartQuantity: product.cartQuantity,
    };
  });
}

// Calculate average Product rating
export function calculateAverageRating(ratings) {
  if (!Array.isArray(ratings) || ratings.length === 0) {
    return 0; // Return 0 if the ratings array is empty or not an array
  }

  var totalStars = 0;
  for (var i = 0; i < ratings.length; i++) {
    var rating = ratings[i];
    if (rating.hasOwnProperty("star")) {
      totalStars += rating.star;
    }
  }

  return totalStars / ratings.length;
}

export function getCartQuantityById(products, id) {
  for (let i = 0; i < products.length; i++) {
    if (products[i]._id === id) {
      return products[i].cartQuantity;
    }
  }
  return 0; // If the _id is not found, return 0 or any default value
}

export const calculatePercentage = (highestPrice, lowestPrice) => {
  const a = highestPrice;
  const b = lowestPrice;
  const c = (b / a) * 100;
  const d = 100;
  if (c >= 100) {
    return (
      <>
        <small className="--f12 --bg-green --items-center price-percent">
          <FaArrowTrendUp size={9} />
          &nbsp;
          {c.toFixed() - d}%
        </small>
      </>
    );
  }
  return (
    <>
      <small className="--f12 --bg-danger --items-center price-percent">
        <FaArrowTrendDown size={9} />
        &nbsp;
        {d - c.toFixed()}%
      </small>
    </>
  );
};

const generateNumbers = (num) => {
  let number = [];

  for (let i = 1; i <= num; i++) {
    number.push(i);
  }
  return number;
};

export default generateNumbers;
