import React from "react";
import ListOfOrders from "./ListOfOrders";
import { useNavigate } from "react-router-dom";

const Orders = () => {
  const navigate = useNavigate();

  const openOrderDetails = (id) => {
    navigate(`/admin/order-details/${id}`);
  };

  return (
    <div className="container order">
      <h3>All Orders</h3>
      <small>
        open an order to{" "}
        <span className="--color-primary">Change order status</span>
      </small>
      <br />
      <ListOfOrders openOrderDetails={openOrderDetails} />
    </div>
  );
};

export default Orders;
