import React from "react";
import Meta from "../../components/meta/Meta";
import Loader from "../../components/loader/Loader";

const Tos = () => {
  return (
    <>
      <Meta
        title={"Terms of Service - Shop With Meenah"}
        canonical={"https://shopwithmeenah.com/terms-of-service"}
      />
      {/* <div className="--f14 --color-grey">Content loading...</div>{" "} */}
      <div>
        <Loader />
      </div>
    </>
  );
};

export default Tos;
