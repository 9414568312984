import { NavLink } from "react-router-dom";
import css from "./Navbar.module.scss";
import { IconContext } from "react-icons/lib";
import { TbBrandAppgallery } from "react-icons/tb";
import { RxDashboard } from "react-icons/rx";
import { FaBoxOpen } from "react-icons/fa";
import { RiCoupon4Line } from "react-icons/ri";
import { BiSolidCategoryAlt } from "react-icons/bi";
import {
  MdOutlineAddShoppingCart,
  MdOutlineShoppingCartCheckout,
} from "react-icons/md";

const activeLink = ({ isActive }) => (isActive ? `${css.active}` : "");

const Navbar = () => {
  return (
    <div>
      <div className={css.navs}>
        <ul>
          <IconContext.Provider
            value={{ size: 20, className: `${css["n-icons"]}` }}
          >
            <li>
              <NavLink to="dashboard" className={activeLink}>
                <RxDashboard />
                Dashboard
              </NavLink>
            </li>
            <li>
              <NavLink to="all-products" className={activeLink}>
                <MdOutlineShoppingCartCheckout />
                All Products
              </NavLink>
            </li>
            <li>
              <NavLink to="add-product" className={activeLink}>
                <MdOutlineAddShoppingCart />
                Add Product
              </NavLink>
            </li>
            <li>
              <NavLink to="orders" className={activeLink}>
                <FaBoxOpen />
                Orders
              </NavLink>
            </li>
            <li>
              <NavLink to="coupon" className={activeLink}>
                <RiCoupon4Line />
                Coupon
              </NavLink>
            </li>

            <li>
              <NavLink to="category" className={activeLink}>
                <BiSolidCategoryAlt />
                Category
              </NavLink>
            </li>
            <li>
              <NavLink to="brand" className={activeLink}>
                <TbBrandAppgallery />
                Brand
              </NavLink>
            </li>
            <li>
              <NavLink to="colors" className={activeLink}>
                <TbBrandAppgallery />
                Colors
              </NavLink>
            </li>
            <li>
              <NavLink to="sizes" className={activeLink}>
                <TbBrandAppgallery />
                Sizes
              </NavLink>
            </li>
          </IconContext.Provider>
        </ul>
      </div>
    </div>
  );
};

export default Navbar;
