import React, { useState } from "react";
import "./FooterLinks.scss";
import {
  FaInstagram,
  FaTelegramPlane,
  FaTiktok,
  FaWhatsapp,
} from "react-icons/fa";
import { BsSnapchat } from "react-icons/bs";

import { IconContext } from "react-icons";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { regMail } from "../../redux/features/newsletter/newsSlice";

const FooterLinks = () => {
  const [email, setEmail] = useState("");
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.news);
  const handleNews = async (e) => {
    e.preventDefault();
    const formData = { email };
    await dispatch(regMail(formData));
    setEmail("");
  };

  return (
    <>
      {/* Footer Links */}
      <div className="footer-section">
        <div className="container footer">
          <div className="footer-menu">
            <p className="link-heading">Useful Links</p>
            <ul className="nav-ul footer-links">
              <li>
                <a href="/contact-us" target="_blank">
                  Contact us
                </a>
              </li>
              <li>
                <a href="/about-us" target="_blank">
                  About us
                </a>
              </li>
              <li>
                <a href="/privacy-policy" target="_blank">
                  Privacy Policy
                </a>
              </li>
              <li>
                <a href="/terms-of-service" target="_blank">
                  Terms of Service
                </a>
              </li>{" "}
            </ul>
          </div>

          <div className="footer-menu">
            <p className="link-heading">Quick Help</p>
            <ul className="nav-ul footer-links">
              <li className="--f14" style={{ color: "#ddd" }}>
                support@shopwithmeenah.com
              </li>
              <li>
                <a href="/contact-us" className="btn btn-dark" target="_blank">
                  Make an enquiry!
                </a>
              </li>
            </ul>
          </div>

          <div className="footer-menu">
            <p className="link-heading">News Letter</p>
            <ul className="footer-links">
              <ul className="nav-ul footer-links">
                <div className="news-letter">
                  <small>
                    Stay up to date with what's going on at SW Meenah.
                  </small>
                  <br />
                  <form method="post" onSubmit={handleNews}>
                    <div className="news-mail">
                      <input
                        type="email"
                        name="email"
                        value={email}
                        required
                        placeholder="Enter E-mail Adress"
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      {!loading ? (
                        <button type="submit">
                          <FaTelegramPlane size={22} />
                        </button>
                      ) : (
                        "..."
                      )}
                    </div>
                  </form>
                </div>
              </ul>
            </ul>
          </div>

          <div className="footer-menu">
            <p className="link-heading">Follow us</p>
            <ul className="footer-links">
              <ul className="nav-ul footer-links">
                <div className="f-icons-w">
                  <div className="f-icons">
                    <IconContext.Provider value={{ size: 24, color: "#ddd" }}>
                      <Link
                        to={"https://wa.me/message/JWKOBH7JOUKQN1"}
                        target="_blank"
                      >
                        <FaWhatsapp />
                      </Link>
                      <Link
                        to={"https://instagram.com/shopwithmeenah_24"}
                        target="_blank"
                      >
                        <FaInstagram />
                      </Link>
                      <Link
                        to={"https://snapchat.com/add/meenah2350"}
                        target="_blank"
                      >
                        <BsSnapchat />
                      </Link>
                      <Link
                        to={"https://tiktok.com/@shopwithmeenah_24"}
                        target="_blank"
                      >
                        <FaTiktok />
                      </Link>
                    </IconContext.Provider>
                  </div>
                </div>
              </ul>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default FooterLinks;
