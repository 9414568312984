import { useDispatch, useSelector } from "react-redux";
import { selectIsLoggedIn } from "../../../../redux/features/auth/authSlice";
import { useEffect, useState } from "react";
import {
  deleteProduct,
  getProducts,
} from "../../../../redux/features/product/productSlice";
import Search from "../../../search/Search";
import Spinner from "../../../loader/Loader";
import { AiOutlineEye } from "react-icons/ai";
import { FaEdit, FaTrashAlt } from "react-icons/fa";
import { Link } from "react-router-dom";
import "./ViewProducts.scss";
import { shortenText } from "../../../../utils/index";
import ReactPaginate from "react-paginate";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { FaNairaSign } from "react-icons/fa6";
import {
  FILTER_BY_SEARCH,
  selectFilteredProducts,
} from "../../../../redux/features/product/filterSlice";
import { MdOutlineNavigateNext } from "react-icons/md";
import { GrFormPrevious } from "react-icons/gr";

const ViewProducts = () => {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const [search, setSearch] = useState("");
  const filteredProducts = useSelector(selectFilteredProducts);

  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffSet] = useState(0);
  const itemsPerPage = 25;

  const { products, loading } = useSelector((state) => state.product);
  useEffect(() => {
    if (isLoggedIn) {
      dispatch(getProducts());
    }
  }, [isLoggedIn, dispatch]);

  const confirmDelete = (id) => {
    confirmAlert({
      title: "Delete Product",
      message: "Are you sure to delete this Product",
      buttons: [
        {
          label: "Delete",
          onClick: () => delProduct(id),
        },
        {
          label: "Cancel",
          // onClick: () => alert("Click No")
        },
      ],
    });
  };

  const delProduct = async (id) => {
    await dispatch(deleteProduct(id));
    await dispatch(getProducts());
  };

  //Pagination
  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(filteredProducts.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(filteredProducts.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, filteredProducts]);

  const handlePageClick = (e) => {
    const newOffset = (e.selected * itemsPerPage) % filteredProducts.length;
    setItemOffSet(newOffset);
    //Paginate ends...
  };
  useEffect(() => {
    dispatch(FILTER_BY_SEARCH({ products, search }));
  }, [dispatch, products, search]);
  return (
    <section>
      <div className="container product-list hideforscreen">
        <div className="table ">
          <div className="--flex-between --flex-dir-column">
            <span>
              <h3>All Products</h3>
              <p>
                ~<b>{products.length}</b> Products found
              </p>
            </span>
            <span>
              <Search
                placeholder={"Search Products"}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </span>
          </div>
        </div>
        {loading && <Spinner />}
        <div className="table">
          {!loading && products.lenght === 0 ? (
            <p>No Product found...</p>
          ) : (
            <table>
              <thead>
                <tr>
                  <th>s/n</th>
                  <th>Image</th>
                  <th>Name</th>
                  <th>Category</th>
                  <th>Price</th>
                  <th>Quantity</th>
                  <th>Value</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {currentItems.map((product, index) => {
                  const { _id, name, category, price, quantity, image } =
                    product;
                  return (
                    <tr key={_id}>
                      <td>{index + 1}</td>
                      <td>
                        <img src={image[0]} alt="" width={80} height={80} />
                      </td>
                      <td>{name}</td>
                      <td>{category}</td>
                      <td>
                        {"#"}
                        {price}
                      </td>
                      <td>{quantity}</td>
                      <td>
                        {"#"}
                        {price * quantity}
                      </td>
                      <td>
                        <span style={{ marginRight: "10px" }}>
                          <Link to={`/product-details/${_id}`}>
                            <AiOutlineEye size={20} color={"var(--mag)"} />
                          </Link>
                        </span>
                        <span style={{ marginRight: "10px" }}>
                          <Link to={`/admin/edit-product/${_id}`}>
                            <FaEdit size={20} color={"var(--mag)"} />
                          </Link>
                        </span>
                        <span>
                          <FaTrashAlt
                            size={20}
                            color={"mag"}
                            onClick={() => confirmDelete(_id)}
                          />
                        </span>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
        </div>
      </div>
      {/* Mobile View */}
      <div
        style={{ background: "white" }}
        className="mobile-product screen-wrapper"
      >
        {currentItems.map((product, index) => {
          const { _id, name, category, price, quantity } = product;
          return (
            <>
              <div key={_id} className="admin-products">
                <div className="--flex-between">
                  <span>{shortenText(name, 12)}</span>
                  <span className="--flex-center">
                    <FaNairaSign />
                    {price.toLocaleString(2)}
                  </span>
                </div>
                <div className="--flex-between">
                  <span style={{ fontSize: "14px" }}>
                    <small>Category:</small>
                    {category}
                  </span>
                  <span>
                    <small>Quantity:</small>
                    {quantity}
                  </span>
                </div>
                <div className="--flex-between">
                  <Link
                    className="--color-green"
                    to={`/admin/edit-product/${_id}`}
                  >
                    Edit
                  </Link>
                  <Link
                    className="--color-danger"
                    onClick={() => confirmDelete(_id)}
                  >
                    Delete
                  </Link>
                </div>
              </div>
            </>
          );
        })}
      </div>
      {/* Ends */}
      <ReactPaginate
        breakLabel={"..."}
        nextLabel={<MdOutlineNavigateNext size={20} />}
        onPageChange={handlePageClick}
        pageRangeDisplayed={1}
        pageCount={pageCount}
        previousLabel={<GrFormPrevious size={20} />}
        renderOnZeroPageCount={null}
        containerClassName="pagination"
        pageLinkClassName="page-num"
        previousLinkClassName="page-num"
        nextLinkClassName="page-num"
        activeLinkClassName="activePage"
      />
    </section>
  );
};

export default ViewProducts;
