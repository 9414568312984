import React, { useEffect, useState } from "react";
import Card from "../../components/card/Card";
import css from "./CheckoutDetails.module.scss";
import { CountryDropdown } from "react-country-region-selector";
import { useDispatch, useSelector } from "react-redux";
import {
  SAVE_SHIPPING_ADDRESS,
  selectPaymentMethod,
  selectShippingAddress,
} from "../../redux/features/checkout/CheckoutSlice";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import CheckoutSummary from "../../components/checkout/checkoutSummary/CheckoutSummary";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import Meta from "../../components/meta/Meta";

const initialAddressState = {
  name: "",
  line1: "",
  line2: "",
  city: "",
  state: "",
  postal_code: "",
  phone: "",
};

const CheckoutDetails = () => {
  const [shippingAddress, setShippingAddress] = useState({
    ...initialAddressState,
  });

  const paymentMethod = useSelector(selectPaymentMethod);
  const shipAddress = useSelector(selectShippingAddress);

  useEffect(() => {
    if (Object.keys(shipAddress).length > 0) {
      setShippingAddress({ ...shipAddress });
    }
  }, [shipAddress]);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleShipping = (e) => {
    const { name, value } = e.target;
    setShippingAddress({
      ...shippingAddress,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(SAVE_SHIPPING_ADDRESS(shippingAddress));

    confirmAlert({
      title: "Confirm Checkout",
      message:
        "Kindly be aware that your order did not include the shipping fee, as this will be determined after your order is placed.",
      buttons: [
        {
          label: "Checkout",
          onClick: () => {
            if (paymentMethod === "") {
              toast.info("Please select a payment method!!!");
              navigate("/cart");
            }
            if (paymentMethod === "flutter-wave") {
              navigate("/checkout-flutter-wave");
            }
            if (paymentMethod === "sFlutterwave") {
              navigate("/checkout-sflutter-wave");
            }
            if (paymentMethod === "pay-stack") {
              navigate("/checkout-pay-stack");
            }
            if (paymentMethod === "wallet") {
              navigate("/checkout-wallet");
            }
          },
        },
        {
          label: "Cancel",
          onClick: () => navigate(-1),
        },
      ],
    });
  };

  return (
    <section>
      <Meta
        robots={"nofollow, noindex"}
        googlebot={"nofollow, noindex"}
        title={"Checkout Details"}
      />
      <div className={`container ${css.checkout}`}>
        <br />
        <h3>Checkout Details</h3>
        <br />
        <form onSubmit={handleSubmit}>
          <div>
            <Card cardClass={css.card}>
              <h3>Shipping Address</h3>
              <br />
              <label>Recipent Name*</label>
              <input
                type="text"
                name="name"
                placeholder="Recipent Name"
                value={shippingAddress.name}
                onChange={(e) => handleShipping(e)}
                required
              />
              <label>Address line 1*</label>
              <input
                type="text"
                name="line1"
                placeholder="Address line 1"
                value={shippingAddress.line1}
                onChange={(e) => handleShipping(e)}
                required
              />
              <label>Address line 2</label>
              <input
                type="text"
                name="line2"
                placeholder="Address line 2"
                value={shippingAddress.line2}
                onChange={(e) => handleShipping(e)}
              />
              <label>City*</label>
              <input
                type="text"
                name="city"
                placeholder="City"
                value={shippingAddress.city}
                onChange={(e) => handleShipping(e)}
                required
              />
              <label>State*</label>
              <input
                type="text"
                name="state"
                placeholder="State"
                value={shippingAddress.state}
                onChange={(e) => handleShipping(e)}
                required
              />
              <label>Postal Code (optional)</label>
              <input
                type="text"
                name="postal_code"
                placeholder="Postal Code"
                value={shippingAddress.postal_code}
                onChange={(e) => handleShipping(e)}
              />
              <label htmlFor="">Country*</label>
              <CountryDropdown
                required
                className={css.select}
                valueType="short"
                showDefaultOption={true}
                value={shippingAddress.country}
                onChange={(val) =>
                  handleShipping({
                    target: {
                      name: "country",
                      value: val,
                    },
                  })
                }
              />
              <label>Phone Number*</label>
              <input
                type="text"
                name="phone"
                placeholder="Phone"
                value={shippingAddress.phone}
                onChange={(e) => handleShipping(e)}
                required
              />
            </Card>
          </div>
          <div>
            <Card cardClass={css.card}>
              <CheckoutSummary />
              <br />
              <input
                type="submit"
                className="--btn --btn-primary"
                value="Proceed to checkout"
              />
            </Card>
          </div>
        </form>
      </div>
    </section>
  );
};

export default CheckoutDetails;
