import React, { useEffect, useState } from "react";
import { MdPassword } from "react-icons/md";
import "./Auth.scss";
import { Link, useNavigate, useParams } from "react-router-dom";
import bag from "../../assets/bag1-min.png";
import PasswordInput from "../../components/passwordInput/PasswordInput";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { RESET_AUTH, resetPassword } from "../../redux/features/auth/authSlice";
import Loader from "../../components/loader/Loader";
import {
  validatePasswordCase,
  validatePasswordNum,
  validatePasswordSChar,
} from "./Register";
import { FaTimes } from "react-icons/fa";
import { BsCheck2All } from "react-icons/bs";
import Meta from "../../components/meta/Meta";

const initialState = {
  password: "",
  cpassword: "",
};

const Reset = () => {
  const [inputFocus, setInputFocus] = useState(false);
  const [formData, setFormData] = useState(initialState);
  const { password, cpassword } = formData;
  const { resetToken } = useParams();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { loading, isSuccess, message } = useSelector((state) => state.auth);

  const [uCase, setUCase] = useState(false);
  const [num, setNum] = useState(false);
  const [sChar, setSChar] = useState(false);
  const [passLength, setPassLength] = useState(false);

  const timesIcon = <FaTimes color="var(--mag)" size={15} />;
  const checkIcon = <BsCheck2All color="green" size={15} />;

  const swithIcon = (condition) => {
    if (condition) {
      return checkIcon;
    } else {
      return timesIcon;
    }
  };

  const handleFocus = (condition) => {
    if (condition) {
      setInputFocus(true);
    } else {
      setInputFocus(false);
    }
  };

  const handleBlur = (condition) => {
    if (condition) {
      setInputFocus(false);
    } else {
      setInputFocus(true);
    }
  };
  const handlePaste = (e) => {
    e.preventDefault();
    // toast.error("Sorry you cannot paste into the input field");
    return false;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  useEffect(() => {
    if (validatePasswordCase(password) || validatePasswordCase(cpassword)) {
      setUCase(true);
    } else {
      setUCase(false);
    }
    //Check for numbers
    if (validatePasswordNum(password) || validatePasswordNum(cpassword)) {
      setNum(true);
    } else {
      setNum(false);
    }
    //Check for special character
    if (validatePasswordSChar(password) || validatePasswordSChar(cpassword)) {
      setSChar(true);
    } else {
      setSChar(false);
    }
    //Check for pass length
    if (password.length > 5 || cpassword.length > 5) {
      setPassLength(true);
    } else {
      setPassLength(false);
    }
  }, [password, cpassword]);

  const reset = async (e) => {
    e.preventDefault();
    if (!password || !cpassword) {
      return toast.error("All fileds are required");
    }
    if (password.length < 6 || cpassword.length < 6) {
      return toast.error("Password must be up to 6 characters");
    }
    if (password !== cpassword) {
      return toast.error("Passwords do not match");
    }
    if (
      !validatePasswordCase(password) ||
      !validatePasswordNum(password) ||
      !validatePasswordSChar(password) ||
      !validatePasswordCase(cpassword) ||
      !validatePasswordNum(cpassword) ||
      !validatePasswordSChar(cpassword)
    ) {
      return toast.error(
        "Password must contain alpha numeric and special characters"
      );
    }
    const userData = {
      password,
    };
    await dispatch(resetPassword({ userData, resetToken }));
    await dispatch(RESET_AUTH(userData));
  };

  useEffect(() => {
    if (
      isSuccess &&
      message.includes("Password Reset Successful, Please Login")
    ) {
      navigate("/login");
    }
    dispatch(RESET_AUTH());
  }, [isSuccess, message, dispatch, navigate]);

  const logoClick = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    navigate("/");
  };

  return (
    <>
      <Meta
        robots={"nofollow, noindex"}
        googlebot={"nofollow, noindex"}
        title={"Reset Password"}
      />
      {loading && <Loader LoadingState={"Resetting Password..."} />}
      <div className="form">
        <div className="form-wrapper">
          <div className="--flex-center">
            <MdPassword size={35} color="#999" />
          </div>
          <h4>Change Password</h4>
          <form onSubmit={reset}>
            <PasswordInput
              placeholder="Password"
              name="password"
              value={password}
              onChange={handleInputChange}
              onPaste={handlePaste}
              onFocus={handleFocus}
              onBlur={handleBlur}
            />
            <PasswordInput
              placeholder="Confirm Password"
              name="cpassword"
              value={cpassword}
              onChange={handleInputChange}
              onPaste={handlePaste}
              onFocus={handleFocus}
              onBlur={handleBlur}
            />
            <div className={inputFocus ? "group" : "none"}>
              <ul className="form-list">
                <li>
                  <span className="indicator">
                    {swithIcon(uCase)}
                    &nbsp; Lowercase & Uppercase
                  </span>
                </li>
                <li>
                  <span className="indicator">
                    {swithIcon(num)}
                    &nbsp; Number (0-9)
                  </span>
                </li>
                <li>
                  <span className="indicator">
                    {swithIcon(sChar)}
                    &nbsp; Special Chracter (!@#$%^&*?)
                  </span>
                </li>
                <li>
                  <span className="indicator">
                    {swithIcon(passLength)}
                    &nbsp; At Least 6 Character
                  </span>
                </li>
              </ul>
            </div>
            <span className="remember-section">
              <label onClick={() => navigate("/login")}>
                Login your Account
              </label>
              <span onClick={() => navigate("/register")}>
                Create new Account
              </span>
            </span>
            <input type="submit" value="Submit" />
          </form>
          <span className="new-user">
            Return to &nbsp;
            <Link to="/" className="sign-up">
              HomePage
            </Link>
          </span>

          <div className="powered-by" onClick={logoClick}>
            <span>Powered by </span>
            <span>
              <img src={bag} alt="logo" width={100} />
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default Reset;
