import css from "./Footer.module.scss";
import { Link } from "react-router-dom";
import FooterLinks from "./FooterLinks";
import google from "../../assets/google.png";
import apple from "../../assets/applw.png";

const Footer = () => {
  const date = new Date();
  const year = date.getFullYear();

  return (
    <>
      <FooterLinks />
      <div className={css.footer}>
        <div className="--flex-start">
          <span className="--mr">
            &copy; {year} Shop With Meenah. All Rights Reserved.
          </span>
          <Link to="https://6dev.tech" className="--underline " target="_blank">
            Design and Developed by 6developer{" "}
          </Link>
        </div>

        <div className="--flex-end --mt10 --btn-block">
          <img src={google} width={110} alt="" className="--mr" />
          <img src={apple} width={100} alt="Apple" />
        </div>
      </div>
    </>
  );
};

export default Footer;
