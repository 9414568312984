import React from "react";
import scss from "./ProductItem.module.scss";
import { Link } from "react-router-dom";
import {
  calculateAverageRating,
  calculatePercentage,
  shortenText,
} from "../../../utils";
import DOMPurify from "dompurify";
import Review from "../../review/Review";
import { useDispatch } from "react-redux";
import { GiSelfLove } from "react-icons/gi";
import { addToWishlist } from "../../../redux/features/auth/authSlice";
import { FaNairaSign } from "react-icons/fa6";

const ProductItem = ({
  product,
  grid,
  _id,
  name,
  price,
  image,
  regularPrice,
  brand,
}) => {
  const averageRating = calculateAverageRating(product?.ratings);
  const dispatch = useDispatch();

  const addToWish = (product) => {
    const productData = { productId: product._id };
    dispatch(addToWishlist(productData));
  };

  return (
    // <Card cardClass={grid ? `${scss.grid} carouselItem` : `${scss.list}`}>
    <div className={grid ? `${scss.grid}` : `${scss.list}`}>
      <Link to={`/product-details/${_id}`}>
        <img src={image[0]} alt={name} className={scss.img} />
      </Link>

      <div className={grid ? `product-short-info` : `${scss.details}`}>
        <div className="--flex-between">
          <small className="brand-name">{brand}</small>
          {grid && (
            <GiSelfLove
              size={20}
              onClick={() => addToWish(product)}
              className="cart-sw"
            />
          )}
        </div>
        <Link to={`/product-details/${_id}`}>
          <h4 className="product-name">{shortenText(name, 15)}</h4>
          <small className={`--flex ${scss.rview}`}>
            <Review
              averageRating={averageRating}
              noOfRatings={product?.ratings.length}
              starDimension={"14px"}
            />
          </small>
          <div className="price --flex-between">
            <div className="--flex">
              <b className="--items-center --f14">
                <FaNairaSign />
                {Number(price).toLocaleString()}
              </b>
              <span className="quantity ">
                {regularPrice > 0 && (
                  <del className="--items-center --f14">
                    <FaNairaSign />
                    {regularPrice.toLocaleString()}
                  </del>
                )}
              </span>
            </div>

            <span className={scss["percentage-discount"]}>
              {grid && regularPrice && calculatePercentage(regularPrice, price)}
            </span>
          </div>
        </Link>

        {!grid && (
          <p
            className={`${scss["list-desc"]} --fw-thin --f14`}
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(
                shortenText(product?.description, 200)
              ),
            }}
          ></p>
        )}
      </div>
    </div>
  );
};

export default ProductItem;
