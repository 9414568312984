import React, { useState } from "react";
import styles from "./Admin.module.scss";
import { Route, Routes } from "react-router-dom";
import {
  AddProduct,
  Coupon,
  AdminHome,
  AdminNavbar,
  Brand,
  Category,
  EditProduct,
  ViewProducts,
} from "../../components";
import Orders from "../../components/admin/orders/Orders";
import OrderDetails from "../../components/admin/orders/OrderDetails";
import { TbMenu2 } from "react-icons/tb";
import Colors from "../../components/admin/colors/Colors";
import Size from "../../components/admin/size/Size";
import Meta from "../../components/meta/Meta";
import Customers from "../../components/admin/customers/Customers";

const Admin = () => {
  const [mview, setMview] = useState(false);
  const toggleMenu = () => {
    setMview(!mview);
  };
  return (
    <>
      <Meta robots={"nofollow, noindex"} googlebot={"nofollow, noindex"} />
      <div className={styles.admin}>
        <div
          className={mview ? styles.navbar : styles["--display-none"]}
          onClick={toggleMenu}
        >
          {mview && (
            <>
              <AdminNavbar />
              <div className={styles["close-bg"]}></div>
            </>
          )}
        </div>
        <div className={styles.content}>
          <span>
            <TbMenu2 size={22} onClick={toggleMenu} />
          </span>
          <Routes>
            <Route path="dashboard" element={<AdminHome />} />
            <Route path="category" element={<Category />} />
            <Route path="brand" element={<Brand />} />

            <Route path="add-product" element={<AddProduct />} />
            <Route path="coupon" element={<Coupon />} />

            <Route path="all-products" element={<ViewProducts />} />
            <Route path="edit-product/:id" element={<EditProduct />} />

            <Route path="orders" element={<Orders />} />
            <Route path="order-details/:id" element={<OrderDetails />} />
            <Route path="colors" element={<Colors />} />

            <Route path="sizes" element={<Size />} />
            <Route path="customers" element={<Customers />} />
          </Routes>
        </div>
      </div>
    </>
  );
};

export default Admin;
