import { Link } from "react-router-dom";
import "./NotFound.scss";
import nf from "../../assets/m-404.png";
import Meta from "../../components/meta/Meta";

const NotFound = () => {
  return (
    <>
      <Meta
        robots={"nofollow, noindex"}
        googlebot={"nofollow, noindex"}
        title={"404 not found"}
      />
      <div className="notfound">
        <span className="nf-img-cont">
          <img src={nf} alt="notfound" />
        </span>

        <span className="nf-text-cont">
          <h3>404 Page Not Found</h3>
          <p>
            This page does'nt exist, but the{" "}
            <Link to="/" className="--inline-link">
              home page
            </Link>{" "}
            does
          </p>
          <p>We also have much more shopping items for you to browse!</p>
          <span className="global-links">
            <Link to="/women-products">Women</Link>
            <Link to="/men-products">Men</Link>
            <Link to="/special-products">Special</Link>
          </span>
        </span>
      </div>{" "}
    </>
  );
};

export default NotFound;
