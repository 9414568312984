import { useState } from "react";
import ReactPaginate from "react-paginate";
import "./WalletTransac.scss";
import { useSelector } from "react-redux";
import { Spinner } from "../../components/loader/Loader";
import { useNavigate } from "react-router-dom";
import { FiArrowUpRight } from "react-icons/fi";
import { LuArrowDownRight } from "react-icons/lu";
import { FaNairaSign } from "react-icons/fa6";
import { shortenText } from "../../utils";
import { MdOutlineNavigateNext } from "react-icons/md";
import { GrFormPrevious } from "react-icons/gr";

const WalletTransaction = ({ transactions, user }) => {
  const navigate = useNavigate();
  const { loading } = useSelector((state) => state.transaction);

  //Pagination
  const itemsPerPage = 15;
  const [itemOffSet, setitemOffSet] = useState(0);
  const endOffset = itemOffSet + itemsPerPage;
  const currentItems = transactions.slice(itemOffSet, endOffset);
  const pageCount = Math.ceil(transactions.length / itemsPerPage);

  const handlePageClick = (e) => {
    const newOffset = (e.selected * itemsPerPage) % transactions.length;
    setitemOffSet(newOffset);
    //Paginate ends...
  };

  return (
    <>
      <div className="wallet-transactions">
        <br />
        {transactions.length > 0 && <h3>All Transactions </h3>}
        <div className="table hideforscreen">
          {transactions.length === 0 ? (
            <p>No Transaction Found</p>
          ) : (
            <>
              {loading && <Spinner />}
              <table>
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Transaction ID</th>
                    <th>Amount</th>
                    <th>Type</th>
                    <th>Ref Account</th>
                    <th>Description</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {currentItems.map((transaction) => {
                    const {
                      _id,
                      createdAt,
                      amount,
                      sender,
                      receiver,
                      description,
                      status,
                    } = transaction;
                    return (
                      <tr
                        key={_id}
                        onClick={() => navigate(`/transaction-details/${_id}`)}
                      >
                        <td>{createdAt}</td>
                        <td>{_id}</td>
                        <td>
                          {sender === user.email ? "-" : "+"}
                          {amount.toLocaleString(2)}
                        </td>
                        <td>
                          {sender === user.email ? (
                            <span className="debit-transact">Debit </span>
                          ) : (
                            <span className="credit-transact">Credit</span>
                          )}
                        </td>
                        <td>{sender === user.email ? receiver : sender}</td>
                        <td>{description}</td>
                        <td>
                          <span className="credit-transact">{status}</span>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </>
          )}
        </div>

        {/* Screen Design */}
        <div className="screen-wrapper">
          {transactions.length === 0 ? (
            <p className="--color-grey">No Transaction Found</p>
          ) : (
            <>
              {currentItems.map((transaction) => {
                const { _id, createdAt, amount, sender, receiver, status } =
                  transaction;
                return (
                  <>
                    <div
                      key={_id}
                      className="screen-cont"
                      onClick={() => navigate(`/transaction-details/${_id}`)}
                    >
                      {sender === user.email ? (
                        <LuArrowDownRight size={22} color="tomato" />
                      ) : (
                        <FiArrowUpRight size={22} color="green" />
                      )}
                      <div className="--flex-col mobile-trans">
                        <span>{shortenText(receiver, 15)}</span>
                        <span>{createdAt}</span>
                      </div>
                      <div className="--flex-col mobile-transac">
                        <span
                          style={{
                            display: "flex",
                            alignItems: "center",
                            fontWeight: "bold",
                          }}
                        >
                          {sender === user.email ? "-" : "+"}
                          <FaNairaSign size={12} />
                          {amount}
                        </span>
                        <span>{status}</span>
                      </div>
                    </div>
                  </>
                );
              })}
            </>
          )}
        </div>
        {/* Ends */}

        <ReactPaginate
          breakLabel="..."
          nextLabel={<MdOutlineNavigateNext size={20} />}
          onPageChange={handlePageClick}
          pageRangeDisplayed={3}
          pageCount={pageCount}
          previousLabel={<GrFormPrevious size={20} />}
          renderOnZeroPageCount={null}
          containerClassName="pagination"
          pageLinkClassName="page-num"
          previousLinkClassName="page-num"
          nextLinkClassName="page-num"
          activeLinkClassName="activePage"
        />
      </div>
    </>
  );
};

export default WalletTransaction;
