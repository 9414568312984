import React from "react";
import "./Carousel.scss";
import { Link } from "react-router-dom";
import {
  calculateAverageRating,
  calculatePercentage,
  shortenText,
} from "../../utils";
import Review from "../review/Review";
import { useDispatch } from "react-redux";
import { FaNairaSign } from "react-icons/fa6";
import { GiSelfLove } from "react-icons/gi";
import { addToWishlist } from "../../redux/features/auth/authSlice";

const CarouselItem = ({
  imageurl,
  name,
  price,
  regularPrice,
  product,
  brand,
}) => {
  const dispatch = useDispatch();

  const addToWish = (product) => {
    const productData = { productId: product._id };
    dispatch(addToWishlist(productData));
  };
  const averageRating = calculateAverageRating(product?.ratings);
  return (
    <>
      <div className="carouselItem">
        <Link to={`/product-details/${product?._id}`}>
          <img className="product--image" src={imageurl} alt="product" />
        </Link>
        <div className="product-short-info --flex-col">
          <Link to={`/product-details/${product?._id}`}>
            <div className="--flex-between">
              <small className="brand-name">{product.brand}</small>
              <GiSelfLove size={20} onClick={addToWish} className="cart-sw" />
            </div>
            <h4 className="product-name">{shortenText(name, 18)}</h4>
            <small className="--flex rview">
              <Review
                averageRating={averageRating}
                noOfRatings={product?.ratings.length}
                starDimension={"14px"}
              />
            </small>
            <div className="price --flex-between">
              <div className="--flex">
                <b className="--items-center ">
                  <FaNairaSign />
                  {Number(price).toLocaleString()}
                </b>
                <span className="quantity ">
                  {regularPrice > 0 && (
                    <del className="--items-center --f14">
                      <FaNairaSign />
                      {regularPrice.toLocaleString()}
                    </del>
                  )}
                </span>
              </div>

              <span>
                {regularPrice && calculatePercentage(regularPrice, price)}
              </span>
            </div>
          </Link>
        </div>
      </div>
    </>
  );
};

export default CarouselItem;
