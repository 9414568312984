import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Confetti from "react-confetti";
import { useDispatch } from "react-redux";
import {
  CALCULATE_TOTAL_QUANTITY,
  CLEAR_CART,
} from "../../redux/features/cart/cartSlice";
import Meta from "../../components/meta/Meta";

const CheckoutSuccess = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(CLEAR_CART());
    dispatch(CALCULATE_TOTAL_QUANTITY());
  }, [dispatch]);

  return (
    <>
      <Meta
        robots={"nofollow, noindex"}
        googlebot={"nofollow, noindex"}
        title={"Checkout Success"}
      />
      <Confetti />
      <section style={{ height: "70vh" }}>
        <div className="container">
          <h2>Checkout Successful</h2>
          <p>Thank you for your purchase</p>
          <br />
          <button
            className="--btn --bg-fblack --color-gray"
            onClick={() => navigate("/order-history")}
          >
            View Order Status
          </button>
        </div>
      </section>
    </>
  );
};

export default CheckoutSuccess;
