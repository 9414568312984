import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Card from "../card/Card";
import {
  REMOVE_COUPON,
  getCoupon,
} from "../../redux/features/coupon/couponSlice";
import "./VerifyCoupon.scss";
import { FaCheck } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { getLoginStatus, getUser } from "../../redux/features/auth/authSlice";
import { SAVE_C } from "../../redux/features/checkout/CheckoutSlice";
import { useEffect } from "react";

export const CartDiscount = () => {
  const { coupon } = useSelector((state) => state.coupon);
  const { initialCartTotalAmount } = useSelector((state) => state.cart);
  return (
    <>
      {coupon !== null && (
        <Card cardClass={"coupon-msg"}>
          <p className="--center-all">
            Initial Total: #{initialCartTotalAmount} | Coupon: {coupon?.name} |
            Discount: {coupon?.discount}%
          </p>
        </Card>
      )}
    </>
  );
};

const VerifyCoupon = () => {
  const dispatch = useDispatch();
  const [couponName, setCouponName] = useState("");
  const [showForm, setShowForm] = useState(false);
  const { coupon } = useSelector((state) => state.coupon);

  const navigate = useNavigate();
  const { user, isLoggedIn } = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(getLoginStatus());
    if (isLoggedIn && user === null) {
      dispatch(getUser());
    }
  }, [user, dispatch, isLoggedIn]);

  const removeCoupon = () => {
    dispatch(REMOVE_COUPON());
    dispatch(SAVE_C({ name: "nil", disount: 0 }));
  };
  const verifyCoupon = (e) => {
    e.preventDefault();
    if (!user) {
      navigate("/login");
    }
    dispatch(getCoupon(couponName));
  };

  return (
    <>
      <CartDiscount />
      <div className="--flex-between">
        <p>Have a coupon?</p>
        {coupon === null ? (
          <p
            className="--cursor --color-primary"
            onClick={() => setShowForm(!showForm)}
          >
            <b>Add Coupon</b>
          </p>
        ) : (
          <p className="--cursor --color-primary" onClick={removeCoupon}>
            <b>Remove Coupon</b>
          </p>
        )}
      </div>
      {showForm && (
        <form onSubmit={verifyCoupon} className="coupon-form">
          <input
            type="text"
            name="name"
            value={couponName}
            onChange={(e) => setCouponName(e.target.value.toUpperCase())}
            required
            placeholder="Coupon Name"
          />
          <button type="submit">
            <FaCheck />
          </button>
        </form>
      )}
    </>
  );
};

export default VerifyCoupon;
