import { useState } from "react";
import { MdOutlinePassword } from "react-icons/md";
import { FaRegEye, FaEyeSlash } from "react-icons/fa";

const PasswordInput = ({
  name,
  placeholder,
  value,
  onChange,
  onPaste,
  onFocus,
  onBlur,
}) => {
  const [visible, setVisible] = useState(false);

  return (
    <div className="input-cont">
      <MdOutlinePassword size={26} color="var(--grey)" />
      <input
        type={visible ? "text" : "password"}
        placeholder={placeholder}
        name={name}
        value={value}
        onChange={onChange}
        required
        onPaste={onPaste}
        onFocus={onFocus}
        onBlur={onBlur}
      />
      {visible ? (
        <FaRegEye size={18} onClick={() => setVisible(false)} />
      ) : (
        <FaEyeSlash size={18} onClick={() => setVisible(true)} />
      )}
    </div>
  );
};

export default PasswordInput;
