import { createSlice } from "@reduxjs/toolkit";
import secureLocalStorage from "react-secure-storage";

const initialState = {
  paymentMethod: secureLocalStorage.getItem("paymentMethod")
    ? JSON.parse(secureLocalStorage.getItem("paymentMethod"))
    : "",
  shippingAddress: secureLocalStorage.getItem("shippingAddress")
    ? JSON.parse(secureLocalStorage.getItem("shippingAddress"))
    : "",
  cpn: secureLocalStorage.getItem("")
    ? JSON.parse(secureLocalStorage.getItem(""))
    : "",
};

const CheckoutSlice = createSlice({
  name: "checkout",
  initialState,
  reducers: {
    SAVE_PAYMENT_METHOD(state, action) {
      state.paymentMethod = action.payload;
      secureLocalStorage.setItem(
        "paymentMethod",
        JSON.stringify(state.paymentMethod)
      );
    },
    SAVE_SHIPPING_ADDRESS(state, action) {
      state.shippingAddress = action.payload;
      secureLocalStorage.setItem(
        "shippingAddress",
        JSON.stringify(state.shippingAddress)
      );
    },
    SAVE_C(state, action) {
      state.cpn = action.payload;
      secureLocalStorage.setItem("", JSON.stringify(state.cpn));
    },
  },
});

export const { SAVE_PAYMENT_METHOD, SAVE_SHIPPING_ADDRESS, SAVE_C } =
  CheckoutSlice.actions;
export const selectPaymentMethod = (state) => state.checkout.paymentMethod;
export const selectShippingAddress = (state) => state.checkout.shippingAddress;
export const selectC = (state) => state.checkout.cpn;
export default CheckoutSlice.reducer;
