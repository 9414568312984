import React, { useEffect, useState } from "react";
import styles from "../../components/product/productList/ProductList.module.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  getWishlist,
  removeWishlist,
} from "../../redux/features/auth/authSlice";
import ProductItem from "../../components/product/productItem/ProductItem";
import { Spinner } from "../../components/loader/Loader";
import Meta from "../../components/meta/Meta";

const Wishlist = () => {
  const [grid, setGrid] = useState(true);
  const dispatch = useDispatch();
  const { wishlist, loading } = useSelector((state) => state.auth);

  const removeWishlisting = async (product) => {
    const productId = product._id;
    await dispatch(removeWishlist(productId));
    await dispatch(getWishlist());
  };

  useEffect(() => {
    dispatch(getWishlist());
  }, [dispatch]);

  return (
    <>
      <Meta
        robots={"nofollow, noindex"}
        googlebot={"nofollow, noindex"}
        title={"My wishlist"}
      />
      <section>
        {loading && <Spinner />}
        <div className="container">
          <h3>My Wishlist</h3>
          <div className="--underline"></div>
          <div className={grid ? `${styles.grid}` : `${styles.list}`}>
            {wishlist.length === 0 ? (
              <p className="--color-grey">
                No product found in your wishlist...
              </p>
            ) : (
              <>
                {wishlist?.map((product) => {
                  return (
                    <div key={product._id}>
                      <ProductItem
                        {...product}
                        grid={grid}
                        product={product}
                        setGrid={setGrid}
                      />
                      <button
                        className="--btn --btn-danger"
                        onClick={() => removeWishlisting(product)}
                        style={{ margin: "5px 0px 15px 8px" }}
                      >
                        Romove From Wishlist
                      </button>
                    </div>
                  );
                })}
              </>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default Wishlist;
