import React, { useEffect, useState } from "react";
import { sliderData } from "./data";
import "./slider.css";
import { FaArrowRotateRight, FaArrowRotateLeft } from "react-icons/fa6";
import { Link } from "react-router-dom";

const Slider = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const slideLength = sliderData.length;

  const autoScroll = true;
  let slideInterval;
  let intervalTime = 15000;

  const nextSlide = () => {
    setCurrentSlide(currentSlide === slideLength - 1 ? 0 : currentSlide + 1);
  };
  const prevSlide = () => {
    setCurrentSlide(currentSlide === 0 ? slideLength - 1 : currentSlide - 1);
  };

  useEffect(() => {
    setCurrentSlide(0);
  }, []);

  useEffect(() => {
    if (autoScroll) {
      const auto = () => {
        slideInterval = setInterval(nextSlide, intervalTime);
      };
      auto();
    }
    return () => clearInterval(slideInterval);
  }, [currentSlide, slideInterval, autoScroll]);

  return (
    <div className="slider">
      {sliderData.map((slide, index) => {
        const { image, heading, category, desc, linkName, path } = slide;
        return (
          <div
            key={index}
            className={index === currentSlide ? "slide current" : "slide"}
          >
            {index === currentSlide && (
              <>
                <img src={image} alt="slide" />
                <div className="content">
                  <div className="top-content">
                    <div className="left-child">
                      <h3>{category}</h3>
                      <h1>{heading}</h1>
                      <p>{desc}</p>
                      <Link to={path} className="shop-btn">
                        {linkName}
                      </Link>
                    </div>
                    <div className="right-child">
                      <span className="button" onClick={prevSlide}>
                        <FaArrowRotateLeft />
                      </span>
                      <span className="button" onClick={nextSlide}>
                        <FaArrowRotateRight />
                      </span>
                    </div>
                  </div>

                  <div className="bottom-content">
                    <div className="thumbnail">
                      {sliderData.map((item, index) => {
                        return (
                          <span className="image-card" key={index}>
                            <img
                              src={item.image}
                              alt=""
                              onClick={() => setCurrentSlide(index)}
                              className={
                                index === currentSlide ? "active-thumb" : ""
                              }
                            />
                          </span>
                        );
                      })}
                      <span className="span1"></span>
                      <span className="span2"></span>
                      <span className="span3"></span>
                      <span className="span4"></span>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default Slider;
