import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./ListOfOrders.scss";
import { getOrders } from "../../../redux/features/order/orderSlice";
import Loader from "../../loader/Loader";
import ReactPaginate from "react-paginate";
import { BsShopWindow } from "react-icons/bs";
import { FaNairaSign } from "react-icons/fa6";
import { shortenText } from "../../../utils";
import { MdOutlineNavigateNext } from "react-icons/md";
import { GrFormPrevious } from "react-icons/gr";

const ListOfOrders = ({ openOrderDetails }) => {
  const { isLoading, orders } = useSelector((state) => state.order);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getOrders());
  }, [dispatch]);

  //Pagination
  const itemsPerPage = 15;
  const [itemOffSet, setitemOffSet] = useState(0);
  const endOffset = itemOffSet + itemsPerPage;
  const currentItems = orders.slice(itemOffSet, endOffset);
  const pageCount = Math.ceil(orders.length / itemsPerPage);

  const handlePageClick = (e) => {
    const newOffset = (e.selected * itemsPerPage) % orders.length;
    setitemOffSet(newOffset);
    //Paginate ends...
  };
  return (
    <>
      {isLoading && <Loader />}
      <div className={"table hideforscreen"}>
        {orders.length === 0 ? (
          <p className="--color-grey">No order found</p>
        ) : (
          <table>
            <thead>
              <tr>
                {/* <th>s/n</th> */}
                <th>Date</th>
                <th>Order ID</th>
                <th>Order Amount</th>
                <th>Order Status</th>
              </tr>
            </thead>
            <tbody>
              {/* {orders.map((order, index) => { */}
              {currentItems.map((order, index) => {
                const { _id, orderDate, orderTime, orderAmount, orderStatus } =
                  order;
                return (
                  <tr key={_id} onClick={() => openOrderDetails(_id)}>
                    {/* <td>{index + 1}</td> */}
                    <td>
                      {orderDate} at {orderTime}
                    </td>
                    <td>{_id}</td>
                    <td className="--items-center">
                      <FaNairaSign />
                      {Number(orderAmount).toLocaleString()}
                    </td>
                    <td>
                      <p
                        className={
                          orderStatus !== "Delivered"
                            ? `${"pending"}`
                            : `${"delivered"}`
                        }
                      >
                        {orderStatus}
                      </p>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </div>
      {/* Screen Design */}
      <div className="screen-wrapper">
        {currentItems.map((order) => {
          const { _id, orderDate, orderTime, orderAmount, orderStatus } = order;
          return (
            <>
              <div
                key={_id}
                className="screen-cont"
                onClick={() => openOrderDetails(_id)}
              >
                <BsShopWindow size={22} color="green" />

                <div className="--flex-col mobile-trans">
                  <span>{_id}</span>
                  <span>
                    {orderDate} at {orderTime}
                  </span>
                </div>
                <div className="--flex-col mobile-transac">
                  <span
                    style={{
                      display: "flex",
                      alignItems: "center",
                      fontWeight: "bold",
                    }}
                  >
                    {"-"}
                    <FaNairaSign size={12} />
                    {orderAmount.toLocaleString(2)}
                  </span>
                  <span
                    className={
                      orderStatus === "delivered" || orderStatus === "Delivered"
                        ? "green"
                        : "grey"
                    }
                  >
                    {shortenText(orderStatus, 7)}
                  </span>
                </div>
              </div>
            </>
          );
        })}
      </div>
      {/* Ends */}

      <ReactPaginate
        breakLabel="..."
        nextLabel={<MdOutlineNavigateNext size={20} />}
        onPageChange={handlePageClick}
        pageRangeDisplayed={3}
        pageCount={pageCount}
        previousLabel={<GrFormPrevious size={20} />}
        renderOnZeroPageCount={null}
        containerClassName="pagination"
        pageLinkClassName="page-num"
        previousLinkClassName="page-num"
        nextLinkClassName="page-num"
        activeLinkClassName="activePage"
      />
    </>
  );
};

export default ListOfOrders;
