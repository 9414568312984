import React, { useState } from "react";
import { MdLockReset, MdOutlineMailOutline } from "react-icons/md";
import "./Auth.scss";
import { Link, useNavigate } from "react-router-dom";
import bag from "../../assets/bag1-min.png";
import {
  RESET_AUTH,
  forgotPassword,
} from "../../redux/features/auth/authSlice";
import { validateEmail } from "../../redux/features/auth/authService";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../components/loader/Loader";
import Meta from "../../components/meta/Meta";

const Reset = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [email, setEmail] = useState("");
  const [mail, setMail] = useState(false);

  const { loading } = useSelector((state) => state.auth);
  const userData = {
    email,
  };

  const forgot = async (e) => {
    e.preventDefault();

    if (!email) {
      return toast.error("Please enter an email");
    }

    if (!validateEmail(email)) {
      return toast.error("Please enter a valid email");
    }

    await dispatch(forgotPassword(userData));
    await dispatch(RESET_AUTH(userData));
    setMail(true);
  };
  const resend = async () => {
    await dispatch(forgotPassword(userData));
    setMail(true);
  };

  const logoClick = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    navigate("/");
  };

  return (
    <>
      <Meta
        robots={"nofollow, noindex"}
        googlebot={"nofollow, noindex"}
        title={"Forgot Password"}
      />
      {loading && <Loader LoadingState={"Sending Link..."} />}
      <div className="form">
        <div className="form-wrapper">
          <div className="--flex-center">
            <MdLockReset size={35} color="#999" />
          </div>
          <h4>Reset Password</h4>
          <small>{mail && "Check your email for reset link!"}</small>
          <form onSubmit={forgot}>
            <div className="input-cont">
              <MdOutlineMailOutline size={26} color="#aaa" />
              <input
                type="email"
                placeholder="Type your email"
                id="email"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <span className="remember-section">
              <label onClick={() => navigate("/login")}>
                Login your Account
              </label>
              <span>
                <b className="--color-primary" onClick={resend}>
                  Resend link
                </b>
              </span>
            </span>

            <input type="submit" value="Get Reset Link" />
          </form>
          <span className="new-user">
            Return to &nbsp;
            <Link to="/" className="sign-up">
              HomePage
            </Link>
          </span>

          <div className="powered-by" onClick={logoClick}>
            <span>Powered by </span>
            <span>
              <img src={bag} alt="logo" width={100} />
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default Reset;
