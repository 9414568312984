import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CALCULATE_SUBTOTAL } from "../../../redux/features/cart/cartSlice";
import { useNavigate } from "react-router-dom";
import css from "./CheckoutSummary.module.scss";
import Card from "../../card/Card";
import { CartDiscount } from "../../verifyCoupon/VerifyCoupon";
import { FaNairaSign } from "react-icons/fa6";

const CheckoutSummary = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { coupon } = useSelector((state) => state.coupon);
  const { cartItems, cartTotalQuantity, cartTotalAmount } = useSelector(
    (state) => state.cart
  );

  useEffect(() => {
    dispatch(CALCULATE_SUBTOTAL({ coupon }));
  }, [dispatch, cartItems, coupon]);
  return (
    <div>
      <h3>Checkout Summary</h3>
      <div>
        {cartItems.length === 0 ? (
          <>
            <p>No item in cart.</p>
            <button className="--btn" onClick={() => navigate("/#products")}>
              &larr;Back to Shop
            </button>
          </>
        ) : (
          <div>
            <p>
              <b>{`Cart item(s): ${cartTotalQuantity}`}</b>
            </p>
            <div className={css.text}>
              <h4>Subtotal:</h4>
              <h3 className="--items-center --fw-m">
                <FaNairaSign />
                {cartTotalAmount.toLocaleString()}
              </h3>
            </div>

            <CartDiscount />
            {cartItems.map((item) => {
              const { _id, name, price, cartQuantity } = item;
              return (
                <Card key={_id} cardClass={css.card}>
                  <h4>Product: {name}</h4>
                  <p>Quantity: {cartQuantity}</p>
                  <p>Unit Price: {price}</p>
                  <p>Set Price: {price * cartQuantity}</p>
                </Card>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default CheckoutSummary;
