import "./InfoBox.scss";

const InfoBox = ({ bgColor, icon, title, count, navigateBox }) => {
  return (
    <div className={`info-box ${bgColor}`} onClick={navigateBox}>
      <span className="info-icon --color-white">{icon}</span>
      <span>
        <p>{title}</p>
        <h4>{count}</h4>
      </span>
    </div>
  );
};

export default InfoBox;
