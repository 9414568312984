import {
  Home,
  Login,
  Register,
  Reset,
  NotFound,
  Forgot,
  Verify,
  LoginWithCode,
  Profile,
  Admin,
  Product,
  Cart,
  SendMoney,
  FundWallet,
  VerifyEmail,
} from "./pages";
import {
  Header,
  Footer,
  HiddenPaths,
  AdminOnlyRoute,
  ProductDetails,
} from "./components";
import { Routes, Route, useLocation } from "react-router-dom";
import CheckoutDetails from "./pages/checkout/CheckoutDetails";
import CheckoutWithFlutterwave from "./pages/checkout/CheckoutWithFlutterwave";
import Checkout from "./pages/checkout/Checkout";
import CheckoutSuccess from "./pages/checkout/CheckoutSuccess";
import OrderHistory from "./pages/order/OrderHistory";
import OrderDetails from "./pages/order/OrderDetails";
import Wallet from "./pages/wallet/Wallet";
import Wishlist from "./pages/wishlist/Wishlist";
import TransactionDetails from "./pages/wallet/TransactionDetails";
import ReviewProducts from "./pages/reviewProduct/ReviewProduct";
import { useEffect } from "react";
import { ToastContainer } from "react-toastify";
import BottomNav from "./components/bottomNav/BottomNav";
import AboutUs from "./pages/aboutUs/AboutUs";
import PrivacyPolicy from "./pages/privacyPolicy/PrivacyPolicy";
import Tos from "./pages/tos/Tos";
import ContactUs from "./pages/contactUs/ContactUs";

// Scroll page when pathname is changed
export function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

const Router = () => {
  const location = useLocation();

  return (
    <>
      <ToastContainer />
      <ScrollToTop />
      <HiddenPaths>
        <Header />
      </HiddenPaths>
      <BottomNav />
      <Routes location={location} key={location.pathname}>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/forgot" element={<Forgot />} />
        <Route path="/resetPassword/:resetToken" element={<Reset />} />
        <Route path="/loginWithCode/:email" element={<LoginWithCode />} />
        <Route path="/verify/:verificationToken" element={<Verify />} />
        <Route path="/verifyEmail" element={<VerifyEmail />} />

        <Route path="/profile/*" element={<Profile />} />
        <Route path="/*" element={<NotFound />} />

        <Route
          path="/admin/*"
          element={
            <AdminOnlyRoute>
              <Admin />
            </AdminOnlyRoute>
          }
        />
        <Route path="/shop" element={<Product />} />
        <Route path="/men-products" element={<Product />} />
        <Route path="/women-products" element={<Product />} />
        <Route path="/special-products" element={<Product />} />

        <Route path="/product-details/:id" element={<ProductDetails />} />
        <Route path="/cart" element={<Cart />} />
        <Route path="/checkout-details" element={<CheckoutDetails />} />
        <Route
          path="/checkout-flutter-wave"
          element={<CheckoutWithFlutterwave />}
        />
        <Route path="/checkout-pay-stack" element={<Checkout />} />
        <Route path="/checkout-wallet" element={<Checkout />} />

        <Route path="/checkout-success" element={<CheckoutSuccess />} />
        <Route path="/order-history" element={<OrderHistory />} />
        <Route path="/order-details/:id" element={<OrderDetails />} />
        <Route path="/wallet" element={<Wallet />} />
        <Route path="/wishlist" element={<Wishlist />} />

        <Route path="/review-product/:id" element={<ReviewProducts />} />
        <Route path="/send-money" element={<SendMoney />} />
        <Route path="/fund-wallet" element={<FundWallet />} />
        <Route
          path="/transaction-details/:id"
          element={<TransactionDetails />}
        />

        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-of-service" element={<Tos />} />
      </Routes>
      <HiddenPaths>
        <Footer />
      </HiddenPaths>
    </>
  );
};
export default Router;
