import React, { useState } from "react";
import { AiOutlineCloudUpload } from "react-icons/ai";
import { FaTrash } from "react-icons/fa";
import { toast } from "react-toastify";

const upload_preset = process.env.REACT_APP_UPLOAD_PRESET;
const cloudinary_url = process.env.REACT_APP_CLOUDINARY_URL;

const UploadWidget = ({ files, setFiles }) => {
  const [selectedImages, setSelectedImages] = useState([]);
  const [images, setImages] = useState([]);
  const [progress, setProgress] = useState(0);
  const [uploading, setUploading] = useState(false);

  const addImages = (e) => {
    const selectedFiles = e.target.files;
    const selectedFilesArray = Array.from(selectedFiles);

    const imagesArray = selectedFilesArray.map((file) => {
      return URL.createObjectURL(file);
    });
    setImages((prevImages) => prevImages.concat(selectedFilesArray));
    setSelectedImages((prevImages) => prevImages.concat(imagesArray));

    e.target.value = "";
  };

  const removeImage = (image) => {
    const imageIndex = selectedImages.indexOf(image);
    setSelectedImages(selectedImages.filter((img) => img !== image));
    setImages(images.filter((img, index) => index !== imageIndex));
    URL.revokeObjectURL(image);
  };

  const uploadImages = () => {
    setUploading(true);
    let imageURLs = [];
    const formData = new FormData();
    for (let i = 0; i < images.length; i++) {
      let file = images[i];
      formData.append("file", file);
      formData.append("upload_preset", upload_preset);
      formData.append("folder", "shopwithM");

      fetch(cloudinary_url, {
        method: "POST",
        body: formData,
      })
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          imageURLs.push(data.secure_url);
          setProgress(imageURLs.length);

          if (imageURLs.length === images.length) {
            setFiles((prevFiles) => prevFiles.concat(imageURLs));
            setUploading(false);
            toast.success("Image Upload Complete");
            setImages([]);
            setSelectedImages([]);
            setProgress(0);
          }
        })
        .catch((error) => {
          setUploading(false);
          toast.error(error.message);
        });
    }
  };
  return (
    <div>
      <label className="uploadWidget">
        <AiOutlineCloudUpload size={35} />
        <br />
        <span>Click to upload up to 7 images</span>
        <input
          type="file"
          name=""
          onChange={addImages}
          multiple
          accept="image/png, image/jpeg, image/webp"
        />
      </label>
      <br />
      {selectedImages.length > 0 &&
        (selectedImages.length > 7 ? (
          <p className="error">
            You can only upload 7 images!
            <br />
            <span>
              Please remove <b>{selectedImages.length - 7}</b> of them
            </span>
          </p>
        ) : (
          <div className="--center-all">
            <button
              disabled={uploading}
              className="--btn --btn-primary"
              onClick={() => uploadImages()}
            >
              {uploading
                ? `Uploading ${progress} of ${images.length}`
                : `Upload ${images.length} Image(s)`}
            </button>
          </div>
        ))}
      {/* View Selected Images */}
      <div className={selectedImages.length > 0 ? "images" : ""}>
        {selectedImages !== 0 &&
          selectedImages.map((image, index) => {
            return (
              <div key={image} className="image">
                <img src={image} alt="productImage" width={200} />
                <button className="--btn" onClick={() => removeImage(image)}>
                  <FaTrash size={25} />
                </button>
                <p>{index + 1}</p>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default UploadWidget;
