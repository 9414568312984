import css from "./Search.module.scss";

const Search = ({ value, onChange, placeholder }) => {
  return (
    <search className={css.search}>
      <input
        type="search"
        placeholder={placeholder}
        value={value}
        onChange={onChange}
      />
    </search>
  );
};

export default Search;
