import React from 'react'
import CreateBrand from './CreateBrand'
import Brandlist from './Brandlist'
import "./Brand.scss"

const Brand = () => {

  return (
    <section>
    <div className="coupon">
        <CreateBrand />
        <Brandlist/> 
    </div>
</section>
  )
}

export default Brand