import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RESET_AUTH, verifyUser } from "../../redux/features/auth/authSlice";
import { useParams } from "react-router-dom";
import Loader from "../../components/loader/Loader";
import Meta from "../../components/meta/Meta";

const Verify = () => {
  const dispatch = useDispatch();
  const { verificationToken } = useParams();

  const { isLoading } = useSelector((state) => state.auth);

  const verifyAccount = async () => {
    await dispatch(verifyUser(verificationToken));
    await dispatch(RESET_AUTH());
  };
  return (
    <section>
      <Meta
        robots={"nofollow, noindex"}
        googlebot={"nofollow, noindex"}
        title={"Verify your account"}
      />
      {isLoading && <Loader />}
      <div className="--center-all">
        <h2>Account Verification</h2>
        <p className="--my">
          To verify your account, click the button below...
        </p>
        <br />
        <button className="--btn --btn-primary" onClick={verifyAccount}>
          Verify Account
        </button>
      </div>
    </section>
  );
};

export default Verify;
