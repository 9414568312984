import React from "react";
import { Helmet } from "react-helmet";
const Meta = ({
  title,
  canonical,
  descName,
  keywords,
  googlebot,
  robots,
  appleTouch,
  ogDesc,
  ogImage,
  ogSiteName,
  ogTitle,
  ogUrl,
}) => {
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>{title}</title>
      <link rel="canonical" href={canonical}></link>
      <meta name="description" content={descName} />
      <meta name="keywords" content={keywords} />
      <meta name="googlebot" content={googlebot} />
      <meta name="robots" content={robots} />
    </Helmet>
  );
};

export default Meta;
