import { useEffect, useState } from "react";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { FaNairaSign } from "react-icons/fa6";
import "./SendMoney.scss";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { selectUser } from "../../redux/features/auth/authSlice";
import { useNavigate } from "react-router-dom";
import Meta from "../../components/meta/Meta";

const initialDepositState = {
  amount: 100,
  paymentMethod: "",
};

const FundWallet = () => {
  const navigate = useNavigate();
  const user = useSelector(selectUser);
  const [depositData, setDepositData] = useState(initialDepositState);
  const { amount: depositAmount, paymentMethod } = depositData;

  // Deposit
  const handleDepositChange = (e) => {
    const { name, value } = e.target;
    setDepositData({ ...depositData, [name]: value });
  };
  const depositMoney = (e) => {
    e.preventDefault();
    if (depositAmount < 100) {
      toast.error("Minimum deposit is #100");
    }
    if (paymentMethod === "") {
      toast.error("Please select a payment method");
    }
    if (paymentMethod === "Flutterwave") {
      // eslint-disable-next-line no-undef
      FlutterwaveCheckout({
        public_key: process.env.REACT_APP_FLW_PK,
        tx_ref: process.env.REACT_APP_TX_REF,
        amount: depositAmount,
        currency: "NGN",
        payment_options: "card, banktransfer, ussd",
        redirect_url: `${process.env.REACT_APP_BACKEND_URL}/api/v0/transaction/depositFundFLW`,
        customer: {
          email: user?.email,
          phone_number: user?.phone,
          name: user?.name,
        },
        customizations: {
          title: "Shop With Meenah Wallet Deposit",
          description: " Shop With Meenah Wallet Deposit",
          logo: "https://checkout.flutterwave.com/assets/img/rave-logo.png",
        },
      });
    }
    if (paymentMethod === "Paystack") {
      toast.error("Pay");
    }
  };
  const closeModal = (e) => {
    if (e.target.classList.contains("cm")) {
      setDepositData({ ...initialDepositState });
      navigate("/wallet");
    }
  };
  useEffect(() => {
    if (!user) {
      navigate("/login");
    }
  });
  return (
    <>
      <Meta
        robots={"nofollow, noindex"}
        googlebot={"nofollow, noindex"}
        title={"Deposit"}
      />
      <section className="send-section">
        <div className="--flex-center send-wrapper">
          <div className="send-header">
            <span>
              <AiOutlineInfoCircle color="var(--mag)" size={18} />
              <h3>&ensp;Fund Wallet</h3>
            </span>

            <span>
              <h3 className="cm" onClick={closeModal}>
                &larr;&nbsp;Cancel
              </h3>
            </span>
          </div>

          <form className="send-form" onSubmit={depositMoney}>
            <label>Amount*</label>
            <div className="currency">
              <span>
                <FaNairaSign color="var(--grey)" />
              </span>
              <input
                type="number"
                name="amount"
                placeholder="0.00"
                required
                value={depositData?.amount}
                onChange={handleDepositChange}
                className="currency-input"
              />

              <div className="flag">
                <span className="flag-span"></span>
                <span className="flag-span"></span>
                <span className="flag-span"></span>
              </div>
              <small className="cur-name">NGN</small>
            </div>

            <div>
              <label htmlFor="flutterwave" className="fund-label">
                <input
                  type="radio"
                  name="paymentMethod"
                  value="Flutterwave"
                  id="flutterwave"
                  onChange={handleDepositChange}
                  className="radio-input"
                />
                <span className="custom-radio"></span>
                Flutter Wave
              </label>
            </div>
            {/* <div>
        <label htmlFor="flutterwave" className='radio-label'>
          <input 
             type="radio" 
             name="paymentMethod"  
             value="Flutterwave"
             id='flutterwave'
            //  onChange={handleDepositChange}
             className='radio-input'
             />
             <span className='custom-radio'></span>
             Paystack
          </label>
        </div> */}

            <div>
              <button type="submit" className="send-btn">
                Add Money
              </button>
            </div>
          </form>
        </div>
      </section>
    </>
  );
};

export default FundWallet;
