import React from "react";
import { useSelector } from "react-redux";
import { selectUser } from "../../redux/features/auth/authSlice";
import { Link } from "react-router-dom";
import nf from "../../assets/m-404.png";

const AdminOnlyRoute = ({ children }) => {
  const user = useSelector(selectUser);
  const userRole = user?.role;

  if (
    userRole === process.env.REACT_APP_CHECK_TU ||
    userRole === process.env.REACT_APP_CHECK_MU
  ) {
    return children;
  }
  return (
    <div className="notfound">
      <span className="nf-img-cont">
        <img src={nf} alt="notfound" />
      </span>

      <span className="nf-text-cont">
        <h3>404 Page Not Found</h3>
        <p>
          This page does'nt exist, but the{" "}
          <Link to="/" className="--inline-link">
            home page
          </Link>{" "}
          does
        </p>
        <p>We also have much more shopping items for you to browse!</p>
        <span className="global-links">
          <Link to="/women-products">Women</Link>
          <Link to="/men-products">Men</Link>
          <Link to="/special-products">Special</Link>
        </span>
      </span>
    </div>
  );
};

export const AdminOnlyLink = ({ children }) => {
  const user = useSelector(selectUser);
  const userRole = user?.role;
  if (
    userRole === process.env.REACT_APP_CHECK_TU ||
    userRole === process.env.REACT_APP_CHECK_MU
  ) {
    return children;
  }
  return null;
};

export default AdminOnlyRoute;
