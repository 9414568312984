import ReactDOM from "react-dom";
import loaderImg from "../../assets/loader.GIF";
import style from "./Loader.module.scss";

const Loader = ({ LoadingState }) => {
  return ReactDOM.createPortal(
    <div className={style["loader-wrapper"]}>
      <div className={style.loader}>
        <img src={loaderImg} alt="Loading..." className={style.loaderImg} />
        <span className={style["loader-text"]}>{LoadingState}</span>
      </div>
    </div>,
    document.getElementById("loader")
  );
};

export const Spinner = () => {
  return (
    <div className="--center-all">
      <img src={loaderImg} alt="Loading..." className={style.loaderImg} />
    </div>
  );
};

export default Loader;
