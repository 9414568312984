import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import enqService from "./enqService";
import { toast } from "react-toastify";

const initialState = {
  loading: false,
  isSuccess: false,
  isError: false,
  message: "",
};

//Register Mail
export const sendToTg = createAsyncThunk(
  "enq/enqService",
  async (formData, thunkAPI) => {
    try {
      return await enqService.sendToTg(formData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

const enqSlice = createSlice({
  name: "enq",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // send
      .addCase(sendToTg.pending, (state) => {
        state.loading = true;
      })
      .addCase(sendToTg.fulfilled, (state, action) => {
        state.loading = false;
        state.isSuccess = true;
        state.isError = false;
        toast.success(action.payload);
      })
      .addCase(sendToTg.rejected, (state, action) => {
        state.loading = false;
        state.isError = true;
        state.message = action.payload;
        toast.error(action.payload);
      });
  },
});

export default enqSlice.reducer;
