import React, { useEffect } from "react";
import "./Dashboard.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  getOrders,
  selectOrders,
} from "../../../redux/features/order/orderSlice";
import {
  getUserTransactions,
  selectTransactions,
} from "../../../redux/features/transaction/transactionSlice";
import { Link } from "react-router-dom";
import { GoDotFill } from "react-icons/go";
import { FaArrowUpRightDots, FaArrowsTurnToDots } from "react-icons/fa6";

const Dashboard = () => {
  const order = useSelector(selectOrders);
  const dispatch = useDispatch();
  const transaction = useSelector(selectTransactions);

  useEffect(() => {
    dispatch(getUserTransactions());
    dispatch(getOrders());
  }, [dispatch]);

  const date = new Date();
  const year = date.getFullYear();
  return (
    <div className="p-dashboard">
      <h3 className="--fw-m">Dashboard Overview</h3>
      <div className="dash-top-flex">
        <div className="dash-two-item">
          <div className="dash-order-stat --card">
            <div className="--flex-between">
              <h3 className="--fw-m">Orders Count</h3>
              <Link to={"/order-history"}> View Report</Link>
            </div>
            <small className="--fw-m">All orders for {year}</small>
            <div className="p-dash-count">
              <span className="count-circle">
                <FaArrowUpRightDots size={30} />
              </span>
              <h3>{order.length}</h3>
            </div>
            <div className="bottom-flex --flex">
              <span>
                <GoDotFill color="green" />
                order
              </span>
              <span>
                <GoDotFill color="var(--m)" />
                purchase
              </span>
            </div>
          </div>
          <div className="dash-order-stat --card">
            <div className="--flex-between">
              <h3 className="--fw-m">Transactions Count</h3>
              <Link to={"/wallet"}> View Report</Link>
            </div>
            <small className="--fw-m">Your transactions for {year}</small>
            <div className="p-dash-count">
              <span className="count-circle">
                <FaArrowsTurnToDots size={30} />
              </span>
              <h3>{transaction.length}</h3>
            </div>
            <div className="bottom-flex --flex">
              <span>
                <GoDotFill color="green" />
                income
              </span>
              <span>
                <GoDotFill color="var(--m)" />
                expenses
              </span>
            </div>
          </div>
        </div>
        <div className="dash-transact --card">
          <h3 className="--fw-m">All History</h3>
          <div className="dash-order-tab">
            <Link to={"/order-history"} className="--btn dash-btn">
              Manage Orders
            </Link>
            <Link to={"/wallet"} className="--btn dash-btn">
              Manage Transactions
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
