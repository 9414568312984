import { useState } from "react";
import Card from "../../card/Card";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Loader from "../../loader/Loader";

import { createSize, getSizes } from "../../../redux/features/size/sizeSlice";

const CreateSize = () => {
  const { loading } = useSelector((state) => state.size);
  const dispatch = useDispatch();

  const [name, setName] = useState("");

  const saveSize = async (e) => {
    e.preventDefault();
    if (name.length < 1) {
      return toast.error("Size name must be up to 3 characters");
    }

    const sizeData = {
      name,
    };
    await dispatch(createSize(sizeData));
    await dispatch(getSizes());
    setName("");
  };

  return (
    <>
      {loading && <Loader />}
      <div className="--mb2 create-category">
        <h3>Create Sizes</h3>
        <small>
          Use the form to{" "}
          <span className="--color-primary">Create a Color</span>
        </small>
        <Card cardClass={"card"}>
          <br />
          <form onSubmit={saveSize}>
            <label htmlFor="">Size Name:</label>
            <input
              type="text"
              name="name"
              value={name}
              placeholder="Size Name"
              onChange={(e) => setName(e.target.value)}
              required
            />
            <input
              type="submit"
              value="Create Size"
              className="--btn --btn-primary"
            />
          </form>
        </Card>
      </div>
    </>
  );
};

export default CreateSize;
