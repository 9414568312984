import React, { useEffect } from "react";
import styles from "../../components/checkout/checkoutForm/CheckoutForm.module.scss";
import Card from "../../components/card/Card";
import CheckoutSummary from "../../components/checkout/checkoutSummary/CheckoutSummary";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  CALCULATE_SUBTOTAL,
  selectCartItems,
  selectCartTotalAmount,
} from "../../redux/features/cart/cartSlice";
import { selectUser } from "../../redux/features/auth/authSlice";
import { toast } from "react-toastify";
import {
  SAVE_C,
  selectC,
  selectPaymentMethod,
  selectShippingAddress,
} from "../../redux/features/checkout/CheckoutSlice";
import { createOrder } from "../../redux/features/order/orderSlice";
import Confetti from "react-confetti";
import Meta from "../../components/meta/Meta";

const CheckoutWithFlutterwave = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector(selectUser);

  const cartTotalAmount = useSelector(selectCartTotalAmount);
  const cartItems = useSelector(selectCartItems);
  const shippingAddress = useSelector(selectShippingAddress);
  const paymentMethod = useSelector(selectPaymentMethod);

  const [urlParams] = useSearchParams();
  const payment = urlParams.get("payment");
  const ref = urlParams.get("ref");
  const { coupon } = useSelector((state) => state.coupon);
  const c = useSelector(selectC);

  useEffect(() => {
    dispatch(CALCULATE_SUBTOTAL({ coupon: coupon }));
  }, [cartItems, dispatch, coupon]);

  // Save order to Order History
  const saveOrder = async () => {
    const today = new Date();
    const formData = {
      orderDate: today.toDateString(),
      orderTime: today.toLocaleTimeString(),
      orderAmount: cartTotalAmount,
      orderStatus: "Order Placed...",
      cartItems,
      shippingAddress,
      paymentMethod,
      coupon: c !== null ? c : { name: "nil", discount: 0 },
    };
    dispatch(createOrder(formData));
  };

  useEffect(() => {
    if (
      payment === "successful" &&
      ref === process.env.REACT_APP_TX_REF &&
      cartTotalAmount > 0
    ) {
      const red = saveOrder();
      if (red) {
        dispatch(SAVE_C({ name: "nil", discount: 0 }));
      }
    }
    if (payment === "failed") {
      toast.error("Payment Failed, please try again later");
      dispatch(SAVE_C({ name: "nil", discount: 0 }));
      navigate("/cart");
    }
    if (payment === "successful" && ref === process.env.REACT_APP_TX_REF) {
      navigate("/checkout-success");
    }
  }, [payment, cartTotalAmount, navigate, ref, dispatch]);

  const makePayment = async () => {
    // eslint-disable-next-line no-undef
    FlutterwaveCheckout({
      public_key: process.env.REACT_APP_FLW_PK,
      tx_ref: process.env.REACT_APP_TX_REF,
      amount: cartTotalAmount,
      currency: "NGN",
      payment_options: "banktransfer,card, ussd",
      redirect_url: `${process.env.REACT_APP_BACKEND_URL}/api/v0/order/response`,
      customer: {
        email: user.email,
        phone_number: user.phone,
        name: user.fname + user.lname,
      },
      customizations: {
        title: "Shop With Meenah",
        description: "Payment for products",
        logo: "https://checkout.flutterwave.com/assets/img/rave-logo.png",
      },
      onclose: function (incomplete) {
        if (incomplete || window.verified === false) {
          dispatch(SAVE_C({ name: "nil", discount: 0 }));
        }
      },
    });
    if (coupon) {
      dispatch(SAVE_C(coupon));
    }
  };

  return (
    <>
      <Meta
        robots={"nofollow, noindex"}
        googlebot={"nofollow, noindex"}
        title={"Checkout Flutterwave"}
      />
      {payment === "successful" && <Confetti />}
      <section>
        <div className={`container ${styles.checkout}`}>
          <h2>Checkout</h2>
          <div>
            <Card cardClass={styles.card}>
              <CheckoutSummary />
            </Card>
          </div>
          <div>
            <Card cardClass={`${styles.card} ${styles.pay}`}>
              <h3>Checkout with Flutterwave</h3>
              <button
                type="button"
                className={styles.button}
                onClick={makePayment}
              >
                Pay Now!
              </button>
            </Card>
          </div>
        </div>
      </section>
    </>
  );
};

export default CheckoutWithFlutterwave;
