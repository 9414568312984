import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import newsService from "./newsService";
import { toast } from "react-toastify";

const initialState = {
  email: "",
  loading: false,
  isSuccess: false,
  isError: false,
  message: "",
};

//Register Mail
export const regMail = createAsyncThunk(
  "news/newsService",
  async (formData, thunkAPI) => {
    try {
      return await newsService.regMail(formData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);
//Get Products
export const getMails = createAsyncThunk(
  "news/getMails",
  async (_, thunkAPI) => {
    try {
      return await newsService.getMails();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);
const newsSlice = createSlice({
  name: "news",
  initialState,
  reducers: {
    RESET_NEWS(state) {
      state.email = "";
      state.isError = false;
      state.isSuccess = false;
      state.loading = false;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      // Create Product
      .addCase(regMail.pending, (state) => {
        state.loading = true;
      })
      .addCase(regMail.fulfilled, (state, action) => {
        state.loading = false;
        state.isSuccess = true;
        state.isError = false;
        toast.success("Subscription Successful");
      })
      .addCase(regMail.rejected, (state, action) => {
        state.loading = false;
        state.isError = true;
        state.message = action.payload;
        toast.error("Email Already In Use");
      });
  },
});

export const {} = newsSlice.actions;

export default newsSlice.reducer;
