import React from 'react'
import CreateCoupon from './CreateCoupon'
import CouponList from './CouponList'

const Coupon = () => {
  return (
    <section>
    <div className="coupon">
        <CreateCoupon />
        <CouponList/> 
    </div>
</section>
  )
}

export default Coupon