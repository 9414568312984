import { FaFire, FaUserAlt } from "react-icons/fa";
import { SlWallet } from "react-icons/sl";
import { GiSelfLove } from "react-icons/gi";
import { FcHome } from "react-icons/fc";

export const nlinks = [
  {
    name: "Home",
    path: "/",
    fa: <FcHome size={18} />,
  },
  {
    name: "Shop",
    path: "/shop",
    fa: <FaFire size={18} />,
  },

  {
    name: "Wallet",
    path: "/wallet",
    fa: <SlWallet size={18} />,
  },
  {
    name: "Wishlist",
    path: "/wishlist",
    fa: <GiSelfLove size={18} />,
  },
  {
    name: "Profile",
    path: "/profile/dashboard",
    fa: <FaUserAlt size={18} />,
  },
];
