import axios from "axios";

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const API_URL = `${BACKEND_URL}/api/v0/enq/`;

// Create New Order
const sendToTg = async (formData) => {
  const response = await axios.post(API_URL + "send-to-tg", formData);
  return response.data.message;
};

const enqService = {
  sendToTg,
};

export default enqService;
