const width = window.innerWidth <= 600;
export const sliderData = [
  {
    image: "https://i.ibb.co/rQMYksx/IMG-5052-min.jpg",
    category: "Fashion",
    heading: "Women Fashion Bags",
    desc: "High quality women's luxury Bag",
    linkName: "Shop Now!",
    path: `${!width ? "/women-products" : "/shop"}`,
  },
  {
    image: "https://i.ibb.co/RjqhLgq/953fe93b-4f3f-48a5-8506-3ac1e53f69d1.jpg",
    category: "Pajamas",
    heading: "Hot Night Wears",
    desc: "Sexy Night wears for both gender",
    linkName: "Shop Now!",
    path: "/shop",
  },
  {
    image: "https://i.ibb.co/dgrHYs8/IMG-5619.jpg",
    category: "Bikini",
    heading: "Hot Beach Wears",
    desc: "Stunning bikini for ladies",
    linkName: "Shop Now!",
    path: `${!width ? "/women-products" : "/shop"}`,
  },
  {
    image: "https://i.ibb.co/fpm32V8/bag-bg-min.jpg",
    category: "Special",
    heading: "Men & Women",
    desc: "Special products for all gender",
    linkName: "Shop Now!",
    path: `${!width ? "/special-products" : "/shop"}`,
  },
  {
    image: "https://i.ibb.co/zSF0XR7/IMG-2258-min.jpg",
    category: "Fashion",
    heading: "Men's Short",
    desc: "Beach or playground short knickers",
    linkName: "Shop Now!",
    path: `${!width ? "/men-products" : "/shop"}`,
  },
  {
    image: "https://i.ibb.co/SKD7K48/IMG-1883-min.jpg",
    category: "Heels",
    heading: "Stunning Heels",
    desc: "Trending Women Heels",
    linkName: "Shop Now!",
    path: `${!width ? "/women-products" : "/shop"}`,
  },
];
