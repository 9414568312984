import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  getProduct,
  selectProduct,
  selectLoading,
  updateProduct,
} from "../../../../redux/features/product/productSlice";
import {} from "../../../../redux/features/categoryAndBrand/CategoryAndBrandSlice";
import { toast } from "react-toastify";
import Loader from "../../../loader/Loader";
import ProductForm from "../productForm/ProductForm";
import { getColors } from "../../../../redux/features/color/colorSlice";
import { getSizes } from "../../../../redux/features/size/sizeSlice";

const EditProduct = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loading = useSelector(selectLoading);

  const productEdit = useSelector(selectProduct);
  const [product, setProduct] = useState(productEdit);

  const [description, setDescription] = useState("");
  const [files, setFiles] = useState([]);
  const [color, setColor] = useState([]);
  const [size, setSize] = useState([]);

  const { colors } = useSelector((state) => state.color);
  const { sizes } = useSelector((state) => state.size);

  let colorss = [];
  colors.forEach((col) => {
    colorss.push({
      _id: col._id,
      color: col.name,
      hexCode: col.hexCode,
      colorQty: col.colorQty,
    });
  });

  let sizess = [];
  sizes.forEach((item) => {
    sizess.push({
      _id: item._id,
      size: item.name,
    });
  });

  useEffect(() => {
    dispatch(getProduct(id));
    dispatch(getColors());
    dispatch(getSizes());
  }, [dispatch, id]);

  useEffect(() => {
    setProduct(productEdit);
    if (productEdit && productEdit.image) {
      setFiles(productEdit.image);
    }
    setDescription(
      productEdit && productEdit.description ? productEdit.description : ""
    );

    if (productEdit && productEdit.color) {
      setColor(productEdit.color);
    }
    if (productEdit && productEdit.size) {
      setSize(productEdit.size);
    }
  }, [productEdit]);

  const saveProduct = async (e) => {
    e.preventDefault();

    if (files.length < 1) {
      return toast.error("Please add an image");
    }

    const formData = {
      name: product?.name,
      mainCategory: product?.mainCategory,
      category: product?.category,
      brand: product?.brand,
      quantity: Number(product?.quantity),
      regularPrice: product.regularPrice,
      price: product?.price,
      description,
      image: files,
      color,
      size,
    };

    await dispatch(updateProduct({ id, formData }));
    navigate("/admin/all-products");
  };

  return (
    <section>
      <div className="container">
        {loading && <Loader />}
        <h3>Edit Product</h3>
        <ProductForm
          files={files}
          setFiles={setFiles}
          product={product}
          setProduct={setProduct}
          saveProduct={saveProduct}
          description={description}
          setDescription={setDescription}
          isEditing={true}
          color={color}
          setColor={setColor}
          colorss={colorss}
          size={size}
          setSize={setSize}
          sizess={sizess}
        />
      </div>
    </section>
  );
};

export default EditProduct;
