import axios from "axios";

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
export const API_URL = `${BACKEND_URL}/api/v0/transaction/`;

// Get User Transaction
const getUserTransactions = async () => {
  const response = await axios.get(API_URL + "getUserTransactions");
  return response.data;
};
// Verify Account
const verifyAccount = async (formData) => {
  const response = await axios.post(API_URL + "verifyAccount", formData);
  return response.data;
};

// Transfrer Fund
const transferFund = async (formData) => {
  const response = await axios.post(API_URL + "transferFund", formData);
  return response.data;
};

// Get a Transaction
const getUserSingleTransaction = async (id) => {
  const response = await axios.get(API_URL + `getUserTransaction/` + id);
  return response.data;
};

const transactionService = {
  getUserTransactions,
  verifyAccount,
  transferFund,
  getUserSingleTransaction,
};
export default transactionService;
