import axios from "axios";

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const API_URL = `${BACKEND_URL}/api/v0/size/`;

const createSize = async (sizeData) => {
  const response = await axios.post(API_URL + "createSize", sizeData);
  return response.data;
};

const getSizes = async () => {
  const response = await axios.get(API_URL + "getSizes");
  return response.data;
};

const deleteSize = async (id) => {
  const response = await axios.delete(API_URL + "deleteSize/" + id);
  return response.data.messsage;
};

const SizeService = {
  createSize,
  getSizes,
  deleteSize,
};

export default SizeService;
