import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getProducts } from "../../redux/features/product/productSlice";
import scss from "./Product.module.scss";
import { ProductFilter, ProductList } from "../../components";
import { Spinner } from "../../components/loader/Loader";
import Meta from "../../components/meta/Meta";

const Product = () => {
  const [showFilter, setShowFilter] = useState(true);
  const { loading, products } = useSelector((state) => state.product);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getProducts());
  }, [dispatch]);

  const toggleFilter = () => {
    setShowFilter(!showFilter);
  };
  return (
    <>
      <Meta
        title={"Shop - Shop With Meenah"}
        canonical={"https://shopwithmeenah.com/shop"}
        descName={"Shop luxury bags and many more..."}
        googlebot={"follow, index"}
        keywords={
          "shopwithmeenah, shop with meenah, Shop with meenah, Shopwithmeenah"
        }
      />

      <div className={scss.product}>
        <div className={showFilter ? `${scss.filterr}` : ``}>
          {loading
            ? null
            : showFilter && (
                <div>
                  <ProductFilter />
                  <div
                    className={scss.closemodl}
                    onClick={() => setShowFilter(false)}
                  ></div>
                </div>
              )}
        </div>
        <div
          className={
            !showFilter
              ? `${scss.pcontent} ${scss["f-width"]}`
              : `${scss.pcontent} ${scss["fit-width"]}`
          }
        >
          {loading ? (
            <Spinner />
          ) : (
            <ProductList products={products} toggleFilter={toggleFilter} />
          )}
        </div>
      </div>
    </>
  );
};

export default Product;
