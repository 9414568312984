import "./BottomNav.css";
import { NavLink, useLocation } from "react-router-dom";
import { nlinks } from "./data";
import React from "react";

const Sidebar = () => {
  const location = useLocation();
  const pages = [
    "/",
    "/login",
    "/register",
    "/reset",
    "/forgot",
    "/verifyEmail",
    "/privacy-policy",
    "/terms-of-service",
    "/about-us",
  ].includes(location.pathname);
  return (
    // Not Aside anymore just reveal at the bottom in media queries
    // <div className='aside' >
    <div className={`${pages ? "--display-none" : "aside"}`}>
      <ul>
        {React.Children.toArray(
          nlinks.map(({ name, path, fa }, index) => {
            return (
              <li key={index}>
                <NavLink className="b-links" to={path}>
                  {fa}
                  {name}
                </NavLink>
              </li>
            );
          })
        )}
      </ul>
    </div>
  );
};

export default Sidebar;
