import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./features/auth/authSlice";
import userFilterReducer from "./features/auth/filterUserSlice";
import categoryReducer from "./features/categoryAndBrand/CategoryAndBrandSlice";
import productReducer from "./features/product/productSlice";
import filterReducer from "./features/product/filterSlice";
import couponReducer from "./features/coupon/couponSlice";
import cartReducer from "./features/cart/cartSlice";
import checkoutReducer from "./features/checkout/CheckoutSlice";
import orderReducer from "./features/order/orderSlice";
import transactionReducer from "./features/transaction/transactionSlice";
import newsReducer from "./features/newsletter/newsSlice";
import colorReducer from "./features/color/colorSlice";
import sizeReducer from "./features/size/sizeSlice";
import enqReducer from "./features/enq/enqSlice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    category: categoryReducer,
    product: productReducer,
    coupon: couponReducer,
    filter: filterReducer,
    cart: cartReducer,
    checkout: checkoutReducer,
    order: orderReducer,
    transaction: transactionReducer,
    news: newsReducer,
    color: colorReducer,
    size: sizeReducer,
    enq: enqReducer,
    filterUser: userFilterReducer,
  },
});
