import { AdminUserStats } from "../..";
import Chart from "../chart/Chart";
import ProductChart from "../productsChart/ProductChart";
import UserChart from "../userChart/UserChart";
import "./Admin.css";

const AdminHome = () => {
  return (
    <div>
      <h3>Admin Statistics</h3>
      <AdminUserStats />
      <div className="--flex change-dir">
        <Chart />
        <UserChart />
      </div>
      <div className="--my ">
        <ProductChart />
      </div>
    </div>
  );
};

export default AdminHome;
