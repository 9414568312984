import "./App.css";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getLoginStatus,
  getUser,
  RESET_AUTH,
  selectIsLoggedIn,
  sendVerificationEmail,
} from "./redux/features/auth/authSlice";

import { FILTER_BY_MAIN_CATEGORY } from "./redux/features/product/filterSlice";
import Router from "./Router";
import Alert from "@mui/material/Alert";
import Loader from "./components/loader/Loader";
import { SAVE_C } from "./redux/features/checkout/CheckoutSlice";
import { useLocation } from "react-router-dom";

axios.defaults.withCredentials = true;

function App() {
  const dispatch = useDispatch();
  const location = useLocation();
  const isLoggedIn = useSelector(selectIsLoggedIn);

  const { user, loading } = useSelector((state) => state.auth);
  const { products } = useSelector((state) => state.product);

  useEffect(() => {
    dispatch(getLoginStatus());
    if (isLoggedIn && user === null) {
      dispatch(getUser());
    }

    if (loading !== false && isLoggedIn === false) {
      dispatch(SAVE_C({ name: "nil", discount: 0 }));
    }
  }, [dispatch, isLoggedIn]);

  useEffect(() => {
    if (window.location.pathname === "/men-products") {
      dispatch(
        FILTER_BY_MAIN_CATEGORY({ products: products, mainCategory: "Men" })
      );
    }
    if (window.location.pathname === "/women-products") {
      dispatch(
        FILTER_BY_MAIN_CATEGORY({ products: products, mainCategory: "Women" })
      );
    }
    if (window.location.pathname === "/special-products") {
      dispatch(
        FILTER_BY_MAIN_CATEGORY({ products: products, mainCategory: "Special" })
      );
    }
  }, [dispatch, products]);

  const sendVerEmail = async () => {
    await dispatch(sendVerificationEmail());
    await dispatch(RESET_AUTH());
  };
  useEffect(() => {
    console.clear();
  }, []);
  return (
    <>
      <>
        {loading && <Loader LoadingState={"Loading"} />}

        {isLoggedIn &&
          !user?.verified &&
          location.pathname !== "/register" &&
          location.pathname !== "/login" &&
          location.pathname !== "/forgot" && (
            <Alert severity="error">
              To verify your account, please check your email for a verification
              link.
              <small
                onClick={sendVerEmail}
                style={{
                  fontWeight: "bold",
                  textDecoration: "underline",
                  marginLeft: "3px",
                }}
              >
                Resend Link
              </small>
            </Alert>
          )}
      </>
      {/* {location.pathname !== "/register" &&
        location.pathname !== "/login" &&
        location.pathname !== "/forgot" && (
          <Alert severity="info">
            Get 10% off on all products with this sale coupon: OPNJUL
          </Alert>
        )} */}
      <Router />
    </>
  );
}

export default App;
