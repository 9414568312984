import React, { useEffect, useState } from "react";
import { GiPadlock } from "react-icons/gi";
import { FaKey } from "react-icons/fa";
import "./Auth.scss";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import bag from "../../assets/bag1-min.png";
import {
  RESET_AUTH,
  loginWithCode,
  sendLoginCode,
} from "../../redux/features/auth/authSlice";
import { getCartDB, saveCartDB } from "../../redux/features/cart/cartSlice";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../components/loader/Loader";
import Meta from "../../components/meta/Meta";
import secureLocalStorage from "react-secure-storage";

const LoginWithCode = () => {
  const [loginCode, setLoginCode] = useState("");
  const [sendCode, setSendCode] = useState(false);

  const { email } = useParams();

  const [urlParams] = useSearchParams();
  const redirect = urlParams.get("redirect");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { loading, isLoggedIn, isSuccess } = useSelector((state) => state.auth);

  const sendUserLoginCode = async () => {
    const resendCode = await dispatch(sendLoginCode(email));
    if (resendCode) {
      setSendCode(true);
    }
    await dispatch(RESET_AUTH());
  };

  const submitLoginCode = async (e) => {
    e.preventDefault();
    if (loginCode === "") {
      return toast.error("Please fill in the login code");
    }
    if (loginCode.length !== 6) {
      return toast.error("Invalid or expired login code ");
    }
    const code = {
      loginCode,
    };
    const codeSent = dispatch(loginWithCode({ code, email }));
    if (codeSent) {
      setSendCode(true);
    }
  };

  useEffect(() => {
    if (isSuccess && isLoggedIn) {
      if (redirect === "cart") {
        dispatch(
          saveCartDB({
            cartItems: JSON.parse(secureLocalStorage.getItem("cartItems")),
          })
        );
        navigate("/cart");
      }
      dispatch(getCartDB());
    }
    dispatch(RESET_AUTH());
  }, [isSuccess, isLoggedIn, dispatch, navigate, redirect]);

  const logoClick = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    navigate("/");
  };

  return (
    <>
      <Meta
        robots={"nofollow, noindex"}
        googlebot={"nofollow, noindex"}
        title={"Login Verification"}
      />
      {loading && <Loader LoadingState={"Loading..."} />}
      <div className="form">
        <div className="form-wrapper">
          <FaKey size={22} color="#aaa" />
          <h4> Let's Confirm it's you</h4>
          {sendCode && <small>Check your email for a 6 code</small>}
          <form onSubmit={submitLoginCode} autoComplete="off">
            <div className="input-cont">
              <GiPadlock size={22} color="#aaa" />
              <input
                type="number"
                placeholder="Enter Access Code"
                name="loginCode"
                required
                value={loginCode}
                onChange={(e) => setLoginCode(e.target.value)}
              />
            </div>
            <span className="remember-section">
              <span onClick={sendUserLoginCode}>
                <b className="--color-primary">Resend code</b>
              </span>
              <span onClick={() => navigate("/register")}>
                Create new Account
              </span>
            </span>
            <input type="submit" value="Proceed to login" />
          </form>
          <span className="new-user">
            Return to &nbsp;
            <Link to="/login" className="sign-up">
              login page
            </Link>
          </span>

          <div className="powered-by" onClick={logoClick}>
            <span>Powered by </span>
            <span>
              <img src={bag} alt="logo" width={100} />
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginWithCode;
