import { useEffect, useState } from "react";
import { MdOutlineMailOutline } from "react-icons/md";
import { FaUser, FaUserClock } from "react-icons/fa6";
import { FaTimes } from "react-icons/fa";
import { BsCheck2All } from "react-icons/bs";
import "./Auth.scss";
import { Link, useNavigate } from "react-router-dom";
import bag from "../../assets/bag1-min.png";
import PasswordInput from "../../components/passwordInput/PasswordInput";
import { toast } from "react-toastify";
import {
  validateEmail,
  validatePhone,
} from "../../redux/features/auth/authService";
import { useDispatch, useSelector } from "react-redux";
import {
  RESET_AUTH,
  register,
  sendVerificationEmail,
} from "../../redux/features/auth/authSlice";
import Loader from "../../components/loader/Loader";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { sendAutomatedEmail } from "../../redux/features/email/emailSlice";
import Meta from "../../components/meta/Meta";

const initialState = {
  fname: "",
  lname: "",
  email: "",
  password: "",
  cpassword: "",
};

export const validatePasswordCase = (password) => {
  return password.match(/([a-z].*[A-Z])|([A-Z].*[a-z])/);
};

export const validatePasswordNum = (password) => {
  return password.match(/([0-9])/);
};

export const validatePasswordSChar = (password) => {
  return password.match(/([!,%,&,@,#,$,^,*,?,_,~])/);
};

const Register = () => {
  const [inputFocus, setInputFocus] = useState(false);
  const [formData, setFormData] = useState(initialState);

  const { fname, lname, email, password, cpassword } = formData;
  const [phone, setPhone] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { loading, isLoggedIn, isSuccess } = useSelector((state) => state.auth);

  const [uCase, setUCase] = useState(false);
  const [num, setNum] = useState(false);
  const [sChar, setSChar] = useState(false);
  const [passLength, setPassLength] = useState(false);

  const timesIcon = <FaTimes color="var(--mag)" size={15} />;
  const checkIcon = <BsCheck2All color="green" size={15} />;

  const swithIcon = (condition) => {
    if (condition) {
      return checkIcon;
    } else {
      return timesIcon;
    }
  };

  const handleFocus = (condition) => {
    if (condition) {
      setInputFocus(true);
    } else {
      setInputFocus(false);
    }
  };

  const handleBlur = (condition) => {
    if (condition) {
      setInputFocus(false);
    } else {
      setInputFocus(true);
    }
  };

  const handlePaste = (e) => {
    e.preventDefault();
    // toast.error("Sorry you cannot paste into the input field");
    return false;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  useEffect(() => {
    if (validatePasswordCase(password) || validatePasswordCase(cpassword)) {
      setUCase(true);
    } else {
      setUCase(false);
    }
    //Check for numbers
    if (validatePasswordNum(password) || validatePasswordNum(cpassword)) {
      setNum(true);
    } else {
      setNum(false);
    }
    //Check for special character
    if (validatePasswordSChar(password) || validatePasswordSChar(cpassword)) {
      setSChar(true);
    } else {
      setSChar(false);
    }
    //Check for pass length
    if (password.length > 5 || cpassword.length > 5) {
      setPassLength(true);
    } else {
      setPassLength(false);
    }
  }, [password, cpassword]);

  const submitSignUp = async (e) => {
    e.preventDefault();
    if (!fname || !lname || !email || !phone || !password || !cpassword) {
      return toast.error("All fileds are required");
    }
    if (!validateEmail(email)) {
      return toast.error("Please enter a valid email");
    }
    if (!validatePhone(phone)) {
      return toast.error("Please enter a valid phone number");
    }
    if (
      fname.length < 3 ||
      lname.length < 3 ||
      email.length < 6 ||
      phone.length < 14 ||
      phone.length > 15
    ) {
      return toast.error("Invalid Data or unsupported region");
    }
    if (password.length < 6 || cpassword.length < 6) {
      return toast.error("Password must be up to 6 characters");
    }

    if (password !== cpassword) {
      return toast.error("Password do not match");
    }
    if (
      !validatePasswordCase(password) ||
      !validatePasswordNum(password) ||
      !validatePasswordSChar(password) ||
      !validatePasswordCase(cpassword) ||
      !validatePasswordNum(cpassword) ||
      !validatePasswordSChar(cpassword)
    ) {
      return toast.error(
        "Password must contain alpha numeric and special characters"
      );
    }

    const userData = {
      fname,
      lname,
      email,
      phone: Number(phone),
      password,
    };
    const emailData = {
      subject: `Welcome ${fname} - Shop With Meenah`,
      send_to: email,
      reply_to: "no-reply@shopwithmeenah.com",
      template: "welcome",
      url: "/shop",
    };
    const isRegistered = await dispatch(register(userData));
    if (isRegistered) {
      dispatch(sendVerificationEmail());
      await dispatch(sendAutomatedEmail(emailData));
    }
  };

  useEffect(() => {
    if (isSuccess && isLoggedIn) {
      navigate("/verifyEmail");
    }
    dispatch(RESET_AUTH());
  }, [isLoggedIn, isSuccess, dispatch, navigate]);

  const logoClick = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    navigate("/");
  };

  return (
    <>
      <Meta
        title={"Sign up - Shop With Meenah"}
        canonical={"https://shopwithmeenah.com/register"}
        descName={"Register with shop with meenah..."}
        keywords="shopwithmeenah signup, shopwithmeenah, shop with meenah, shop with meenah signup,shop with meenah register, shopwithmeenah register"
        googlebot={"follow, index"}
      />
      {loading && <Loader LoadingState={"Setting Up..."} />}
      <div className="form">
        <div className="form-wrapper">
          <FaUserClock size={35} color="#999" />
          <h4>Sign Up</h4>
          <form onSubmit={submitSignUp}>
            <div className="input-cont">
              <FaUser size={18} color="#aaa" />
              <input
                type="text"
                placeholder="Legal Firstname"
                required
                name="fname"
                value={fname}
                onChange={handleInputChange}
              />
            </div>
            <div className="input-cont">
              <FaUser size={18} color="#aaa" />
              <input
                type="text"
                placeholder=" Legal Lastname (Surname)"
                required
                name="lname"
                value={lname}
                onChange={handleInputChange}
              />
            </div>
            <div className="input-cont">
              <MdOutlineMailOutline size={26} color="#aaa" />
              <input
                type="email"
                placeholder="E-mail"
                required
                name="email"
                value={email}
                onChange={handleInputChange}
              />
            </div>

            <div className="input-cont">
              <PhoneInput
                placeholder="Phone Number"
                required
                value={phone}
                onChange={setPhone}
                defaultCountry="NG"
                international={true}
                className="phone-input"
                limitMaxLength
              />
            </div>

            <PasswordInput
              placeholder="Password"
              name="password"
              value={password}
              onChange={handleInputChange}
              onPaste={handlePaste}
              onFocus={handleFocus}
              onBlur={handleBlur}
            />

            <PasswordInput
              placeholder={"Confirm Password"}
              name="cpassword"
              value={cpassword}
              onChange={handleInputChange}
              onPaste={handlePaste}
              onFocus={handleFocus}
              onBlur={handleBlur}
            />

            <div className={inputFocus ? "group" : "none"}>
              <ul className="form-list">
                <li>
                  <span className="indicator">
                    {swithIcon(uCase)}
                    &nbsp; Lowercase & Uppercase
                  </span>
                </li>
                <li>
                  <span className="indicator">
                    {swithIcon(num)}
                    &nbsp; Number (0-9)
                  </span>
                </li>
                <li>
                  <span className="indicator">
                    {swithIcon(sChar)}
                    &nbsp; Special Chracter (!@#$%^&*?)
                  </span>
                </li>
                <li>
                  <span className="indicator">
                    {swithIcon(passLength)}
                    &nbsp; At Least 6 Character
                  </span>
                </li>
              </ul>
            </div>
            <span className="remember-section no-uline">
              <label htmlFor="remember-me">
                <input
                  type="checkbox"
                  name=""
                  id="remember-me"
                  required
                  checked
                />
                &nbsp; I agree to the{" "}
                <Link
                  to="/terms-of-service"
                  className="--inline-link"
                  target="_blank"
                >
                  Terms and Conditions
                </Link>
                and{" "}
                <Link
                  to="/privacy-policy"
                  className="--inline-link"
                  target="_blank"
                >
                  Privacy Policy
                </Link>
              </label>
              {/* <span>Forgot Password</span> */}
            </span>
            <input type="submit" value="Sign Up" />
          </form>
          <span className="new-user">
            Already a member ? &nbsp;
            <Link to="/login" className="sign-up">
              Sign in
            </Link>
          </span>

          <div className="powered-by" onClick={logoClick}>
            <span>Powered by </span>
            <span>
              <img src={bag} alt="logo" width={100} />
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default Register;
