import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import sizeService from "./sizeService";
import { toast } from "react-toastify";

const initialState = {
  sizes: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

//Create size
export const createSize = createAsyncThunk(
  "size/createSize",
  async (sizeData, thunkAPI) => {
    try {
      return await sizeService.createSize(sizeData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);
//Get sizes
export const getSizes = createAsyncThunk(
  "size/getSizes",
  async (_, thunkAPI) => {
    try {
      return await sizeService.getSizes();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);
//Delete size
export const deleteSize = createAsyncThunk(
  "size/deleteSize",
  async (id, thunkAPI) => {
    try {
      return await sizeService.deleteSize(id);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);
const sizeSlice = createSlice({
  name: "size",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createSize.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createSize.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        toast.success("size Created");
      })
      .addCase(createSize.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.error(action.payload);
      })
      //Get sizes
      .addCase(getSizes.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getSizes.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.sizes = action.payload;
      })
      .addCase(getSizes.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.error(action.payload);
      })
      //Delete Color
      .addCase(deleteSize.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteSize.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        toast.success("Size deleted");
      })
      .addCase(deleteSize.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.error(action.payload);
      });
  },
});

export default sizeSlice.reducer;
