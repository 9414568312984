import { useEffect, useState } from "react";
import css from "./Settings.module.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  getUser,
  updatePhoto,
  updateUser,
} from "../../../redux/features/auth/authSlice";
import { AiOutlineCloudUpload } from "react-icons/ai";
// import { toast } from "react-toastify";
import { shortenText } from "../../../utils";
import { Spinner } from "../../loader/Loader";
import { GoDotFill } from "react-icons/go";
import { FaEdit } from "react-icons/fa";
import ChangePassword from "../../changePassword/ChangePassword";

const cloud_name = process.env.REACT_APP_CLOUD_NAME;
const upload_preset = process.env.REACT_APP_UPLOAD_PRESET;
const cloudinary_url = process.env.REACT_APP_CLOUDINARY_URL;

const Dashboard = () => {
  const { loading, user } = useSelector((state) => state.auth);
  const initialState = {
    fname: user?.fname || "",
    lname: user?.lname || "",
    email: user?.email || "",
    phone: user?.phone || "",
    role: user?.role || "",
    gender: user?.gender || "",
    dob: user?.dob || "",
    photo: user?.photo || "",
    address: user?.address || {},
    verified: user?.verified || false,
  };
  const [profile, setProfile] = useState(initialState);
  const [profileImage, setProfileImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (user === null) {
      dispatch(getUser());
    }
  }, [dispatch, user]);

  useEffect(() => {
    if (user) {
      setProfile({
        // ...profile,
        fname: user?.fname || "",
        lname: user?.lname || "",
        email: user?.email || "",
        phone: user?.phone || "",
        role: user?.role || "",
        gender: user?.gender || "",
        dob: user?.dob || "",
        photo: user?.photo || "",
        address: user.address || {},
        verified: user?.verified,
      });
    }
  }, [dispatch, user]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProfile({ ...profile, [name]: value });
  };

  const saveProfile = async (e) => {
    e.preventDefault();

    const userData = {
      fname: profile.fname,
      lname: profile.lname,
      phone: profile.phone,
      gender: profile.gender,
      dob: profile.dob,
      address: {
        street: profile.street,
        state: profile.state,
        city: profile.city,
        zip: profile.zip,
      },
    };
    await dispatch(updateUser(userData));
  };
  const handleImageChange = (e) => {
    setProfileImage(e.target.files[0]);
    setImagePreview(URL.createObjectURL(e.target.files[0]));
  };

  const savePhoto = async (e) => {
    e.preventDefault();
    let imageURL;
    try {
      if (
        profileImage !== null &&
        (profileImage.type === "image/jpeg" ||
          profileImage.type === "image/jpg" ||
          profileImage.type === "image/png")
      ) {
        const image = new FormData();
        image.append("file", profileImage);
        image.append("cloud_name", cloud_name);
        image.append("upload_preset", upload_preset);
        image.append("folder", "usersProfile");

        //save to cloudinary
        const response = await fetch(cloudinary_url, {
          method: "post",
          body: image,
        });
        const imgData = await response.json();
        imageURL = imgData.url.toString();
      }
      // Save image to db
      const userData = {
        photo: profileImage ? imageURL : profile.photo,
      };
      await dispatch(updatePhoto(userData));
      setImagePreview(null);
    } catch (error) {
      // toast.error(error.message);
    }
  };

  return (
    <>
      {loading && <Spinner />}
      <div className={css.dashboard}>
        <span className={css.dtitle}>General Information</span>
        <form onSubmit={saveProfile}>
          {/* {!loading && ()} */}
          <div className={css["d-photo-wrap"]}>
            <span className={css.imgcont}>
              <img
                src={imagePreview === null ? user?.photo : imagePreview}
                alt=""
              />
            </span>
            <small style={{ padding: "4px" }}>
              <GoDotFill
                color={
                  user?.role !== process.env.REACT_APP_CHECK_USER
                    ? "green"
                    : "red"
                }
              />
              {user?.role !== process.env.REACT_APP_CHECK_USER && "Active"}
            </small>
            <small
              className={user?.verified ? "--color-green" : "--color-danger"}
            >
              Email: {user?.verified ? "Verified" : "unverified"}
            </small>

            <label for="file" className={css["upload-icon"]}>
              <FaEdit size={18} /> &nbsp;Edit photo
            </label>

            {imagePreview !== null && (
              <>
                {!loading && (
                  <button onClick={savePhoto} className="--btn --btn-primary">
                    <AiOutlineCloudUpload size={18} /> Upload Photo
                  </button>
                )}
              </>
            )}
            <br></br>
            <span
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <input
                type="file"
                name="image"
                onChange={handleImageChange}
                id="file"
                style={{ display: "none" }}
              />
            </span>
          </div>
          <div className={css.dashwrapper}>
            <div className={css["l-form"]}>
              <div className={css.lcontent}>
                <label htmlFor="">First Name</label>
                <input
                  type="text"
                  name="fname"
                  value={profile?.fname}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className={css.lcontent}>
                <label htmlFor="">Last Name</label>
                <input
                  type="text"
                  name="lname"
                  value={profile?.lname}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className={css.lcontent}>
                <label htmlFor="">Email</label>
                <input
                  type="email"
                  name="email"
                  value={profile?.email}
                  onChange={handleInputChange}
                  readOnly
                  disabled
                />
              </div>
              <div className={css.lcontent}>
                <label htmlFor="">Phone</label>
                <input
                  type="number"
                  name="phone"
                  value={profile?.phone}
                  onChange={handleInputChange}
                  readOnly
                  disabled
                />
              </div>
            </div>
            <div className={css["r-form"]}>
              <div className={css.rcontent}>
                <label htmlFor="">Gender</label>
                <input
                  type="text"
                  name="gender"
                  value={profile?.gender}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className={css.rcontent}>
                <label htmlFor="">Date of Birth</label>
                <input
                  type="date"
                  max={"2014-01-01"}
                  name="dob"
                  value={profile?.dob}
                  onChange={handleInputChange}
                  required
                />
              </div>
            </div>
          </div>
          <br />
          <span className={css.dtitle}>Billing Address</span>
          <br />
          <br />
          <div className={css.dashwrapper}>
            <div className={css["l-form"]}>
              <div className={css.lcontent}>
                <label htmlFor="">Street</label>
                <input
                  type="text"
                  name="street"
                  value={profile?.address?.street}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className={css.lcontent}>
                <label htmlFor="">City</label>
                <input
                  type="text"
                  name="city"
                  value={profile?.address?.city}
                  onChange={handleInputChange}
                  required
                />
              </div>
            </div>
            <div className={css["r-form"]}>
              <div className={css.rcontent}>
                <label htmlFor="">State</label>
                <input
                  type="text"
                  name="state"
                  value={profile?.address?.state}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className={css.rcontent}>
                <label htmlFor="">Zipcode</label>
                <input
                  type="number"
                  name="zip"
                  value={profile?.address?.zip}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className={css.rcontent}>
                <label htmlFor="">Country</label>
                <input type="text" value="Nigeria" readOnly />
              </div>
            </div>
          </div>
          <input
            type={loading ? "button" : "submit"}
            value={loading ? "Saving..." : "Save Changes"}
          />
        </form>
        <ChangePassword />
      </div>
    </>
  );
};

export const UserName = () => {
  const { user } = useSelector((state) => state.auth);

  const username = user?.fname || "...";
  return (
    <span style={{ fontSize: "14px", paddingLeft: "4px" }}>
      Hi, {shortenText(username, 6)}
    </span>
  );
};

export default Dashboard;
