import React from "react";
import Meta from "../../components/meta/Meta";
import Loader from "../../components/loader/Loader";

const PrivacyPolicy = () => {
  return (
    <>
      <Meta
        title={"Privacy Policy - Shop With Meenah"}
        canonical={"https://shopwithmeenah.com/privacy-policy"}
      />
      <div className="--f14 --color-grey">Content loading...</div>
      <div>
        <Loader />
      </div>
    </>
  );
};

export default PrivacyPolicy;
