import React, { useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getOrder } from "../../redux/features/order/orderSlice";
import { Link } from "react-router-dom";
import css from "./OrderDetails.module.scss";
import { Spinner } from "../../components/loader/Loader";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { FaNairaSign } from "react-icons/fa6";
import { toast } from "react-toastify";

const OrderDetailsComp = ({ orderPageLink }) => {
  const { id } = useParams();
  const pdfRef = useRef();

  const dispatch = useDispatch();
  const { isLoading, order } = useSelector((state) => state.order);

  useEffect(() => {
    dispatch(getOrder(id));
  }, [dispatch, id]);

  const downloadPDF = () => {
    const input = pdfRef.current;
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4", true);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();
      const imageWidth = canvas.width;
      const imageHeight = canvas.height;
      const ratio = Math.min(pdfWidth / imageWidth, pdfHeight / imageHeight);
      const imgX = (pdfWidth - imageWidth * ratio) / 2;
      const imgY = 30;
      pdf.addImage(
        imgData,
        "PNG",
        imgX,
        imgY,
        imageWidth * ratio,
        imageHeight * ratio
      );
      pdf.save(`swmeenahInvoice.pdf`);
    });
  };

  return (
    <section>
      <div className="container" ref={pdfRef}>
        <div className="table">
          {isLoading && order === null ? (
            <Spinner />
          ) : (
            <>
              <p className={css["order-content"]}>
                <b>Ship To:</b>
                {order?.shippingAddress?.name}
              </p>
              <p className={css["order-content"]}>
                <b>Order ID:</b>
                {order?._id}
              </p>
              <p className={css["order-content"]}>
                <b>Order Amount:</b>#{order?.orderAmount}
              </p>
              <p className={css["order-content"]}>
                <b>Coupon</b> {order?.coupon.name} ~ {order?.coupon?.discount}%
              </p>
              <p className={css["order-content"]}>
                <b>Payment Method:</b>
                {order?.paymentMethod}
              </p>
              <p className={css["order-content"]}>
                <b>Order Status:</b>
                {order?.orderStatus}
              </p>
              <p className={css["order-content"]}>
                <span className="--color-primary">Shipping Address:</span>
                <br />
                <b>Address: </b> {order?.shippingAddress?.line1},
                {order?.shippingAddress?.line2},{order?.shippingAddress?.city},
                <br />
                <b>State:</b> {order?.shippingAddress?.state},
                <br />
                <b>Country:</b> {order?.shippingAddress?.country}
              </p>
              <br />
              {/* Table */}
              {order?.cartItems?.map((cart) => {
                const {
                  _id,
                  name,
                  price,
                  image,
                  cartQuantity,
                  color,
                  brand,
                  itemColor,
                  itemSize,
                } = cart;
                return (
                  <div
                    key={_id}
                    className={`--flex-between ${css["cart-items-wrap"]} --mt10`}
                  >
                    <div className="--flex">
                      <span
                        style={{ width: "80px", height: "80px" }}
                        className="--mr"
                      >
                        <img
                          src={image[0]}
                          width={80}
                          alt=""
                          // onClick={() => navigate(`/product-details/${_id}`)}
                        />
                      </span>
                      <div>
                        <h3 className="--f16">{name}</h3>
                        <small>{brand}</small>&emsp;
                        <small>{color}</small>
                        <p className="--items-center --color-primary">
                          <FaNairaSign />
                          {Number(price * cartQuantity).toLocaleString(2)}
                        </p>
                        <span className="--flex">
                          <small>{cartQuantity}</small>
                          <small
                            style={{
                              width: "16px",
                              height: "16px",
                              background: `${itemColor?.hexCode}`,
                              display: "block",
                              borderRadius: "3em",
                              margin: "0 6px",
                            }}
                          ></small>
                          <small>{itemSize?.size}</small>
                        </span>
                      </div>
                    </div>
                    <span>
                      {order.orderStatus === "Delivered" ? (
                        <Link to={`/review-product/${_id}`}>
                          <button className="--btn --bg-fblack --color-gray">
                            Review Product
                          </button>
                        </Link>
                      ) : (
                        <Link
                          onClick={() =>
                            toast.error("Kindly allow order(s) to be delivered")
                          }
                        >
                          <button className="--btn --bg-grey --color-gray">
                            Review Product
                          </button>
                        </Link>
                      )}
                    </span>
                  </div>
                );
              })}
            </>
          )}
        </div>
        <br></br>
        {/* <div className="--center-all">
          <button className="--btn --btn-primary" onClick={downloadPDF}>
            Download as PDF
          </button>
        </div> */}
      </div>
    </section>
  );
};

export default OrderDetailsComp;
