import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import styles from "./ProductChart.module.scss";
import { useSelector } from "react-redux";
import Card from "../../card/Card";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: false,
      text: "Chart.js Bar Chart",
    },
  },
};

const ProductChart = () => {
  const { products } = useSelector((state) => state.product);
  const countOutOfStockProducts = () => {
    return products.reduce((count, product) => {
      if (product.quantity < 1) {
        return count + 1;
      }
      return count;
    }, 0);
  };
  const inStock = () => {
    return products.reduce((count, product) => {
      if (product.quantity >= 1) {
        return count + 1;
      }
      return count;
    }, 0);
  };
  const lowOnStock = () => {
    return products.reduce((count, product) => {
      if (product.quantity <= 3) {
        return count + 1;
      }
      return count;
    }, 0);
  };

  const total = products.length;
  const available = inStock();
  const low = lowOnStock();
  const outOfStock = countOutOfStockProducts();

  const data = {
    labels: ["Total products", "In Stock", "Low on Stock", "Out of Stock"],
    datasets: [
      {
        label: "Product count",
        data: [total, available, low, outOfStock],
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
    ],
  };

  return (
    <div className={styles.pcharts}>
      <Card cardClass={styles.card}>
        <h3>Products Chart</h3>
        <Bar options={options} data={data} />
      </Card>
    </div>
  );
};

export default ProductChart;
