import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  ADD_TO_CART,
  CALCULATE_SUBTOTAL,
  CALCULATE_TOTAL_QUANTITY,
  CLEAR_CART,
  DECREASE_CART,
  REMOVE_FROM_CART,
  saveCartDB,
  selectCartItems,
  selectCartTotalQuantity,
  selectCartTotalAmount,
} from "../../redux/features/cart/cartSlice";
import css from "./Cart.module.scss";
import { FaTrash } from "react-icons/fa";
import Card from "../../components/card/Card";
import VerifyCoupon from "../../components/verifyCoupon/VerifyCoupon";
import PaymentOptions from "../../components/paymentOptions/PaymentOptions";
import { shortenText } from "../../utils";
import { FaNairaSign } from "react-icons/fa6";

import { GoPlusCircle } from "react-icons/go";
import { HiOutlineMinusCircle } from "react-icons/hi2";
import { getLoginStatus, getUser } from "../../redux/features/auth/authSlice";

import Loader from "../../components/loader/Loader";
import Meta from "../../components/meta/Meta";
import secureLocalStorage from "react-secure-storage";

const Cart = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const cartItems = useSelector(selectCartItems);
  const cartTotalQuantity = useSelector(selectCartTotalQuantity);
  const cartTotalAmount = useSelector(selectCartTotalAmount);
  const { coupon } = useSelector((state) => state.coupon);
  const { user, isLoggedIn, loading } = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(getLoginStatus());
    if (isLoggedIn && user === null) {
      dispatch(getUser());
    }
  }, [user, dispatch, isLoggedIn]);

  const increaseCart = (cart) => {
    if (!isLoggedIn || user === null) {
      return navigate("/login");
    }
    dispatch(ADD_TO_CART(cart));
    dispatch(
      saveCartDB({
        cartItems: JSON.parse(secureLocalStorage.getItem("cartItems")),
      })
    );
  };

  const decreaseCart = (cart) => {
    if (!isLoggedIn || user === null) {
      return navigate("/login");
    }
    dispatch(DECREASE_CART(cart));
    dispatch(
      saveCartDB({
        cartItems: JSON.parse(secureLocalStorage.getItem("cartItems")),
      })
    );
  };
  const removeFromCart = (cart) => {
    if (!isLoggedIn || user === null) {
      return navigate("/login");
    }
    dispatch(REMOVE_FROM_CART(cart));
    dispatch(
      saveCartDB({
        cartItems: JSON.parse(secureLocalStorage.getItem("cartItems")),
      })
    );
  };
  const clearCart = () => {
    if (!isLoggedIn || user === null) {
      return navigate("/login");
    }
    dispatch(CLEAR_CART());
    dispatch(saveCartDB({ cartItems: [] }));
  };

  useEffect(() => {
    dispatch(
      saveCartDB({
        cartItems: JSON.parse(secureLocalStorage.getItem("cartItems")),
      })
    );
  }, [dispatch, cartItems]);

  useEffect(() => {
    dispatch(CALCULATE_TOTAL_QUANTITY());
    dispatch(CALCULATE_SUBTOTAL({ coupon }));
  }, [dispatch, cartItems, coupon]);

  return (
    <section>
      <Meta
        robots={"nofollow, noindex"}
        googlebot={"nofollow, noindex"}
        title={"Cart"}
      />
      {loading && <Loader />}
      <div className={css.table}>
        <br></br>
        <h3 className="--fw-thin">Shopping Cart</h3>
        {cartItems?.length === 0 ? (
          <>
            <p className="--color-grey">Your cart is empty</p>
          </>
        ) : (
          <div>
            <div>
              {cartItems?.map((cart) => {
                const { _id, name, price, cartQuantity, image, brand } = cart;
                return (
                  <div
                    key={_id}
                    className={`--flex-between ${css["cart-items-wrap"]}`}
                  >
                    <div className="--flex">
                      <span
                        style={{ width: "80px", height: "80px" }}
                        className="--mr"
                      >
                        <img
                          src={image[0]}
                          width={80}
                          height={80}
                          onClick={() => navigate(`/product-details/${_id}`)}
                          alt=""
                        />
                      </span>
                      <div>
                        <h3 className="--f14 ">{shortenText(name, 22)}</h3>
                        <span className="--items-center">
                          <small>{brand}</small>&emsp;
                          <small
                            style={{
                              background: cart?.itemColor?.hexCode,
                              width: "15px",
                              height: "15px",
                              display: "flex",
                              borderRadius: "50%",
                              marginRight: "10px",
                            }}
                          ></small>
                          <small>{cart?.itemSize?.size}</small>
                        </span>
                        <p className="--items-center --f14 --fw-thin">
                          <FaNairaSign size={13} />
                          {Number(price * cartQuantity).toLocaleString()}
                        </p>
                        <div className={`${css.count}`}>
                          <>
                            <HiOutlineMinusCircle
                              size={22}
                              color="var(--grey)"
                              onClick={() => decreaseCart(cart)}
                            />
                            <p className={css["count-total"]}>
                              <b>{cart.cartQuantity}</b>
                            </p>
                            <GoPlusCircle
                              size={22}
                              color="var(--grey)"
                              onClick={() => increaseCart(cart)}
                            />
                          </>
                        </div>
                      </div>
                    </div>

                    {/* <div
                      className="--flex-col"
                      style={{
                        alignItems: "flex-end ",
                        justifyContent: "space-between",
                      }}
                    > */}

                    <span>
                      <FaTrash
                        size={19}
                        onClick={() => removeFromCart(cart)}
                        color="red"
                      />
                    </span>
                    {/* </div> */}
                  </div>
                );
              })}
            </div>
            <div className={css.summary}>
              <button className="--btn --btn-danger" onClick={clearCart}>
                Clear Cart
              </button>
              <div className={css.checkout}>
                <div>
                  <Link to={"/shop"} className="--color-primary">
                    &larr; Continue Shopping
                  </Link>
                </div>
                <br />
                <Card cardClass={css.card}>
                  <p>
                    <b>{`Cart item(s): ${cartTotalQuantity}`}</b>
                  </p>
                  <div className={css.text}>
                    <h4>Subtotal:</h4>
                    <h3>{`#${cartTotalAmount?.toLocaleString(2)}`}</h3>
                  </div>
                  <VerifyCoupon />
                  <div className="--box-underline"></div>
                  <PaymentOptions />
                </Card>
              </div>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default Cart;
