import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteSize, getSizes } from "../../../redux/features/size/sizeSlice";
import { FaTrashAlt } from "react-icons/fa";
import { confirmAlert } from "react-confirm-alert";
import CreateSize from "./CreateSize";

const Size = () => {
  const dispatch = useDispatch();
  const { sizes } = useSelector((state) => state.size);

  useEffect(() => {
    dispatch(getSizes());
  }, [dispatch]);

  const confirmDelete = (size) => {
    confirmAlert({
      title: "Delete Size",
      message: "Are you sure you want to delete this size",
      buttons: [
        {
          label: "Delete",
          onClick: () => delSize(size),
        },
        {
          label: "Cancel",
          // onClick: () => alert("Click No")
        },
      ],
    });
  };
  const delSize = async (size) => {
    await dispatch(deleteSize(size));
    await dispatch(getSizes());
  };
  return (
    <>
      <CreateSize />

      <div className="table">
        {sizes?.length === 0 ? (
          <p>No Size Found</p>
        ) : (
          <table>
            <thead>
              <tr>
                <th>S/n</th>
                <th>Id</th>
                <th>Name</th>
                <th>Date Created</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {sizes?.map((size, index) => {
                const { _id, name, createdAt } = size;
                return (
                  <tr key={_id}>
                    <td>{index + 1}</td>
                    <td>{_id}</td>
                    <td>{name}</td>
                    <td>{new Date(createdAt).toDateString()}</td>
                    <td>
                      <span>
                        <FaTrashAlt
                          size={20}
                          color={"red"}
                          onClick={() => confirmDelete(_id)}
                        />
                      </span>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </div>
    </>
  );
};

export default Size;
