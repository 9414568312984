import { useState } from "react";
import Card from "../../card/Card";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Loader from "../../loader/Loader";
import {
  createColor,
  getColors,
} from "../../../redux/features/color/colorSlice";

const CreateColor = () => {
  const { loading } = useSelector((state) => state.color);
  const dispatch = useDispatch();

  const [name, setName] = useState("");
  const [hexCode, setHexCode] = useState("");

  const saveColor = async (e) => {
    e.preventDefault();
    if (name.length < 3 || hexCode.length < 3) {
      return toast.error("Invalid Color Details");
    }

    const colorData = {
      name,
      hexCode,
    };
    await dispatch(createColor(colorData));
    await dispatch(getColors());
    setName("");
    setHexCode("");
  };

  return (
    <>
      {loading && <Loader />}
      <div className="--mb2 create-category">
        <h3>Create Color</h3>
        <small>
          Use the form to{" "}
          <span className="--color-primary">Create a Color</span>
        </small>
        <Card cardClass={"card"}>
          <br />
          <form onSubmit={saveColor}>
            <label htmlFor="">Color Name:</label>
            <input
              type="text"
              name="name"
              value={name}
              placeholder="Color Name"
              onChange={(e) => setName(e.target.value)}
              required
            />
            <label htmlFor="">Color Hexcode:</label>
            <input
              type="text"
              name="hexCode"
              value={hexCode}
              placeholder="Color HexCode"
              onChange={(e) => setHexCode(e.target.value)}
              required
            />
            <input
              type="submit"
              value="Save Color"
              className="--btn --btn-primary"
            />
          </form>
        </Card>
      </div>
    </>
  );
};

export default CreateColor;
