import "./ProductForm.scss";
import Card from "../../../card/Card";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import UploadWidget from "./UploadWidget";
import { BsTrash } from "react-icons/bs";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import Multiselect from "react-widgets/Multiselect";
import "react-widgets/styles.css";
// import generateNumbers from "../../../../utils";
const mainCategoryData = [
  {
    id: 1,
    name: "Men",
  },
  {
    id: 2,
    name: "Women",
  },
  {
    id: 3,
    name: "Special",
  },
];

const ProductForm = ({
  saveProduct,
  isEditing,
  product,
  setProduct,
  description,
  setDescription,
  placeholder,
  files,
  setFiles,
  setColor,
  color,
  colorss,
  size,
  setSize,
  sizess,
  // colorQty,
  // setColorQty,
}) => {
  const [filteredBrands, setFilteredBrands] = useState([]);
  const { categories, brands } = useSelector((state) => state.category);

  //Fliter Brands based on selected category
  const filterBrands = (selectedCategory) => {
    const newBrands = brands.filter(
      (brand) => brand.category === selectedCategory
    );
    setFilteredBrands(newBrands);
  };

  useEffect(() => {
    filterBrands(product?.category);
  }, [product?.category]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProduct({ ...product, [name]: value });
  };

  const removeImage = (image) => {
    setFiles(files.filter((img) => img !== image));
  };

  return (
    <div className="add-product">
      <UploadWidget files={files} setFiles={setFiles} />
      <Card cardClass={"card"}>
        <br />
        <form onSubmit={saveProduct}>
          <label>Product Image(s):</label>
          <div className="slide-container">
            <aside>
              {files.length > 0 &&
                files.map((image) => (
                  <div key={image} className="thumbnail">
                    <img src={image} alt="" height={100} />
                    <div>
                      <BsTrash
                        size={25}
                        className="thumbnailIcon"
                        onClick={() => removeImage(image)}
                      />
                    </div>
                  </div>
                ))}
              {files.length < 1 && <p>No Image set for this product</p>}
            </aside>
          </div>
          <label>Product Name:</label>
          <input
            type="text"
            name="name"
            value={product?.name}
            onChange={handleInputChange}
            placeholder="Product Name"
            required
          />
          <label>Main Category:</label>
          <select
            name="mainCategory"
            value={product?.mainCategory}
            onChange={handleInputChange}
            required
          >
            {isEditing ? (
              <option value={product?.mainCategory}>
                {product?.mainCategory}
              </option>
            ) : (
              <option> Select main category</option>
            )}
            {mainCategoryData.map((mcat) => (
              <option key={mcat.id} value={mcat.name}>
                {mcat.name}
              </option>
            ))}
          </select>
          <label>Product Category:</label>
          <select
            name="category"
            value={product?.category}
            onChange={handleInputChange}
          >
            {isEditing ? (
              <option value={product?.category}>{product?.category}</option>
            ) : (
              <option> select category</option>
            )}
            {categories.length > 0 &&
              categories.map((cat) => (
                <option key={cat._id} value={cat.name}>
                  {cat.name}
                </option>
              ))}
          </select>
          <label>Product Brand:</label>
          <select
            name="brand"
            value={product?.brand}
            onChange={handleInputChange}
          >
            {isEditing ? (
              <option value={product?.brand}>{product?.brand}</option>
            ) : (
              <option> select brand</option>
            )}
            {filteredBrands.length > 0 &&
              filteredBrands.map((brand) => (
                <option key={brand._id} value={brand.name}>
                  {brand.name}
                </option>
              ))}
          </select>
          {/* <label>Color Quantity</label>
          <Multiselect
            data={generateNumbers(100)}
            dataKey="_id"
            textField="color"
            value={color.colorQty}
            onChange={setColorQty}
          /> */}

          <label>Product Color(s):</label>

          <Multiselect
            dataKey="_id"
            textField="color"
            data={colorss}
            value={color}
            onChange={setColor}
            placeholder="Choose Product Colors..."
          />
          <label>Product Size(s):</label>

          <Multiselect
            dataKey="_id"
            textField="size"
            data={sizess}
            value={size}
            onChange={setSize}
            placeholder="Choose Product Sizes..."
          />
          <label>Regular Price:</label>
          <input
            type="number"
            name="regularPrice"
            value={product?.regularPrice}
            onChange={handleInputChange}
            placeholder="Regular Price"
          />
          <label>Product Price:</label>
          <input
            type="number"
            name="price"
            value={product?.price}
            onChange={handleInputChange}
            placeholder="Product Price"
          />
          <label>Product Quantity:</label>
          <input
            type="number"
            name="quantity"
            value={product?.quantity}
            onChange={handleInputChange}
            placeholder="Product Quantity"
          />
          <label>Product Description:</label>
          <ReactQuill
            theme="snow"
            value={description}
            onChange={setDescription}
            modules={ProductForm.modules}
            formats={ProductForm.formats}
            placeholder={placeholder}
          />
          <br></br>
          <input
            type="submit"
            value="Save Product"
            className="--btn --btn-primary"
          />
        </form>
      </Card>
    </div>
  );
};

ProductForm.modules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }, { font: [] }],
    [{ size: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [{ align: [] }],
    [{ color: [] }, { background: [] }],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["clean"],
  ],
};

ProductForm.formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "color",
  "background",
  "list",
  "bullet",
  "indent",
  "link",
  "video",
  "image",
  "code-block",
  "align",
];
export default ProductForm;
