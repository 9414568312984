import React, { useEffect } from "react";
import { FaTrashAlt } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import {
  deleteCoupon,
  getCoupons,
} from "../../../redux/features/coupon/couponSlice";
import "./Coupon.scss";

const CouponList = () => {
  const { coupons } = useSelector((state) => state.coupon);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCoupons());
  }, [dispatch]);

  const confirmDelete = (coupon) => {
    confirmAlert({
      title: "Delete Coupon",
      message: "Are you sure you want to delete this coupon",
      buttons: [
        {
          label: "Delete",
          onClick: () => delCoupon(coupon),
        },
        {
          label: "Cancel",
          // onClick: () => alert("Click No")
        },
      ],
    });
  };

  const delCoupon = async (coupon) => {
    await dispatch(deleteCoupon(coupon));
    await dispatch(getCoupons());
  };
  return (
    <div className="--mb2 category-list">
      <h3>All Coupons</h3>
      <div className="table hideforscreen">
        {coupons.length === 0 ? (
          <p>No Coupon Found</p>
        ) : (
          <table>
            <thead>
              <tr>
                <th>s/n</th>
                <th>Name</th>
                <th>Discount(%)</th>
                <th>Date Created</th>
                <th>Expiry Date</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {coupons.map((coupon, index) => {
                const { _id, name, discount, expiresAt, createdAt } = coupon;
                return (
                  <tr key={_id}>
                    <td>{index + 1}</td>
                    <td>{name}</td>
                    <td>{`${discount}%`}</td>
                    <td>{new Date(createdAt).toDateString()}</td>
                    <td>{new Date(expiresAt).toDateString()}</td>
                    <td>
                      <span>
                        <FaTrashAlt
                          size={20}
                          color={"red"}
                          onClick={() => confirmDelete(_id)}
                        />
                      </span>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </div>
      <div>
        {coupons.map((coupon) => {
          const { _id, name, discount, expiresAt, createdAt } = coupon;
          return (
            <>
              <div key={_id} className="mobile-coupon">
                <div className="--flex-between">
                  <span>{name}</span>
                  <span>{`${discount}%`}</span>
                </div>
                <div className="--flex-between">
                  <span>
                    <small>Created: {createdAt}</small>
                  </span>
                  <span>
                    <small>Expires: {expiresAt}</small>
                  </span>
                </div>
              </div>
            </>
          );
        })}
      </div>
    </div>
  );
};

export default CouponList;
