import StarRatings from "react-star-ratings";
import "./Review.scss";

const Review = ({ averageRating, noOfRatings, starDimension }) => {
  return (
    <div>
      {/* {averageRating > 0 && ( */}
      <>
        <StarRatings
          numberOfStars={5}
          starEmptyColor="var(--gray)"
          starRatedColor="gold"
          rating={averageRating}
          editing={false}
          starDimension={starDimension}
          starSpacing="0"
        />
        ({noOfRatings})
      </>
      {/* )} */}
    </div>
  );
};

export default Review;
