import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import styles from "./UserChart.module.scss";
import { useSelector } from "react-redux";
import Card from "../../card/Card";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: false,
      text: "Chart.js Bar Chart",
    },
  },
};

const UserChart = () => {
  const { users, verifiedUsers, suspendedUsers } = useSelector(
    (state) => state.auth
  );

  const total = users.length;
  const verified = verifiedUsers;
  const uv = users.length - verifiedUsers;
  const suspended = suspendedUsers;

  const data = {
    labels: ["Total Users", "Verified", "Unverified", "Suspended"],
    datasets: [
      {
        label: "User count",
        data: [total, verified, uv, suspended],
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
    ],
  };

  return (
    <div className={styles.charts}>
      <Card cardClass={styles.card}>
        <h3>User Status Chart</h3>
        <Bar options={options} data={data} />
      </Card>
    </div>
  );
};

export default UserChart;
