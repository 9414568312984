import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../loader/Loader";
import ProductForm from "../productForm/ProductForm";
import { useEffect, useState } from "react";
import {
  getBrands,
  getCategories,
} from "../../../../redux/features/categoryAndBrand/CategoryAndBrandSlice";
import {
  RESET_PROD,
  createProduct,
} from "../../../../redux/features/product/productSlice";
import { useNavigate } from "react-router-dom";
import { getColors } from "../../../../redux/features/color/colorSlice";
import { getSizes } from "../../../../redux/features/size/sizeSlice";
import { toast } from "react-toastify";

const initialState = {
  name: "",
  category: "",
  mainCategory: "",
  brand: "",
  quantity: "",
  price: "",
  regularPrice: "",
};
const AddProduct = () => {
  const [product, setProduct] = useState(initialState);
  const [description, setDescription] = useState("");
  const [files, setFiles] = useState([]);
  const [color, setColor] = useState([]);
  const [size, setSize] = useState([]);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { name, mainCategory, category, brand, quantity, price, regularPrice } =
    product;
  const { loading, message } = useSelector((state) => state.product);
  const { colors } = useSelector((state) => state.color);
  const { sizes } = useSelector((state) => state.size);
  // const [colorQty, setColorQty] = useState([]);

  useEffect(() => {
    dispatch(getCategories());
    dispatch(getBrands());
    dispatch(getColors());
    dispatch(getSizes());
  }, [dispatch]);

  //Returning only name
  // let colorss = colors.map((col) => {
  //   return col.name.toLowerCase();
  // });

  let colorss = [];
  colors.forEach((col) => {
    colorss.push({
      _id: col._id,
      color: col.name,
      hexCode: col.hexCode,
      // colorQty: col.colorQty,
      // colorQty: colorQty,
    });
  });

  let sizess = [];
  sizes.forEach((item) => {
    sizess.push({
      _id: item._id,
      size: item.name,
    });
  });

  const generateSKU = (category) => {
    const letter = category.slice(0, 3).toUpperCase();
    const number = Date.now();
    const sku = letter + "-" + number;
    return sku;
  };

  const saveProduct = async (e) => {
    e.preventDefault();

    if (files.length < 1) {
      return toast.error("Please add an image");
    }

    const formData = {
      name,
      sku: generateSKU(category),
      mainCategory,
      category,
      brand,
      quantity: Number(quantity),
      regularPrice,
      price,
      description,
      image: files,
      color,
      size,
    };
    await dispatch(createProduct(formData));
  };
  useEffect(() => {
    if (message === "Product created successfully") {
      navigate("/admin/all-products");
    }
    dispatch(RESET_PROD());
  }, [message, navigate, dispatch]);

  return (
    <section>
      <div className="container">
        {loading && <Loader />}
        <h3>Add New Product</h3>
        <ProductForm
          saveProduct={saveProduct}
          product={product}
          setProduct={setProduct}
          description={description}
          setDescription={setDescription}
          files={files}
          setFiles={setFiles}
          placeholder={"Add Product Description"}
          isEditing={false}
          color={color}
          setColor={setColor}
          colorss={colorss}
          size={size}
          setSize={setSize}
          sizess={sizess}
          // setColorQty={setColorQty}
        />
      </div>
    </section>
  );
};

export default AddProduct;
