import React, { useEffect } from "react";
import { MdOutlineMarkEmailRead } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import css from "./VerifyEmail.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { getUser } from "../../redux/features/auth/authSlice";
import Meta from "../../components/meta/Meta";

const VerifyEmail = () => {
  const { user, verified } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getUser());

    setTimeout(() => {
      navigate("/");
    }, 5000);
  }, [dispatch, navigate]);

  return (
    <>
      <Meta
        robots={"nofollow, noindex"}
        googlebot={"nofollow, noindex"}
        title={"Verify your email"}
      />
      {!verified && (
        <div className={css["verify-email"]}>
          <span>
            <MdOutlineMarkEmailRead size={50} />
          </span>
          <span style={{ textAlign: "center" }}>
            <h2 className="--f16">
              Welcome, <br />
              {user?.fname + " " + user?.lname}
            </h2>
          </span>
          <div className={css.vcontent}>
            <h3>Verify your email to finish signing up</h3>
            <p>
              To verify your account, please click on the link that was sent to
              your email
            </p>
            <p>
              Thank you for choosing <b>Shop With Meenah</b>
            </p>
            <br />
            <p>
              Please confirm that <b>{user?.email}</b> is your email,
              verification link is only valid for 24 hours
            </p>
          </div>
        </div>
      )}
    </>
  );
};

export default VerifyEmail;
