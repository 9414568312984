import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getProduct } from "../../../redux/features/product/productSlice";
import css from "./ProductDetails.module.scss";
import Loader, { Spinner } from "../../loader/Loader";
import Review from "../../review/Review";
import { calculateAverageRating, calculatePercentage } from "../../../utils";
import { toast } from "react-toastify";
import DOMPurify from "dompurify";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import {
  ADD_TO_CART,
  DECREASE_CART,
  saveCartDB,
  selectCartItems,
} from "../../../redux/features/cart/cartSlice";
import StarRating from "react-star-ratings";
import ReviewSummary from "../../review/ReviewSummary";
import { FaCheck } from "react-icons/fa";
import { FiMinusCircle, FiPlusCircle } from "react-icons/fi";
import { FaNairaSign } from "react-icons/fa6";
import {
  addToWishlist,
  getLoginStatus,
  getUser,
  selectLoading,
} from "../../../redux/features/auth/authSlice";
import { RiShareBoxLine } from "react-icons/ri";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { Breadcrumbs, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Meta from "../../meta/Meta";
import secureLocalStorage from "react-secure-storage";

const ProductDetails = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user, isLoggedIn } = useSelector((state) => state.auth);
  const loadUser = useSelector(selectLoading);

  const [imageIndex, setImageIndex] = useState(0);
  const { product, loading } = useSelector((state) => state.product);
  const cartItems = useSelector(selectCartItems);
  const [itemColor, setItemColor] = useState(null);
  const [itemSize, setItemSize] = useState(null);

  useEffect(() => {
    dispatch(getLoginStatus());
    if (isLoggedIn && user === null) {
      dispatch(getUser());
    }
  }, [user, dispatch, isLoggedIn]);

  const cart = cartItems.find(
    (cart) =>
      cart._id === id &&
      cart?.itemColor?._id === itemColor?._id &&
      cart?.itemSize?._id === itemSize?._id
  );
  const isCartAdded = cartItems.findIndex((cart) => {
    return (
      cart._id === id &&
      cart?.itemColor === itemColor &&
      cart?.itemSize === itemSize
    );
  });

  useEffect(() => {
    dispatch(getProduct(id));
  }, [dispatch, id]);

  const slideLength = product?.image?.length;
  const nextSlide = () => {
    setImageIndex(imageIndex === slideLength - 1 ? 0 : imageIndex + 1);
  };
  let slideInterval;
  useEffect(() => {
    if (product?.image?.length > 1) {
      const auto = () => {
        slideInterval = setInterval(nextSlide, 3000);
      };
      auto();
    }
    return () => clearInterval(slideInterval);
  }, [imageIndex, slideInterval, product]);

  const averageRating = calculateAverageRating(product?.ratings);

  const addToCart = () => {
    if (!isLoggedIn || user === null) {
      return navigate("/login");
    }
    const { color, size, ...newProduct } = product;
    const productColor = (newProduct["itemColor"] = itemColor);
    const productSize = (newProduct["itemSize"] = itemSize);

    if (
      (product?.color.length > 0 && itemColor === null) ||
      (product?.size.length > 0 && itemSize === null)
    ) {
      return toast.error("Please Choose a Color or Size");
    } else {
      dispatch(ADD_TO_CART(newProduct, productColor, productSize));
      dispatch(
        saveCartDB({
          cartItems: JSON.parse(secureLocalStorage.getItem("cartItems")),
        })
      );
    }
  };

  const decreaseCart = () => {
    if (!isLoggedIn || user === null) {
      return navigate("/login");
    }
    dispatch(DECREASE_CART(cart));
    dispatch(
      saveCartDB({
        cartItems: JSON.parse(secureLocalStorage.getItem("cartItems")),
      })
    );
  };
  useEffect(() => {
    dispatch(
      saveCartDB({
        cartItems: JSON.parse(secureLocalStorage.getItem("cartItems")),
      })
    );
  }, [dispatch, cartItems]);
  const addToWish = (product) => {
    const productData = {
      productId: product._id,
    };
    dispatch(addToWishlist(productData));
  };
  const copyToClipBoard = () => {
    const copy = navigator.clipboard.writeText(window.location.href);
    if (copy) {
      toast.info("Link Copied");
    }
  };

  return (
    <>
      {loadUser && <Loader />}
      <Meta
        title={`${product?.name} - Shop With Meenah`}
        canonical={`https://shopwithmeenah.com/${product?._id}`}
        descName={product?.description}
      />
      <section>
        <div className={`container ${css.product}`}>
          <div className={css.breadcrumbs}>
            <Breadcrumbs aria-label="breadcrumb" className={css.breadcrumbs}>
              <Link underline="hover" color="inherit" to="/">
                Home
              </Link>
              <Link underline="hover" color="inherit" to="/shop">
                Shop
              </Link>
              <Typography style={{ fontSize: "14px" }}>
                {window.location.pathname.slice(1)}
              </Typography>
            </Breadcrumbs>
          </div>
          {loading ? (
            <Spinner />
          ) : (
            <>
              <div className={css.details}>
                <div className={css.img}>
                  <Zoom>
                    <img
                      src={product?.image[imageIndex]}
                      alt={product?.name}
                      className={css.pImg}
                    />
                  </Zoom>
                  <div className={css.smallImg}>
                    {product?.image.map((img, index) => {
                      return (
                        <Zoom>
                          <img
                            key={index}
                            src={img}
                            alt=""
                            onClick={() => setImageIndex(index)}
                            className={imageIndex === index ? "activeImg" : ""}
                          />
                        </Zoom>
                      );
                    })}
                  </div>
                </div>
                <div className={css.content}>
                  <h3 className="--fw-thick --f16">{product?.name}</h3>
                  <div className={`--flex-star --items-center ${css.skupart}`}>
                    <div className="--items-center">
                      <p className="--f14">SKU: &nbsp;</p>
                      <small className="--f12">{product?.sku}</small>
                    </div>
                    &nbsp;
                    <small>
                      <Review
                        averageRating={averageRating}
                        noOfRatings={product?.ratings?.length}
                        starDimension={"13px"}
                      />
                    </small>
                  </div>
                  <div className="--flex-start">
                    <span className="--items-center">
                      <p className="--f14">Main:&nbsp;</p>
                      <small>{product?.mainCategory}</small>
                    </span>
                    &nbsp;
                    <span className="--items-center">
                      <p className="--f14">Category:&nbsp;</p>
                      <small>{product?.category}</small>
                    </span>
                  </div>
                  <div className="--flex-start">
                    <span className="--items-center">
                      <p className="--f14">Brand:&nbsp;</p>
                      <small>{product?.brand}</small>
                    </span>
                  </div>
                  <div className="--flex-start">
                    <span className="--items-center">
                      <p className="--f14 --color-grey">In Stock:&nbsp;</p>
                      <small>{product?.quantity}</small>
                    </span>
                    &ensp;&ensp;
                    <span className="--items-center">
                      <p className="--f14 --color-grey">Sold:&nbsp;</p>
                      <small>{product?.sold}</small>
                    </span>
                    &ensp;&ensp;
                    <RiShareBoxLine
                      size={20}
                      onClick={copyToClipBoard}
                      color="var(--m)"
                    />
                  </div>
                  <div className={`--items-center --my ${css.price}`}>
                    <p className={` --items-center --fw-thick `}>
                      <FaNairaSign />
                      {Number(product?.price).toLocaleString()}
                    </p>
                    {product?.regularPrice > 0 && (
                      <del className="--items-center --mr --f14 --color-grey">
                        <FaNairaSign />
                        {Number(product?.regularPrice).toLocaleString()}
                      </del>
                    )}
                    {product?.regularPrice && (
                      <p>
                        {calculatePercentage(
                          product?.regularPrice,
                          product?.price
                        )}
                      </p>
                    )}
                  </div>
                  {product?.regularPrice && (
                    <small className="--color-green --f16">
                      You just save{" "}
                      <span className="--color-green">
                        #{product?.regularPrice - product?.price}
                      </span>
                    </small>
                  )}
                  <span className="--dotted-lines --my"></span>
                  {product?.color.length > 0 && (
                    <>
                      <p className={`--fw-thick ${css.size} --mt10`}>Color:</p>

                      <ul className={css["unordered-color"]}>
                        {product?.color &&
                          product?.color.map((col) => {
                            return (
                              <>
                                <li
                                  key={col._id}
                                  style={{ background: `${col.hexCode}` }}
                                  className={
                                    itemColor === col
                                      ? "btnStyle btnActive"
                                      : "btnStyle"
                                  }
                                  onClick={() => setItemColor(col)}
                                >
                                  {" "}
                                  {itemColor === col ? (
                                    <FaCheck className="checkStyle" />
                                  ) : null}
                                </li>
                              </>
                            );
                          })}
                      </ul>
                    </>
                  )}
                  {/* <div className="--dotted-lines --my10"></div> */}
                  <div>
                    {product?.size.length > 0 && (
                      <>
                        <p className={`--fw-thick ${css.size} --mt10`}>Size:</p>

                        <ul className={css["size-orders"]}>
                          {product?.size &&
                            product?.size.map((siz) => {
                              const { _id, size } = siz;
                              return (
                                <li
                                  key={_id}
                                  onClick={() => setItemSize(siz)}
                                  className={
                                    itemSize === siz ? `${css.sizeHover}` : ""
                                  }
                                  style={{ padding: "8px" }}
                                >
                                  {size}
                                </li>
                              );
                            })}
                        </ul>
                      </>
                    )}
                  </div>
                  {/* <div className="--dotted-lines --my10"></div> */}
                  <div className={css.count}>
                    {isCartAdded < 0 ? null : (
                      <>
                        <FiMinusCircle
                          size={22}
                          color="var(--grey)"
                          onClick={() => decreaseCart(product)}
                        />
                        <p className={css["count-total"]}>
                          <b>{cart.cartQuantity}</b>
                        </p>
                        <FiPlusCircle
                          size={22}
                          color="var(--grey)"
                          onClick={() => addToCart(product)}
                        />
                      </>
                    )}
                  </div>
                  <div className={`--flex-start ${css["button-order"]} --mt10`}>
                    {product?.quantity > 0 ? (
                      <button
                        className={`--btn --bg-fblack ${css.tocart} `}
                        onClick={() => addToCart(product)}
                      >
                        Add to cart
                      </button>
                    ) : (
                      <button
                        className="--btn --btn-danger"
                        onClick={() =>
                          toast.error("Sorry, Product is out of stock")
                        }
                      >
                        Out of stock
                      </button>
                    )}
                    <button
                      onClick={() => addToWish(product)}
                      className={`--btn --color-black ${css.tocart} `}
                    >
                      Add to wish
                    </button>
                  </div>
                  {/* <div className="--dotted-lines --my10"></div> */}
                  <p className={`--fw-thick ${css.size} --my`}>Description</p>
                  <div>
                    <Accordion>
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        Description
                      </AccordionSummary>
                      <AccordionDetails
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(product?.description),
                        }}
                      ></AccordionDetails>
                    </Accordion>
                  </div>
                </div>
              </div>
            </>
          )}
          {/* Review Section */}
          <div className={css["product-review-section"]}>
            <div>
              <h3>Customers Review</h3>
              <Review
                averageRating={averageRating}
                noOfRatings={product?.ratings.length}
                starDimension={"22px"}
              />
              <div className="--box-underline"></div>
              <div>
                {product !== null && product?.ratings.length > 0 && (
                  <ReviewSummary ratings={product?.ratings} />
                )}
              </div>
              <div className="--my">
                {product?.ratings.length === 0 ? (
                  <p>There are no reviews for this product yet.</p>
                ) : (
                  <>
                    {product?.ratings.map((item, index) => {
                      const { star, review, reviewDate, name } = item;
                      return (
                        <div key={index} className={css.review}>
                          {/* <StarsRating value={star} style={{ fontSize: 10 }} /> */}
                          <StarRating
                            starDimension="20px"
                            starSpacing="2px"
                            starRatedColor="gold"
                            rating={star}
                            editing={false}
                          />
                          <p className={css["review-desc"]}>{review}</p>
                          <small>
                            <b>{reviewDate}</b>
                          </small>
                          <br />
                          <small>
                            <b>
                              {" "}
                              {name
                                ?.toUpperCase()
                                .substring(0, 3)
                                .concat("***")}
                            </b>
                          </small>
                        </div>
                      );
                    })}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ProductDetails;
