import React from 'react'

const PageHeading = ({heading, boldText}) => {
    return(
      <>
      <div className="page-heading">
        <h3 className="--roboto --uppercase">
          {heading}
        </h3>
        <h1 className='ph-title'>
          {boldText}
        </h1>
      </div>
      </>
    )
  }
  export default PageHeading;