import React, { useEffect, useState } from "react";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { FaNairaSign } from "react-icons/fa6";
import "./SendMoney.scss";
import { FaCheck } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import {
  RESET_RECEIVER,
  RESET_TRANSACTION_MESSAGE,
  getUserTransactions,
  selectReceiverName,
  selectTransactionMessage,
  transferFund,
  verifyAccount,
} from "../../redux/features/transaction/transactionSlice";
import { toast } from "react-toastify";
import { validateEmail } from "../../redux/features/auth/authService";
import { getUser, selectUser } from "../../redux/features/auth/authSlice";
import { useNavigate } from "react-router-dom";
import Confetti from "react-confetti";
import Meta from "../../components/meta/Meta";

const initialstate = {
  amount: 100,
  receiver: "",
  description: "",
};

const SendMoney = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const user = useSelector(selectUser);
  const receiverName = useSelector(selectReceiverName);
  const transactionMessage = useSelector(selectTransactionMessage);

  const { loading } = useSelector((state) => state.transaction);
  const [transferData, setTransferData] = useState(initialstate);

  const [verifyWithMessage, setVerifyWithMessage] = useState(false);
  const { amount, receiver, description } = transferData;

  const [popup, setPopup] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setTransferData({ ...transferData, [name]: value });
  };
  const handleAccountChange = (e) => {
    const { name, value } = e.target;
    setTransferData({ ...transferData, [name]: value });
    setVerifyWithMessage(false);
    dispatch(RESET_TRANSACTION_MESSAGE());
    dispatch(RESET_RECEIVER());
  };
  const verifyUserAccount = () => {
    if (receiver === "") {
      toast.error("Please add receiver account");
    }
    if (!validateEmail(receiver)) {
      return toast.error("Please add a valid account email");
    }
    const formData = {
      receiver,
    };
    dispatch(verifyAccount(formData));
  };
  const transferMoney = async (e) => {
    e.preventDefault();
    if (amount < 100) {
      return toast.error("Minimum amount is #100");
    }
    if (description < 2) {
      return toast.error("Please enter a description");
    }
    const formData = {
      ...transferData,
      sender: user?.email,
      status: "Success",
    };
    await dispatch(transferFund(formData));
    await dispatch(getUser());
  };
  const closeModal = (e) => {
    if (e.target.classList.contains("cm")) {
      setTransferData({ ...initialstate });
      setVerifyWithMessage(false);
      dispatch(RESET_RECEIVER());
      navigate("/wallet");
    }
  };

  useEffect(() => {
    if (transactionMessage === "Account Verification Successful") {
      setVerifyWithMessage(true);
    }
    if (transactionMessage === "Transaction Successful") {
      setTransferData({ ...initialstate });
      setVerifyWithMessage(false);
      dispatch(RESET_RECEIVER());
      dispatch(getUserTransactions());
      setPopup(true);
      setTimeout(() => {
        navigate("/wallet");
      }, 5000);
    }
    dispatch(RESET_TRANSACTION_MESSAGE());
  }, [transactionMessage, dispatch, navigate]);

  useEffect(() => {
    if (!user) {
      navigate("/login");
    }
  });
  return (
    <>
      <Meta
        robots={"nofollow, noindex"}
        googlebot={"nofollow, noindex"}
        title={"Transfer"}
      />
      {popup && <Confetti />}
      <section className="send-section">
        <div className="--flex-center send-wrapper">
          <div className="send-header">
            <span>
              <AiOutlineInfoCircle color="var(--mag)" size={18} />
              <h3>&ensp;Payee Details</h3>
            </span>

            <span>
              <h3 className="cm" onClick={closeModal}>
                &larr;&nbsp;Back
              </h3>
            </span>
          </div>

          <form className="send-form" onSubmit={transferMoney}>
            <div>
              <label>Email*</label>
              <span className="mail-check">
                <input
                  type="email"
                  placeholder="Enter Payee Email"
                  required
                  name="receiver"
                  value={transferData?.receiver}
                  onChange={handleAccountChange}
                />
                <button
                  type="button"
                  name="shortURL"
                  onClick={verifyUserAccount}
                >
                  <FaCheck size={16} />
                </button>
              </span>
            </div>
            {receiverName !== "" && (
              <div className="receiver-name">{receiverName}</div>
            )}

            <label>Amount*</label>
            <div className="currency">
              <span>
                <FaNairaSign color="var(--grey)" />
              </span>
              <input
                type="number"
                name="amount"
                placeholder="0.00"
                required
                value={transferData?.amount}
                onChange={handleInputChange}
                className="currency-input"
              />

              <div className="flag">
                <span className="flag-span"></span>
                <span className="flag-span"></span>
                <span className="flag-span"></span>
              </div>
              <small className="cur-name">NGN</small>
            </div>

            <div>
              <label>Remark*</label>
              <input
                placeholder="What's this for?"
                className="textarea"
                name="description"
                value={transferData?.description}
                onChange={handleInputChange}
                required
              />
            </div>
            {!verifyWithMessage && (
              <p style={{ fontSize: "14px" }}>
                Please click the check button above to verify email!!!
              </p>
            )}
            {
              <div>
                {verifyWithMessage &&
                  (loading ? (
                    <button type="submit" className="send-btn" disabled>
                      Initializing...
                    </button>
                  ) : (
                    <button type="submit" className="send-btn">
                      Transfer
                    </button>
                  ))}
              </div>
            }
          </form>
        </div>
      </section>
    </>
  );
};

export default SendMoney;
