import React, { useState } from "react";
import "./Radio.scss";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { SAVE_PAYMENT_METHOD } from "../../redux/features/checkout/CheckoutSlice";
import { selectIsLoggedIn } from "../../redux/features/auth/authSlice";

const PaymentOptions = () => {
  const [paymentMethod, setPaymentMethod] = useState("");
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const setPayment = (e) => {
    e.preventDefault();
    if (paymentMethod === "") {
      return toast.error("Please select a payment method");
    }
    dispatch(SAVE_PAYMENT_METHOD(paymentMethod));
    if (isLoggedIn) {
      navigate("/checkout-details");
    } else {
      navigate("/login?redirect=cart");
    }
  };
  return (
    <>
      <p>Please choose a payment method</p>
      <form className="--form-control " onSubmit={setPayment}>
        <label htmlFor="flutter-wave" className="radio-label ">
          <input
            type="radio"
            name="paymentMethod"
            id="flutter-wave"
            value={"flutter-wave"}
            onChange={(e) => setPaymentMethod(e.target.value)}
            className="radio-input"
          />{" "}
          <span className="custom-radio"></span>
          Flutterwave
        </label>
        {/* <label htmlFor="pay-stack" className='radio-label'>
            <input 
              type="radio" 
              name="paymentMethod" 
              id="pay-stack" 
              value={"pay-stack"} 
              onChange={(e)=> setPaymentMethod(e.target.value)}
              className='radio-input'
             /> <span className='custom-radio'></span>
             PayStack
        </label> */}

        <label htmlFor="wallet" className="radio-label">
          <input
            type="radio"
            name="paymentMethod"
            id="wallet"
            value={"wallet"}
            onChange={(e) => setPaymentMethod(e.target.value)}
            className="radio-input"
          />{" "}
          <span className="custom-radio"></span>
          Wallet
        </label>
        <input
          type="submit"
          value="Checkout"
          className="--btn --btn-primary --btn-block"
        />
      </form>
    </>
  );
};

export default PaymentOptions;
