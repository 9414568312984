import axios from "axios";

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const API_URL = `${BACKEND_URL}/api/v0/color/`;

const createColor = async (colorData) => {
  const response = await axios.post(API_URL + "createColor", colorData);
  return response.data;
};

const getColors = async () => {
  const response = await axios.get(API_URL + "getColors");
  return response.data;
};

const deleteColor = async (id) => {
  const response = await axios.delete(API_URL + "deleteColor/" + id);
  return response.data.messsage;
};
// Update Product
const updateColor = async (id, colorData) => {
  const response = await axios.patch(API_URL + id, colorData);
  return response.data;
};
const colorService = {
  createColor,
  getColors,
  deleteColor,
  updateColor,
};

export default colorService;
