import React, { useState } from "react";
import { FaCheck } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import "./Changerole.scss";
import {
  EMAIL_RESET,
  sendAutomatedEmail,
} from "../../../redux/features/email/emailSlice";
import { getUsers, upgradeUser } from "../../../redux/features/auth/authSlice";

const ChangeRole = ({ _id, email }) => {
  const [userRole, setUserRole] = useState("");
  const dispatch = useDispatch();

  // Change User role
  const changeRole = async (e) => {
    e.preventDefault();

    if (!userRole) {
      toast.error("Please select a role");
    }

    const userData = {
      role: userRole,
      id: _id,
    };

    const emailData = {
      subject: `Account Notice - Shop With Meenah`,
      send_to: email,
      reply_to: "no-reply@shopwithmeenah.com",
      template: "changeRole",
      url: "/login",
    };

    const upgradedUser = dispatch(upgradeUser(userData));
    if (upgradedUser) {
      dispatch(sendAutomatedEmail(emailData));
      dispatch(getUsers());
      dispatch(EMAIL_RESET());
    }
  };

  return (
    <div className="sort">
      <form
        className="--flex-start role-form"
        onSubmit={(e) => changeRole(e, _id, userRole)}
      >
        <select
          value={userRole}
          onChange={(e) => setUserRole(e.target.value)}
          className="role-select"
        >
          <option value="">-- select --</option>
          <option value="customer">Customer</option>
          <option value="author">Author</option>
          <option value="admin">Admin</option>
          <option value="suspended">Suspend</option>
        </select>
        <button className="--btn --btn-primary role-btn">
          <FaCheck size={15} />
        </button>
      </form>
    </div>
  );
};

export default ChangeRole;
