import { useNavigate } from "react-router-dom";
import ListOfOrders from "../../components/admin/orders/ListOfOrders";
import Meta from "../../components/meta/Meta";

const OrderHistory = () => {
  const navigate = useNavigate();

  const openOrderDetails = (id) => {
    navigate(`/order-details/${id}`);
  };

  return (
    <section>
      <Meta
        robots={"nofollow, noindex"}
        googlebot={"nofollow, noindex"}
        title={"Order History"}
      />
      <div className={`container order`}>
        <h3 className="--fw-thin">All Orders</h3>
        <small>
          Open an order to leave a{" "}
          <span className="--color-primary">Product Review</span>
        </small>
        {/* <br /> */}
        <ListOfOrders openOrderDetails={openOrderDetails} />
      </div>
    </section>
  );
};

export default OrderHistory;
