import {
  FaHome,
  FaInfo,
  FaInstagram,
  FaPhoneAlt,
  FaTiktok,
  FaWhatsapp,
} from "react-icons/fa";
import css from "./ContactUs.module.scss";
import { Link } from "react-router-dom";
import { BsSnapchat } from "react-icons/bs";
import { IconContext } from "react-icons";
import { MdMail } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getUser } from "../../redux/features/auth/authSlice";
import { toast } from "react-toastify";
import Loader from "../../components/loader/Loader";
import { sendToTg } from "../../redux/features/enq/enqSlice";
import Meta from "../../components/meta/Meta";

const ContactUs = () => {
  const { user } = useSelector((state) => state.auth);
  const { loading } = useSelector((state) => state.enq);

  const initialState = {
    name: user?.fname + " " + user?.lname || "",
    email: user?.email || "",
    phone: user?.phone || "",
    comment: "",
  };
  const [contact, setContact] = useState(initialState);
  const { name, email, phone, comment } = contact;
  const dispatch = useDispatch();
  useEffect(() => {
    if (user === null) {
      dispatch(getUser());
    }
  }, [dispatch, user]);
  useEffect(() => {
    if (user) {
      setContact({
        name: user?.fname + " " + user?.lname || "",
        email: user?.email || "",
        phone: user?.phone || "",
      });
    }
  }, [dispatch, user]);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setContact({ ...contact, [name]: value });
  };

  const submitContact = async (e) => {
    e.preventDefault();
    if (!name || !email || !phone || !comment) {
      return toast.error("All fileds are required");
    }
    const formData = {
      name: user?.fname + " " + user?.lname,
      email: user?.email,
      phone: user?.phone,
      comment,
    };
    await dispatch(sendToTg(formData));
  };
  return (
    <>
      <Meta
        title={"Contact us - Shop With Meenah"}
        canonical={"https://shopwithmeenah.com/contact-us"}
        robots={"follow, index"}
        googlebot={"follow, index"}
      />
      {loading && <Loader />}
      <div className={css.map}>
        <div className={css["left-map"]}>
          <h4>Ibadan, Oyo State</h4>
          <iframe
            title="Ibadan, Oyo State"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d126614.51346305433!2d3.8226587906503915!3d7.387080140294093!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x10398d77eeff086f%3A0x3b33e0f76e8e04a9!2sIbadan%2C%20Oyo!5e0!3m2!1sen!2sng!4v1719606760110!5m2!1sen!2sng"
            width="100%"
            height="400px"
            style={{ border: "none" }}
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          />
        </div>
        <div className={css["right-map"]}>
          <h4>Ede, Osun State</h4>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d63257.1916548847!2d4.393856211337413!3d7.7287186292209356!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x103829b20138cf6d%3A0x3baebcd1703938b3!2sEde%2C%20Osun!5e0!3m2!1sen!2sng!4v1719606355163!5m2!1sen!2sng"
            width="100%"
            title="Ede, Osun State"
            height="400px"
            style={{ border: "none" }}
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          />
        </div>
      </div>
      <div className={`${css.contact} --flex`}>
        <div className={css["left-contact"]}>
          <h3>Contact Us</h3>
          <form onSubmit={submitContact}>
            <input
              type="text"
              placeholder="Name"
              name="name"
              value={name}
              onChange={handleInputChange}
              required
              readOnly
              disabled
            />
            <input
              type="email"
              placeholder="Email"
              name="email"
              value={email}
              onChange={handleInputChange}
              readOnly
              disabled
            />
            <input
              type="number"
              placeholder="Phone"
              name="phone"
              value={phone}
              onChange={handleInputChange}
              readOnly
              disabled
            />
            <textarea
              type="text"
              placeholder="Comment..."
              name="comment"
              value={comment}
              onChange={handleInputChange}
              style={{ height: "80px" }}
            />
            <input
              type="submit"
              value="Contact Us"
              className="--btn --btn-primary"
            />
          </form>
        </div>
        <div className={css["right-contact"]}>
          <h4>Get In Touch With Us</h4>
          <IconContext.Provider value={{ size: "20px", color: "var(--grey)" }}>
            <span className={css["contact-address"]}>
              <FaHome />
              &emsp; Iyana ajia, Ibadan. Oyo State
            </span>
            <span className={css["contact-address"]}>
              <FaHome />
              &emsp; Sk Ring, Ede N. Osun State
            </span>
            <span className={css["contact-address"]}>
              <FaPhoneAlt />
              &emsp; +234 *** *** ****
            </span>
            <span
              className={css["contact-address"]}
              style={{ textTransform: "none" }}
            >
              <MdMail />
              &emsp; support@shopwithmeenah.com
            </span>
            <span className={css["contact-address"]}>
              <FaInfo />
              &emsp; Monday - Friday 10AM - 7PM
            </span>
            <span>
              <h4>Socials</h4>
              <ul className="nav-ul footer-links">
                <div className="f-icons-w">
                  <div className="f-icons">
                    <IconContext.Provider value={{ size: 24, color: "#444" }}>
                      <Link
                        to={"https://wa.me/message/JWKOBH7JOUKQN1"}
                        target="_blank"
                      >
                        <FaWhatsapp />
                      </Link>
                      <Link
                        to={"https://instagram.com/shopwithmeenah_24"}
                        target="_blank"
                      >
                        <FaInstagram />
                      </Link>
                      <Link
                        to={"https://snapchat.com/add/meenah2350"}
                        target="_blank"
                      >
                        <BsSnapchat />
                      </Link>
                      <Link
                        to={"https://tiktok.com/@amiinah0105"}
                        target="_blank"
                      >
                        <FaTiktok />
                      </Link>
                    </IconContext.Provider>
                  </div>
                </div>
              </ul>
            </span>
          </IconContext.Provider>
        </div>
      </div>
    </>
  );
};

export default ContactUs;
