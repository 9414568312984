import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteColor,
  getColors,
} from "../../../redux/features/color/colorSlice";
import { FaTrashAlt } from "react-icons/fa";
import { confirmAlert } from "react-confirm-alert";
import CreateColor from "./CreateColor";

const Colors = () => {
  const dispatch = useDispatch();
  const { colors } = useSelector((state) => state.color);
  useEffect(() => {
    dispatch(getColors());
  }, [dispatch]);

  const confirmDelete = (color) => {
    confirmAlert({
      title: "Delete Color",
      message: "Are you sure you want to delete this color",
      buttons: [
        {
          label: "Delete",
          onClick: () => delColor(color),
        },
        {
          label: "Cancel",
          // onClick: () => alert("Click No")
        },
      ],
    });
  };
  const delColor = async (color) => {
    await dispatch(deleteColor(color));
    await dispatch(getColors());
  };

  return (
    <>
      <CreateColor />

      <div className="table">
        {colors.length === 0 ? (
          <p>No Color Found</p>
        ) : (
          <table>
            <thead>
              <tr>
                <th>S/n</th>
                <th>Id</th>
                <th>Name</th>
                <th>Hex Code</th>
                <th>Date Created</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {colors.map((color, index) => {
                const { _id, name, hexCode, createdAt } = color;
                return (
                  <tr key={_id}>
                    <td>{index + 1}</td>
                    <td>{_id}</td>
                    <td>{name}</td>
                    <td>{hexCode}</td>
                    <td>{new Date(createdAt).toDateString()}</td>
                    <td>
                      <span className="--flex">
                        <FaTrashAlt
                          size={20}
                          color={"red"}
                          onClick={() => confirmDelete(_id)}
                        />
                      </span>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </div>
    </>
  );
};

export default Colors;
