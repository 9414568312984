import React, { useEffect, useState } from "react";
import scss from "./ProductList.module.scss";
import { BsGridFill } from "react-icons/bs";
import { FaListAlt } from "react-icons/fa";
import Search from "../../search/Search";
import { useDispatch, useSelector } from "react-redux";
import ProductItem from "../productItem/ProductItem";
import {
  FILTER_BY_SEARCH,
  SORT_PRODUCTS,
  selectFilteredProducts,
} from "../../../redux/features/product/filterSlice";
import ReactPaginate from "react-paginate";
import { IoFilter } from "react-icons/io5";
import { GrFormPrevious } from "react-icons/gr";
import { MdOutlineNavigateNext } from "react-icons/md";

const ProductList = ({ products, toggleFilter }) => {
  const dispatch = useDispatch();
  const [grid, setGrid] = useState(true);
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState("");

  const filteredProducts = useSelector(selectFilteredProducts);

  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffSet] = useState(0);
  const itemsPerPage = 15;

  //Pagination
  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(filteredProducts.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(filteredProducts.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, filteredProducts]);

  const handlePageClick = (e) => {
    const newOffset = (e.selected * itemsPerPage) % filteredProducts.length;
    setItemOffSet(newOffset);
    window.scrollTo(0, 0); // Scroll to top when the page changes
    //Paginate ends...
  };

  useEffect(() => {
    dispatch(SORT_PRODUCTS({ products, sort }));
  }, [dispatch, products, sort]);

  useEffect(() => {
    dispatch(FILTER_BY_SEARCH({ products, search }));
  }, [dispatch, products, search]);
  return (
    <div className={scss["product-list"]}>
      <div className={scss["srch-cont"]}>
        <div className="--items-center --mr">
          {grid ? (
            <BsGridFill
              size={22}
              color="var(--m)"
              onClick={() => setGrid(!grid)}
            />
          ) : (
            <FaListAlt
              size={24}
              color="var(--fblack)"
              onClick={() => setGrid(!grid)}
            />
          )}
          &ensp;&ensp;
          <IoFilter
            size={24}
            onClick={toggleFilter}
            className={scss.hidelist}
          />
        </div>
        <Search
          placeholder={"Search for Products"}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>
      <div className={scss["pl-top"]}>
        <div className={`scss["pl-icons"]--flex-col`}>
          <p style={{ fontSize: "14px" }}>
            {/* <b>{currentItems.length} Products Found</b> */}
            <b>{products.length} Products Found</b>
          </p>
        </div>

        <div className={scss["pl-sort"]}>
          <select value={sort} onChange={(e) => setSort(e.target.value)}>
            <option value="latest">Latest</option>
            <option value="highest-price">Highest Price</option>
            <option value="lowest-price">Lowest Price</option>
            <option value="a-z">a-z</option>
            <option value="z-a">z-a</option>
          </select>
        </div>
      </div>
      <div className={grid ? `${scss.grid}` : `${scss.list}`}>
        {products.length === 0 ? (
          <p>No product found</p>
        ) : (
          <>
            {currentItems.map((product) => {
              return (
                <>
                  <div key={product._id}>
                    <ProductItem {...product} grid={grid} product={product} />
                  </div>
                </>
              );
            })}
          </>
        )}
      </div>
      <ReactPaginate
        breakLabel={"..."}
        nextLabel={<MdOutlineNavigateNext size={20} />}
        onPageChange={handlePageClick}
        pageRangeDisplayed={3}
        pageCount={pageCount}
        previousLabel={<GrFormPrevious size={20} />}
        renderOnZeroPageCount={null}
        containerClassName="pagination"
        pageLinkClassName="page-num"
        previousLinkClassName="page-num"
        nextLinkClassName="page-num"
        activeLinkClassName="activePage"
      />
    </div>
  );
};

export default ProductList;
