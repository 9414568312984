import React, { useState } from "react";
import PasswordInput from "../passwordInput/PasswordInput";
import "./ChangePassword.scss";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  RESET_AUTH,
  changePassword,
  logout,
} from "../../redux/features/auth/authSlice";
import {
  EMAIL_RESET,
  sendAutomatedEmail,
} from "../../redux/features/email/emailSlice";
import {
  validatePasswordCase,
  validatePasswordNum,
  validatePasswordSChar,
} from "../../pages/auth/Register";

const initialState = {
  oldPassword: "",
  password: "",
  password2: "",
};
const ChangePassword = () => {
  const [formData, setFormData] = useState(initialState);
  const { oldPassword, password, password2 } = formData;
  const { loading, user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const updatePassword = async (e) => {
    e.preventDefault();
    if (!oldPassword || !password || !password2) {
      return toast.error("All fields are required");
    }
    if (password.length < 6 || password2.length < 6) {
      return toast.error("Password must be greater than 6 characters");
    }
    if (password !== password2) {
      return toast.error("Password do not match");
    }
    if (
      !validatePasswordCase(password) ||
      !validatePasswordNum(password) ||
      !validatePasswordSChar(password) ||
      !validatePasswordCase(password2) ||
      !validatePasswordNum(password2) ||
      !validatePasswordSChar(password2)
    ) {
      return toast.error(
        "Password must contain upper and lower case alpha numeric and special characters"
      );
    }
    const userData = {
      oldPassword,
      password,
    };

    const emailData = {
      subject: "Password Changed - Shop With Meenah",
      send_to: user.email,
      reply_to: "no-reply@shopwithmeenah.com",
      template: "changePassword",
      url: "/forgot",
    };
    const passwordChanged = dispatch(changePassword(userData));
    const noticeSent = dispatch(sendAutomatedEmail(emailData));
    if (passwordChanged && noticeSent) {
      await dispatch(logout());
      await dispatch(RESET_AUTH(userData));
      await dispatch(EMAIL_RESET(emailData));
      await navigate("/login");
    }
  };
  return (
    <div className="change-password">
      <h3>Reset Password</h3>
      <form onSubmit={updatePassword} className="--mt10">
        <div className="reset-cont">
          <div>
            <PasswordInput
              placeholder="Current password"
              name="oldPassword"
              value={oldPassword}
              onChange={handleInputChange}
            />
          </div>
          <PasswordInput
            placeholder="New Password"
            name="password"
            value={password}
            onChange={handleInputChange}
          />
          <PasswordInput
            placeholder={"Confirm New Password"}
            name="password2"
            value={password2}
            onChange={handleInputChange}
          />
        </div>
        <input
          type={loading ? "button" : "submit"}
          value={loading ? "Resetting..." : "Change Password"}
          className="--btn --btn-danger --color-gray"
          style={{ margin: "0", background: "tomato" }}
        />
      </form>
    </div>
  );
};

export default ChangePassword;
