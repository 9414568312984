import { useEffect, useState } from "react";
import { MdOutlineMailOutline } from "react-icons/md";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import bag from "../../assets/bag1-min.png";
import PasswordInput from "../../components/passwordInput/PasswordInput";
import { useDispatch, useSelector } from "react-redux";
import { validateEmail } from "../../redux/features/auth/authService";
import { toast } from "react-toastify";
import {
  RESET_AUTH,
  login,
  sendLoginCode,
} from "../../redux/features/auth/authSlice";
import Loader from "../../components/loader/Loader";
import { getCartDB, saveCartDB } from "../../redux/features/cart/cartSlice";
import { BiLogIn } from "react-icons/bi";
import Meta from "../../components/meta/Meta";
import secureLocalStorage from "react-secure-storage";

const initialState = {
  email: "",
  password: "",
};

const Login = () => {
  const [formData, setFormData] = useState(initialState);
  const { email, password } = formData;
  const { loading, isLoggedIn, isSuccess, isError, twoFactor } = useSelector(
    (state) => state.auth
  );

  const [urlParams] = useSearchParams();
  const redirect = urlParams.get("redirect");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const submitLogin = async (e) => {
    e.preventDefault();
    if (!email || !password) {
      return toast.error("Please enter your email and password");
    }
    if (!validateEmail(email)) {
      return toast.error("Invalid email or password");
    }
    const userData = {
      email,
      password,
    };
    if (userData) {
      await dispatch(login(userData));
    }
  };

  useEffect(() => {
    if (isError && twoFactor) {
      dispatch(sendLoginCode(email));
      navigate(`/loginWithCode/${email}`);
    }

    if (isSuccess && isLoggedIn) {
      if (redirect === "cart") {
        dispatch(
          saveCartDB({
            cartItems: JSON.parse(secureLocalStorage.getItem("cartItems")),
          })
        );
        navigate("/cart");
      }

      dispatch(getCartDB());
      navigate("/");
    }
    dispatch(RESET_AUTH());
  }, [
    twoFactor,
    email,
    isError,
    isLoggedIn,
    isSuccess,
    dispatch,
    navigate,
    redirect,
  ]);

  const logoClick = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    navigate("/");
  };

  return (
    <>
      <Meta
        title={"Login - Shop With Meenah"}
        canonical={"https://shopwithmeenah.com/login"}
        descName={"Login your shop with meenah account..."}
        keywords="shopwithmeenah signin, shopwithmeenah, shop with meenah, shop with meenah signin,shop with meenah login, shopwithmeenah login"
        googlebot={"follow, index"}
        robots={"follow, index"}
      />
      {loading && <Loader LoadingState={"Authenticating..."} />}
      <div className="form">
        <div className="form-wrapper">
          <BiLogIn size={35} color="#999" />
          <h4>Sign in</h4>
          <form onSubmit={submitLogin}>
            <div className="input-cont">
              <MdOutlineMailOutline size={26} color="var(--grey)" />
              <input
                type="email"
                placeholder="Type your email"
                required
                name="email"
                value={email}
                onChange={handleInputChange}
              />
            </div>
            <PasswordInput
              placeholder="Type your password"
              name="password"
              value={password}
              onChange={handleInputChange}
            />
            <span className="remember-section">
              <label htmlFor="remember-me">
                <input type="checkbox" name="" id="remember-me" checked />
                Keep me signed in
              </label>
              <span onClick={() => navigate("/forgot")}>Forgot Password</span>
            </span>
            <input type="submit" value="Log In" />
          </form>
          <span className="new-user">
            Not a member ? &nbsp;
            <Link to="/register" className="sign-up">
              Sign up
            </Link>
          </span>
          <div className="powered-by" onClick={logoClick}>
            <span>Powered by </span>
            <span>
              <img src={bag} alt="logo" width={100} />
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
