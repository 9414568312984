import React, { useEffect } from "react";
import css from "./TransactionDetails.module.scss";
import brand from "../../assets/bag1-min.png";
import { FaNairaSign } from "react-icons/fa6";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getUserSingleTransaction } from "../../redux/features/transaction/transactionSlice";
import { getUser, selectUser } from "../../redux/features/auth/authSlice";
import Loader from "../../components/loader/Loader";
import Meta from "../../components/meta/Meta";

const TransactionDetails = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const { transaction, loading } = useSelector((state) => state.transaction);
  const user = useSelector(selectUser);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getUserSingleTransaction(id));
    dispatch(getUser());
  }, [dispatch, id]);

  return (
    <>
      <Meta
        robots={"nofollow, noindex"}
        googlebot={"nofollow, noindex"}
        title={"Transaction Details"}
      />
      {loading && <Loader />}
      <div className={css["t-details"]}>
        <div className={css["td-wrapper"]}>
          <div className={css["td-cont"]}>
            <div className={css["td-head"]}>
              <span className={css.brandcont}>
                <img src={brand} alt="" />
              </span>
              <h4>Transaction Receipt</h4>
            </div>
            <div className={css["td-amount"]}>
              <span className={css["full-amount"]}>
                <h3>
                  {transaction?.sender === user?.email ? "-" : "+"}&nbsp;{" "}
                </h3>
                <FaNairaSign />
                <h3>{transaction?.amount.toLocaleString(2)}</h3>
              </span>
              <span className={css["td-status"]}>{transaction?.status}</span>
              <span className={css.tdate}>{transaction?.createdAt}</span>
            </div>

            <div className={css["per-transact"]}>
              <span>Transaction Type</span>
              <h4>
                {transaction?.sender === user?.email ? "Debit" : "Credit"}
              </h4>
            </div>
            <div className={css["per-transact"]}>
              <span>Receiver Details</span>
              <span className={css.tdname}>
                <h4>{transaction?.receiver}</h4>
              </span>
            </div>
            <div className={css["per-transact"]}>
              <span>Sender Details</span>
              <span className={css.tdname}>
                <h4>{transaction?.sender}</h4>
              </span>
            </div>
            <div className={css["per-transact"]}>
              <span>Remark</span>
              <h4>{transaction?.description}</h4>
            </div>
            <div className={css["per-transact"]}>
              <span>Transaction Id</span>
              <h4>{transaction?._id}</h4>
            </div>

            <div className={css["td-support"]}>
              <span className="--color-grey">Support</span>
              <h4 className="--color-primary">help@shopwithmeenah.com</h4>
            </div>
            <br />
            <button
              className="--btn --btn-primary --btn-block "
              onClick={() => navigate("/wallet")}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default TransactionDetails;
