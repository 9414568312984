import { useLocation } from "react-router-dom";

const HiddenPaths = ({ children }) => {
  const loc = useLocation();
  const hidedPaths = [
    "/login",
    "/register",
    "/reset",
    "/forgot",
    "/verifyEmail",
    "/loginWithCode/*",
    "/privacy-policy",
    "/terms-of-service",
    "/about-us",
  ].includes(loc.pathname);
  if (!hidedPaths) {
    return children;
  }
  return null;
};

export default HiddenPaths;
