import {useSelector} from "react-redux";

const ShowOnLogin = ({children}) => {
 const {isLoggedIn} = useSelector((state) => state.auth);
 if (isLoggedIn) {
    return <>{children}</>
 }
}

export const ShowOnLogout = ({children}) => {
    const {isLoggedIn} = useSelector((state) => state.auth);
    if (!isLoggedIn) {
       return <>{children}</>
    }
   }

export default ShowOnLogin;