import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import "./ReviewProducts.scss";
// import StarsRating from "react-star-rate";
import { Spinner } from "../../components/loader/Loader";
import { useDispatch } from "react-redux";
import {
  deleteReview,
  getProduct,
  reviewProduct,
  updateReview,
} from "../../redux/features/product/productSlice";
import { toast } from "react-toastify";
import { BsTrash } from "react-icons/bs";
import StarRating from "react-star-ratings";
import { FaEdit } from "react-icons/fa";
import Meta from "../../components/meta/Meta";

const ReviewProducts = () => {
  const { id } = useParams();
  const [rate, setRate] = useState(0);
  const [review, setReview] = useState("");
  const [userReview, setUserReview] = useState([]);
  const [isEditing, setIsEditing] = useState(false);

  const { isLoading, product } = useSelector((state) => state.product);
  const { user } = useSelector((state) => state.auth);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getProduct(id));
  }, [dispatch, id]);

  const submitReview = async (e) => {
    e.preventDefault();
    const today = new Date();
    const date = today.toDateString();
    if (rate === 0 || review === "") {
      return toast.error("Please enter rating and review");
    }
    const formData = {
      star: rate,
      review,
      reviewDate: date,
    };

    await dispatch(reviewProduct({ id, formData }));
    navigate(-1);
  };

  const delReview = async (e) => {
    const formData = {
      userID: user._id,
    };
    await dispatch(deleteReview({ id, formData }));
    navigate(-1);
  };
  const startEdit = async () => {
    setIsEditing(true);
    setRate(userReview[0].star);
    setReview(userReview[0].review);
  };
  const editReview = async (e) => {
    e.preventDefault();
    const today = new Date();
    const date = today.toDateString();
    if (rate === 0 || review === "") {
      return toast.error("Please enter rating and review");
    }
    const formData = {
      star: rate,
      review,
      reviewDate: date,
      userID: userReview[0].userID,
    };
    await dispatch(updateReview({ id, formData }));
    navigate(-1);
  };
  useEffect(() => {
    const reviewed = product?.ratings.filter((rev) => {
      return rev.userID === user?._id;
    });
    setUserReview(reviewed);
  }, [product, user]);

  const changeStar = (newRating, name) => {
    setRate(newRating);
    // console.table(newRating, name);
  };

  return (
    <section>
      <Meta
        robots={"nofollow, noindex"}
        googlebot={"nofollow, noindex"}
        title={"Product Review"}
      />
      <div className="container review">
        <br />
        <h3 className="--fw-m --f16 ">
          <b>Write a Review</b>
        </h3>
        <br />
        {isLoading && product === null ? (
          // <img src={spinnerImg} alt="Loading..." style={{ width: "50px" }} />
          <Spinner />
        ) : (
          <>
            <span className="--flex">
              <img
                src={product?.image[0]}
                alt={product?.name}
                style={{ width: "100px" }}
              />
              &nbsp;&nbsp;&nbsp;
              {product?.name}
            </span>
          </>
        )}
        {userReview?.length > 0 && !isEditing ? (
          <>
            <br />
            <h3>
              <b>Product Reviews</b>
            </h3>
            <div>
              {userReview?.map((item, index) => {
                const { star, review, reviewDate, fname } = item;
                return (
                  <div key={index} className="review --flex-between --p">
                    <div>
                      {/* <StarsRating value={rate} /> */}
                      <StarRating
                        starDimension="20px"
                        starSpacing="2px"
                        starRatedColor="var(--m)"
                        rating={star}
                        changeRating={changeStar}
                        editing={false}
                      />
                      <p>{review}</p>
                      <span>
                        <b>{reviewDate}</b>
                      </span>
                      <br />
                      <span>
                        <b>by: {fname}</b>
                      </span>
                    </div>
                    <div>
                      <FaEdit
                        color="green"
                        size={25}
                        onClick={() => startEdit()}
                      />
                      <BsTrash color="red" size={25} onClick={delReview} />
                    </div>
                  </div>
                );
              })}
            </div>
          </>
        ) : (
          <form>
            <label className="--mt10">
              <b>Rating:</b>
            </label>
            <StarRating
              starDimension="20px"
              starSpacing="2px"
              starRatedColor="var(--m)"
              starHoverColor="#F6B01E"
              rating={rate}
              changeRating={changeStar}
              editing={true}
              isSelectable={true}
            />
            <label className="--mt10">
              <b>Review</b>
            </label>
            <textarea
              value={review}
              required
              onChange={(e) => setReview(e.target.value)}
              placeholder="Note..."
            ></textarea>
            {!isEditing ? (
              <button
                onClick={(e) => submitReview(e)}
                className="--btn --bg-fblack --color-gray"
              >
                Submit Review
              </button>
            ) : (
              <div className="--flex-start">
                <button
                  onClick={(e) => editReview(e)}
                  className="--btn --bg-fblack --color-gray"
                >
                  Update Review
                </button>
                <button onClick={() => setIsEditing(false)} className="--btn">
                  Cancel
                </button>
              </div>
            )}
          </form>
        )}
      </div>
    </section>
  );
};

export default ReviewProducts;
