import { useState } from "react";
import Card from "../../card/Card";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { createCoupon } from "../../../redux/features/coupon/couponSlice";
import Loader from "../../loader/Loader";
// import "./Category.scss"
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const CreateCoupon = () => {
  const { loading } = useSelector((state) => state.coupon);
  const dispatch = useDispatch();

  const [name, setName] = useState("");
  const [discount, setDiscount] = useState("");
  const [expiresAt, setExpiresAt] = useState(new Date());

  const saveCoupon = async (e) => {
    e.preventDefault();
    if (name.length < 6) {
      return toast.error("Coupon name must be up to 3 characters");
    }
    if (name.length < 1) {
      return toast.error("Discount name must be greater than 1");
    }
    const formData = {
      name,
      discount,
      expiresAt,
    };
    await dispatch(createCoupon(formData));
    setName("");
    setDiscount("");
  };
  return (
    <>
      {loading && <Loader />}
      <div className="--mb2 create-category">
        <h3>Create Coupon</h3>
        <small>
          Use the form to{" "}
          <span className="--color-primary">Create a Coupon</span>
        </small>
        <Card cardClass={"card"}>
          <br />
          <form onSubmit={saveCoupon}>
            <label htmlFor="">Coupon Name:</label>
            <input
              type="text"
              name="name"
              value={name}
              placeholder="Coupon Name"
              onChange={(e) => setName(e.target.value)}
              required
            />
            <label htmlFor="">Coupon Discount:</label>
            <input
              type="text"
              name="discount"
              value={discount}
              placeholder="Coupon Discount"
              onChange={(e) => setDiscount(e.target.value)}
              required
            />
            <label htmlFor="">Expiry Date:</label>
            <DatePicker
              selected={expiresAt}
              value={expiresAt}
              onChange={(date) => setExpiresAt(date)}
              required
            />
            <input
              type="submit"
              value="Save Coupon"
              className="--btn --btn-primary"
            />
          </form>
        </Card>
      </div>
    </>
  );
};

export default CreateCoupon;
