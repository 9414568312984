import axios from "axios"

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
export const API_URL = `${BACKEND_URL}/api/v0/products/`;

// Create Product
const createProduct = async (formData) => {
    const response = await axios.post(API_URL,  formData);
    return response.data;
   }
// Get Products
const getProducts = async () => {
    const response = await axios.get(API_URL);
    return response.data;
   }
   // Delete Product
   const deleteProduct = async (id) => {
    const response = await axios.delete(API_URL + id);
    return response.data;
   }

   // Get Product
   const getProduct = async (id) => {
    const response = await axios.get(API_URL + id);
    return response.data;
   }

      // Update Product
      const updateProduct = async (id, formData) => {
        const response = await axios.patch(API_URL + id, formData);
        return response.data;
       }
// Review Product
const reviewProduct = async (id, formData) => {
    const response = await axios.patch(`${API_URL}review/${id}`, formData);
    return response.data.message;
  };
  
  // Delete Product
  const deleteReview = async (id, formData) => {
    const response = await axios.patch(`${API_URL}deleteReview/${id}`, formData);
    return response.data.message;
  };
  
  // Update Product
  const updateReview = async (id, formData) => {
    const response = await axios.patch(`${API_URL}updateReview/${id}`, formData);
    return response.data.message;
  };
   const productService = {
    createProduct,
    getProducts,
    deleteProduct,
    getProduct,
    updateProduct,
    reviewProduct,
    deleteReview,
    updateReview,
}
export default productService;