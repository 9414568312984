import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getOrder } from "../../redux/features/order/orderSlice";
import OrderDetailsComp from "./OrderDetailsComp";
import Meta from "../../components/meta/Meta";

const OrderDetails = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getOrder(id));
  }, [dispatch, id]);

  return (
    <section>
      <Meta
        robots={"nofollow, noindex"}
        googlebot={"nofollow, noindex"}
        title={"Order Details"}
      />
      <OrderDetailsComp orderPageLink={"/order-history"} />
    </section>
  );
};

export default OrderDetails;
