import axios from "axios";

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const API_URL = `${BACKEND_URL}/api/v0/news-letter/`;

// Create New Order
const regMail = async (formData) => {
  const response = await axios.post(API_URL + "newsletter", formData);
  return response.data.message;
};

// Get all products
const getMails = async () => {
  const response = await axios.get(API_URL);
  return response.data;
};

const newsService = {
  regMail,
  getMails,
};

export default newsService;
