import { useState } from "react";
import Card from "../../card/Card";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  createCategory,
  getCategories,
} from "../../../redux/features/categoryAndBrand/CategoryAndBrandSlice";
import Loader from "../../loader/Loader";
import "./Category.scss";

const CreateCategory = () => {
  const { loading } = useSelector((state) => state.category);
  const dispatch = useDispatch();
  const [name, setName] = useState("");

  const saveCategory = async (e) => {
    e.preventDefault();
    if (name.length < 3) {
      return toast.error("Category name must be up to 3 characters");
    }
    const formData = {
      name,
    };
    await dispatch(createCategory(formData));
    await dispatch(getCategories());
    setName("");
  };
  return (
    <>
      {loading && <Loader />}
      <div className="--mb2 create-category">
        <h3>CreateCategory</h3>
        <p>
          Use the form to <b className="--fw-thick">Create a Category</b>
        </p>
        <Card cardClass={"card"}>
          <br />
          <form onSubmit={saveCategory}>
            <label htmlFor="">Category Name:</label>
            <input
              type="text"
              name="name"
              value={name}
              placeholder="Category Name"
              onChange={(e) => setName(e.target.value)}
              required
            />
            <input
              type="submit"
              value="Save Category"
              className="--btn --btn-primary"
            />
          </form>
        </Card>
      </div>
    </>
  );
};

export default CreateCategory;
